import React from 'react';

const AnimatedCheckmark = () => (
  <svg
    className="w-12 h-12 text-green-500"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      className="checkmark"
      d="M6 12l4 4 8-8"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <style jsx>{`
      .checkmark {
        stroke-dasharray: 22;
        stroke-dashoffset: 22;
        animation: dash 0.5s ease-in-out forwards;
      }
      @keyframes dash {
        to {
          stroke-dashoffset: 0;
        }
      }
    `}</style>
  </svg>
);

export default AnimatedCheckmark;
