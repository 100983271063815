import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';

const ErrorAlerts = ({ errors, onClose }) => {
  return (
    <div className="fixed top-4 left-1/2 transform -translate-x-1/2 mb-4 w-full max-w-xl rounded-lg bg-red-100 p-4 text-red-700 z-50 shadow-lg">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <FiAlertCircle className="mr-2 text-2xl" />
          <span className="font-bold">Error</span>
        </div>
        <button onClick={onClose}>
          <AiOutlineClose className="text-2xl text-red-700 hover:text-red-900 transition duration-200" />
        </button>
      </div>
      {errors.map((error, index) => (
        <p key={index} className="mb-1">{error}</p>
      ))}
    </div>
  );
};

export default ErrorAlerts;


