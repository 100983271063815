export const columnsDataUserReports = [
    {
      Header: 'FULL NAME',
      accessor: 'name',
    },
    {
      Header: 'EMAIL',
      accessor: 'email',
    },
    {
      Header: 'ROLE',
      accessor: 'teamRole',
    },
   
    {
      Header: 'STATUS',
      accessor: 'status',
    },
  ];
  