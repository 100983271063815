import { useEffect, useState } from "react";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import Card from "components/card";
import TeamInfo from "./components/steps/TeamInfo";
import AddMembers from "./components/steps/AddMembers";
import { createTeam, getUserProfile } from "api";
import ErrorAlert from "components/alerts/ErrorAlerts";
import SuccessAlert from "components/alerts/SuccessAlerts";
import { useNavigate } from "react-router-dom";

const ProductNew = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [teamData, setTeamData] = useState({
    name: "",
    description: "",
    schoolName: "",
    mentorId: null,
  });
  const [localMembers, setLocalMembers] = useState([]);
  const [errors, setErrors] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [success, setSuccess] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await getUserProfile();
        setCurrentUser(response.user);
        setTeamData((prev) => ({ ...prev, mentorId: response.user.mentor?.id }));
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, []);

  // Add the useEffect hook to handle the error timeout
  useEffect(() => {
    let timer;
    if (errors.length > 0) {
      timer = setTimeout(() => {
        setErrors([]);
      }, 5000); // Error message will disappear after 5 seconds
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [errors]);

  const steps = [
    { stepNo: 1, name: "Team Info" },
    { stepNo: 2, name: "Add Members" },
  ];

  const displayStep = (step) => {
    switch (step.stepNo) {
      case 1:
        return (
          <TeamInfo
            setTeamData={setTeamData}
            teamData={teamData}
            setHasError={setHasError}
          />
        );
      case 2:
        return (
          <AddMembers
            setLocalMembers={setLocalMembers}
            setTeamData={setTeamData}
            teamData={teamData}
          />
        );
      default:
        return null;
    }
  };

  const validateTeamInfo = () => {
    const validationErrors = [];

    if (!teamData.name.trim()) {
      validationErrors.push("team name");
    }

    if (!teamData.schoolName.trim()) {
      validationErrors.push("school name");
    }

    if (validationErrors.length > 0) {
      setErrors([
        `Please provide the following: ${validationErrors.join(", ")}.`,
      ]);
      return false;
    }

    return true;
  };

  const validateAddMembers = () => {
    if (localMembers.length < 2) {
      setErrors(["Please add at least two team members."]);
      return false;
    }

    if (localMembers.length > 5) {
      setErrors(["A team cannot have more than five members."]);
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateTeamInfo() || !validateAddMembers()) {
      setHasError(true);
      return;
    }

    try {
      const teamPayload = {
        name: teamData.name,
        description: teamData.description,
        schoolName: teamData.schoolName,
        mentorId: teamData.mentorId,
        members: localMembers.map((member) => ({
          id: member.id,
          role: member.role,
        })),
        teamLeader: currentUser.id,
      };


      const createdTeam = await createTeam(teamPayload);

      // Show success alert
      setSuccess("Team created successfully!");
      setTimeout(() => {
        // Navigate to the teams page
        navigate("/admin/teams");
      }, 1000);
    } catch (error) {
      const messages = [];
      if (error.response && error.response.data) {
        const backendError = error.response.data;

        messages.push(backendError.message);

        if (backendError.invalidMembers) {
          messages.push(
            `Members with unmatched school: ${backendError.invalidMembers.join(
              ", "
            )}`
          );
        }
        if (backendError.alreadyInOtherTeam) {
          messages.push(
            `Members already in another team: ${backendError.alreadyInOtherTeam.join(
              ", "
            )}`
          );
        }
        if (backendError.alreadyInThisTeam) {
          messages.push(
            `Members already in this team: ${backendError.alreadyInThisTeam.join(
              ", "
            )}`
          );
        }
      } else {
        messages.push("An unexpected error occurred.");
      }

      setErrors(messages);
      console.error("Error creating new team:", error.message);
    }
  };

  const handleCloseError = () => {
    setErrors([]);
  };

  const handleClick = (direction) => {
    // Clear previous errors before starting validation
    setErrors([]);
    setHasError(false);

    let newStep = currentStep;

    if (direction === "next") {
      if (currentStep === 1) {
        // Validate Team Info step
        if (!validateTeamInfo()) {
          setHasError(true); // Mark that there is an error
          return;
        }
      }

      if (currentStep === 2) {
        // Validate Add Members step
        if (!validateAddMembers()) {
          setHasError(true); // Mark that there is an error
          return;
        }
      }

      newStep++;
      if (newStep > steps.length) {
        handleSubmit();
        return;
      }
    } else {
      newStep--;
    }

    if (newStep > 0 && newStep <= steps.length) {
      setCurrentStep(newStep);
    }
  };

  return (
    <div className="mt-3 h-full w-full relative">
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-orange-400 to-brand-900 md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3 3xl:w-7/12 relative">
        {errors.length > 0 && (
          <ErrorAlert errors={errors} onClose={handleCloseError} />
        )}
        {success && (
          <SuccessAlert message={success} onClose={() => setSuccess("")} />
        )}
        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>
        <Card extra={"h-full mx-auto pb-3"}>
          <div className="rounded-[20px]">
            <UseContextProvider>
              {displayStep(steps[currentStep - 1])}
            </UseContextProvider>
          </div>
          <StepperControl
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
            hasError={hasError}
          />
        </Card>
      </div>
    </div>
  );
};

export default ProductNew;
