// GlassmorphicOverlay.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdLock, MdClose } from 'react-icons/md';

const GlassmorphicOverlay = ({ message }) => {
  const navigate = useNavigate();

  const handleSubscribe = () => {
    navigate('/admin/courses'); // Adjust the path to your courses page
  };

  const handleClose = () => {
    navigate('/admin/dashboard'); // Adjust the path to your home page
  };

  return (
    // Changed 'fixed' to 'absolute' and removed 'inset-0' to prevent covering the entire viewport
    <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
      {/* Modal container */}
      <div className="relative w-full max-w-md md:max-w-xl lg:max-w-2xl p-6 md:p-8 text-center rounded-2xl bg-gray-100 bg-opacity-80 dark:bg-white dark:bg-opacity-10 backdrop-filter backdrop-blur-lg border border-gray-300 dark:border-white border-opacity-50 dark:border-opacity-20 shadow-2xl text-gray-900 dark:text-white">
        {/* Close button */}
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100 focus:outline-none"
        >
          <MdClose size={24} />
        </button>
        {/* Content */}
        <div className="flex flex-col items-center">
          {/* Lock icon */}
          <MdLock className="text-5xl md:text-6xl mb-4" />
          {/* Heading */}
          <h2 className="text-2xl md:text-3xl font-bold mb-4">Access Restricted</h2>
          {/* Message */}
          <p className="mb-6 md:mb-8 text-base md:text-lg px-2">
            {message}
          </p>
          {/* Subscribe button */}
          <button
            onClick={handleSubscribe}
            className="px-6 md:px-8 py-2 md:py-3 rounded-full bg-gradient-to-r from-orange-500 to-pink-500 hover:from-orange-600 hover:to-pink-600 transition-colors text-white font-semibold shadow-lg"
          >
            Subscribe Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default GlassmorphicOverlay;
