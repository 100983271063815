import React from "react";
import './animatedText.css';

const AnimatedText = () => {
  return (
    <div className="animated-title">
      <div className="text-top">
        <div>
          <span>join a</span>
          <span>team today </span>
        </div>
      </div>
      <div className="text-bottom">
        <div>start now!</div>
      </div>
    </div>
  );
};

export default AnimatedText;
