import SearchIcon from "components/icons/SearchIcon";
import { RiListSettingsLine } from "react-icons/ri";
import { MdOutlineGroups } from "react-icons/md";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { getUserProfile, fetchUsersAssignedToMentor, fetchTeamsByMentor } from "api";
import Loader from "components/loaders/Loader";

function SearchTableWithTabs() {
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(location.state?.activeTab || "Users");

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError
  } = useQuery({
    queryKey: ['userProfile'],
    queryFn: getUserProfile,
    refetchOnWindowFocus: true,
  });

  const user = profileData?.user;
  const mentorId = user?.role === 'mentor' ? user.id : user?.mentor?.id;

  const {
    data: usersData,
    isLoading: usersLoading,
    error: usersError
  } = useQuery({
    queryKey: ['usersAssignedToMentor', mentorId],
    queryFn: () => fetchUsersAssignedToMentor(mentorId),
    enabled: !!mentorId && activeTab === 'Users'
  });

  const {
    data: teamsData,
    isLoading: teamsLoading,
    error: teamsError
  } = useQuery({
    queryKey: ['teamsByMentor', mentorId],
    queryFn: () => fetchTeamsByMentor(mentorId),
    enabled: !!mentorId && activeTab === 'Teams'
  });

  const userTableData = useMemo(() => {
    return usersData?.map(user => ({
      name: [
        `${user.firstName} ${user.lastName}`,
        user.imageUrl
          ? `${process.env.REACT_APP_API_BASE_URL}${user.imageUrl}`
          : 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y',
      ],
      email: user.email,
      username: user.username,
      date: user.team?.name || 'No Team', // Team name only, without an image
      type: user.teamRole || 'N/A',
      actions: 'Actions',
    })) || [];
  }, [usersData]);
  

  const teamTableData = useMemo(() => {
    return teamsData?.map(team => ({
      teamName: team.name,
      teamType: team.schoolName || 'N/A',
      createdDate: new Date(team.createdAt).toLocaleDateString(),
      membersCount: team.membersCount || 0,
      actions: 'Actions',
    })) || [];
  }, [teamsData]);

  const columnsDataUsers = useMemo(() => [
    { Header: 'NAME', accessor: 'name' },
    { Header: 'EMAIL', accessor: 'email' },
    { Header: 'USERNAME', accessor: 'username' },
    { Header: 'TEAM NAME', accessor: 'date' },
    { Header: 'ROLE', accessor: 'type' },
    { Header: 'ACTIONS', accessor: 'actions' },
  ], []);

  const columnsDataTeams = useMemo(() => [
    { Header: 'TEAM NAME', accessor: 'teamName' },
    { Header: 'SCHOOL NAME', accessor: 'teamType' },
    { Header: 'CREATED DATE', accessor: 'createdDate' },
    { Header: 'MEMBERS', accessor: 'membersCount' },
    { Header: 'ACTIONS', accessor: 'actions' },
  ], []);

  const columns = useMemo(
    () => (activeTab === "Users" ? columnsDataUsers : columnsDataTeams),
    [activeTab, columnsDataUsers, columnsDataTeams]
  );

  const data = useMemo(
    () => (activeTab === "Users" ? userTableData : teamTableData),
    [activeTab, userTableData, teamTableData]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state,
  } = tableInstance;

  const { pageSize } = state;

  if (profileLoading || usersLoading || teamsLoading) {
    return <Loader />;
  }

  if (profileError || usersError || teamsError) {
    console.error('Error loading data', profileError || usersError || teamsError);
    return <div>Error loading data.</div>
  }

  return (
    <div className="h-full w-full">
      {/* Tabs */}
      <div className="flex space-x-4 mb-4">
        <button
          onClick={() => setActiveTab("Users")}
          className={`px-4 py-2 rounded-lg ${activeTab === "Users" ? "bg-brand-500 text-white" : "bg-gray-100 dark:bg-navy-800 text-gray-600 dark:text-white"}`}
        >
          Users
        </button>
        <button
          onClick={() => setActiveTab("Teams")}
          className={`px-4 py-2 rounded-lg ${activeTab === "Teams" ? "bg-brand-500 text-white" : "bg-gray-100 dark:bg-navy-800 text-gray-600 dark:text-white"}`}
        >
          Teams
        </button>
      </div>

      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex w-[400px] max-w-full items-center rounded-xl p-[8px] pt-[20px] xl:ml-3"
      >
        <div className="flex h-[38px] w-[400px] flex-grow items-center rounded-xl bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white">
          <SearchIcon />
          <input
            type="text"
            placeholder="Search...."
            className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>

      {/* Table */}
      <div className="mt-[14px] h-full w-full overflow-x-scroll 2xl:overflow-x-hidden">
        {data.length > 0 ? (
          <table {...getTableProps()} className="w-full font-dm">
            <thead className="w-full">
              {headerGroups.map((headerGroup, index) => (
                <tr
                  className="items-center border-b border-gray-200 dark:!border-white/10"
                  {...headerGroup.getHeaderGroupProps()}
                  key={index}
                >
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="px-[27px] pt-[32px] pb-[12px] text-start text-xs tracking-wide text-gray-600"
                      key={index}
                    >
                      <div className="text-xs font-bold">
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    className="border-b border-gray-200 dark:!border-white/10"
                    {...row.getRowProps()}
                    key={index}
                  >
                    {row.cells.map((cell, index) => {
                      let dataContent;

                      if (cell.column.Header === "TEAM NAME" && activeTab === "Teams") {
                        // Render team name with the MdOutlineGroups icon
                        dataContent = (
                          <div className="flex w-full items-center gap-[14px]">
                            <div className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-gray-300 overflow-hidden">
                              <MdOutlineGroups className="text-3xl text-orange-400 transform scale-125" />
                            </div>
                            <p className="font-medium text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          </div>
                        );
                      } else if (cell.column.Header === "NAME" && activeTab === "Users") {
                        // Render user profile with image
                        dataContent = (
                          <div className="flex w-full items-center gap-[14px]">
                            <div className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-gray-300 overflow-hidden">
                              <img
                                className="h-full w-full object-cover"
                                src={cell.value[1]}
                                alt={`${cell.value[0]}'s profile`}
                              />
                            </div>
                            <p className="font-medium text-navy-700 dark:text-white">
                              {cell.value[0]}
                            </p>
                          </div>
                        );
                      } else if (cell.column.Header === "ACTIONS") {
                        dataContent = (
                          <div className="flex justify-center">
                            <RiListSettingsLine className="text-xl text-brand-500 hover:cursor-pointer" />
                          </div>
                        );
                      } else {
                        dataContent = (
                          <div className="w-full text-base font-medium text-navy-700 dark:text-white">
                            {cell.value}
                          </div>
                        );
                      }

                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          className="mt-[20px] px-4 py-[16px]"
                        >
                          {dataContent}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="text-center text-gray-500 dark:text-gray-400">No data available</div>
        )}
      </div>

      {/* Pagination */}
      <div className="mt-2 flex h-20 w-full items-center justify-between px-6">
        <div className="flex items-center gap-3">
          <p className="text-sm text-gray-700">Show rows per page</p>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-[32px] w-[75px] rounded-[20px] border border-gray-200 px-2 text-gray-700 dark:!border-white/10 dark:bg-navy-800"
          >
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className="linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            <MdChevronLeft />
          </button>
          <button
            onClick={() => nextPage()}
            className="linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchTableWithTabs;
