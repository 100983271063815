import React, { useState, useEffect } from "react";
import { FaSchool } from "react-icons/fa";
import Card from "components/card";
import { getUserProfile } from "api";

const Upload = () => {
  const [user, setUser] = useState({
    team: '',
    school: ''
  });
  const [hasTeam, setHasTeam] = useState(true);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const data = await getUserProfile();
        const team = data.user.team ? data.user.team.name : null;
        const school = data.user.school;
        setUser({ team, school });
        setHasTeam(!!team);
      } catch (error) {
        console.error("Error fetching user profile", error);
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <Card className="flex flex-col items-center justify-center h-full w-full rounded-[20px] bg-white bg-clip-border p-6 font-dm shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
      <div className="flex flex-col items-center justify-center w-full h-full rounded-xl border-[2px] border-dashed border-gray-200 dark:border-navy-700">
        {/* Icon */}
        <FaSchool className="text-6xl text-brand-500 dark:text-white mb-4" />
        {/* School and Team Information */}
        <div className="text-center">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white mb-2">
            {user.school}
          </h4>
          <p className="text-xl font-medium text-gray-600 dark:text-gray-300">
            {hasTeam ? `Team: ${user.team}` : 'You are not part of a team yet.'}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default Upload;
