import Card from "components/card";
import Switch from "components/switch";
import React, { useState } from "react";

function MentorNotifications() {
  // Initialize state for each notification switch to be on by default
  const [notificationStates, setNotificationStates] = useState({
    switch1: true,
    switch2: true,
    switch3: true,
    switch4: true,
    switch5: true,
  });

  // Function to handle toggling of each switch
  const handleToggle = (id) => {
    setNotificationStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  // Define the list of notifications
  const notifications = [
    { id: "switch1", label: "Step Completion Request Notifications" },
    { id: "switch2", label: "Team Assignment Notifications" },
    { id: "switch3", label: "3D Printing Guidance Request Notifications" },
    { id: "switch4", label: "Event or Deadline Reminder Notifications" },
    { id: "switch5", label: "General Announcements" },
  ];

  return (
    <Card extra={"w-full h-full p-3"}>
      <div className="relative mb-3 flex items-center justify-between pt-1">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Notifications
        </h4>
      </div>
      <div className="flex flex-col gap-4 mt-5">
        {notifications.map((notification) => (
          <div key={notification.id} className="flex items-center gap-3">
            <Switch
              id={notification.id}
              checked={notificationStates[notification.id]}
              onChange={() => handleToggle(notification.id)}
            />
            <label
              htmlFor={notification.id}
              className="text-base font-medium text-navy-700 dark:text-white"
            >
              {notification.label}
            </label>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default MentorNotifications;
