export const columnsDataReferrals = [
  {
    Header: 'USER NAME',
    accessor: 'name',
  },
  {
    Header: 'VALUE',
    accessor: 'value',
  },
  {
    Header: 'PROFIT',
    accessor: 'profit',
  },
  {
    Header: 'ORDERS',
    accessor: 'orders',
  },
];
