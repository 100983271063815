import { useEffect, useState } from "react";
import Card from "components/card";
import TimelineItem from "components/dataDisplay/TimelineItem";
import { fetchUserTimelines, getUserProfile } from "api";

const TimeLine = () => {
  const [timelines, setTimelines] = useState([
    { weekday: "Monday", day: "", title: "No events scheduled", hasEvent: false },
    { weekday: "Tuesday", day: "", title: "No events scheduled", hasEvent: false },
    { weekday: "Wednesday", day: "", title: "No events scheduled", hasEvent: false },
    { weekday: "Thursday", day: "", title: "No events scheduled", hasEvent: false },
    { weekday: "Friday", day: "", title: "No events scheduled", hasEvent: false }
  ]);

  // 1. Load user data, then load timelines for the user’s mentor
  useEffect(() => {
    const loadUserData = async () => {
      try {
        const userProfile = await getUserProfile();
        if (userProfile?.user) {
          // Get all timelines from that user's mentor
          const userTimelines = await fetchUserTimelines(userProfile.user.id);
            if (!userTimelines || userTimelines.length === 0) {
              console.warn("No timelines found, setting default week view");
            }
            processTimelines(userTimelines || []);
        }
      } catch (error) {
        console.error("Error loading user profile:", error);
        processTimelines([]);
      }
    };

    loadUserData();
  }, []);

  // 2. Filter or transform timelines into a Monday-to-Friday structure
  const processTimelines = (timelinesData = []) => {
    // Current date
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
  
    // If Sunday => shift -6, else shift so that we get Monday
    const diff = currentDay === 0 ? -6 : 1 - currentDay;
    const monday = new Date(currentDate);
    monday.setDate(currentDate.getDate() + diff);
  
    // Build array of 5 days: Monday -> Friday
    const weekDays = Array.from({ length: 5 }).map((_, i) => {
      const date = new Date(monday);
      date.setDate(monday.getDate() + i);
      return date;
    });
  
    const placeholders = [
      { weekday: "Monday", date: weekDays[0] },
      { weekday: "Tuesday", date: weekDays[1] },
      { weekday: "Wednesday", date: weekDays[2] },
      { weekday: "Thursday", date: weekDays[3] },
      { weekday: "Friday", date: weekDays[4] },
    ];
  
    const filteredTimelines = placeholders.map((placeholder) => {
      const matchedTimeline = timelinesData.find((timeline) => {
        const startDate = new Date(timeline.startDate);
        return (
          startDate.getFullYear() === placeholder.date.getFullYear() &&
          startDate.getMonth() === placeholder.date.getMonth() &&
          startDate.getDate() === placeholder.date.getDate()
        );
      });
  
      return matchedTimeline
        ? {
            title: matchedTimeline.title,
            day: placeholder.date.getDate(),
            weekday: placeholder.weekday,
            hours: matchedTimeline.hours || "",
            current: matchedTimeline.current ? "current" : "",
            mb: "mb-[16px]",
            hasEvent: true,
          }
        : {
            title: "No events scheduled",
            day: placeholder.date.getDate(),
            weekday: placeholder.weekday,
            hours: "",
            current: "",
            mb: "mb-[16px]",
            hasEvent: false,
          };
    });
  
    setTimelines(filteredTimelines);
  };


  return (
    <Card extra={"w-full p-4 md:p-6 lg:p-8"}>
      {/* Header */}
      <div>
        <h4 className="text-lg md:text-xl font-bold text-navy-700 dark:text-white">
          Timeline
        </h4>
        <p className="text-sm md:text-base font-medium text-gray-600">
          Review your upcoming activities
        </p>
      </div>

      {/* Timeline Items */}
      <div className="mt-[20px] md:mt-[30px]">
        {timelines.map((timeline, index) => (
          <TimelineItem
            key={index}
            title={timeline.title}
            day={timeline.day}
            weekday={timeline.weekday}
            hours={timeline.hours}
            current={timeline.current}
            mb={timeline.mb}
            hasEvent={timeline.hasEvent}
          />
        ))}
      </div>
    </Card>
  );
};

export default TimeLine;
