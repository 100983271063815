import React from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';

const SuccessAlert = ({ message, onClose }) => {
  return (
    <div className="fixed top-4 left-1/2 transform -translate-x-1/2 mb-4 w-full max-w-xl rounded-lg bg-green-100 p-4 text-green-700 z-50 shadow-lg">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <MdCheckCircle className="mr-2 text-2xl" />
          <span className="font-bold">Success</span>
        </div>
        <button onClick={onClose}>
          <AiOutlineClose className="text-2xl text-green-700 hover:text-green-900 transition duration-200" />
        </button>
      </div>
      <p>{message}</p>
    </div>
  );
};

export default SuccessAlert;
