import React, { useState, useEffect } from "react";
import Dropdown from "components/dropdown";
import { IoChatboxEllipses } from "react-icons/io5";
import { fetchUnreadMessages, markMessagesAsRead, getUserProfile } from "api";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchUnreadTeamMessages } from "api";
import { markTeamMessagesAsRead } from "api";

const MessageDropdown = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [error, setError] = useState(null);

  // Clear error after 5 seconds
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const {
    data: userProfileData,
    isLoading: userLoading,
    error: userError,
  } = useQuery({
    queryKey: ['userProfile'],
    queryFn: getUserProfile,
  });

  const userProfile = userProfileData?.user;

  // Fetch unread individual messages
  const {
    data: unreadMessagesData,
    isLoading: messagesLoading,
    error: messagesError,
  } = useQuery({
    queryKey: ['unreadMessages', userProfile?.id],
    queryFn: () => fetchUnreadMessages(userProfile.id),
    enabled: Boolean(userProfile?.id),
    onError: (err) => {
      console.error('Error fetching unread messages:', err);
      setError('Failed to load messages');
    },
  });

  // Fetch unread team messages if the user is part of a team
    const { data: unreadTeamMessagesData, isLoading: teamMessagesLoading, error: teamMessagesError } = useQuery({
      queryKey: ['unreadTeamMessages', userProfile?.team?.id],
      queryFn: () => fetchUnreadTeamMessages(userProfile?.team?.id, userProfile?.id),
      enabled: Boolean(userProfile?.team?.id),
      onError: (err) => {
        console.error('Error fetching unread team messages:', err);
        setError('Failed to load team messages');
      },
    });


  const messages = unreadMessagesData || [];
  const teamMessages = unreadTeamMessagesData || [];

  // Combine and group messages by sender/team
  const combinedMessages = [...messages, ...teamMessages];

  const groupedMessages = combinedMessages.reduce((acc, message) => {
    const key = message.sender?.id || (message.teamId ? `team-${message.teamId}` : 'unknown');

    if (!acc[key] || new Date(message.timestamp) > new Date(acc[key].timestamp)) {
      acc[key] = { ...message, unreadCount: (acc[key]?.unreadCount || 0) + 1 };
    } else {
      acc[key].unreadCount += 1;
    }
    return acc;
  }, {});

  const uniqueMessages = Object.values(groupedMessages).sort(
    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
  );

  // Mutation to mark individual messages as read
  const markAsReadMutation = useMutation({
    mutationFn: (messageIds) => markMessagesAsRead(messageIds),
    onSuccess: () => {
      queryClient.invalidateQueries(['unreadMessages', userProfile?.id]);
    },
    onError: (err) => {
      console.error('Error marking messages as read:', err);
      setError('Failed to mark message as read.');
    },
  });

  // Mutation to mark team messages as read
  const markTeamMessagesAsReadMutation = useMutation({
    mutationFn: (messageIds) => markTeamMessagesAsRead(messageIds),
    onSuccess: () => {
      queryClient.invalidateQueries(['unreadTeamMessages', userProfile?.team?.id]);
    },
    onError: (err) => {
      console.error('Error marking team messages as read:', err);
      setError('Failed to mark team message as read.');
    },
  });

  // Handle individual and team message clicks
  const handleMessageClick = async (message) => {
    try {
      if (message.teamId) {
        // Team: already marking all team messages
        await markTeamMessagesAsReadMutation.mutateAsync(teamMessages.map((msg) => msg.id));
        await queryClient.invalidateQueries(["unreadTeamMessages", userProfile?.team?.id]);
        navigate(`/admin/inbox?teamChatWith=${message.teamId}`);
      } else {
        // Individual: gather all unread from the same sender
        const unreadFromSender = messages.filter(
          (m) => !m.isRead && m.sender?.id === message.sender?.id
        );
        const unreadIds = unreadFromSender.map((m) => m.id);

        // Mark them as read at once
        if (unreadIds.length > 0) {
          await markAsReadMutation.mutateAsync(unreadIds);
        }
        await queryClient.invalidateQueries(["unreadMessages", userProfile?.id]);

        // ADDED LOGIC FOR MENTOR:
        // If the sender is the same as the user's mentor, navigate accordingly
        if (userProfile?.mentor?.id === message.sender?.id) {
          navigate(`/admin/inbox?chatWith=${userProfile.mentor.id}`);
        } else {
          navigate(`/admin/inbox?chatWith=${message.sender?.id}`);
        }
      }
    } catch (error) {
      console.error("Error in handleMessageClick:", error);
    }
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    return date.toDateString() === now.toDateString()
      ? date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      : date.toLocaleDateString();
  };

  const handleViewAllMessages = () => {
    navigate("/admin/inbox");
  };

  const hasNewMessages = uniqueMessages.length > 0;

  if (userLoading || messagesLoading || teamMessagesLoading) {
    return (
      <div className="relative cursor-pointer">
        <IoChatboxEllipses className="h-5 w-5 text-gray-600 dark:text-white" />
      </div>
    );
  }

  if (userError || messagesError || teamMessagesError) {
    console.error("Error loading data:", userError || messagesError || teamMessagesError);
  }

  return (
    <Dropdown
      button={
        <div className="relative cursor-pointer">
          <IoChatboxEllipses
            className={`${
              hasNewMessages ? "h-6 w-6 text-green-500" : "h-5 w-5 text-gray-600 dark:text-white"
            }`}
          />
          {hasNewMessages && (
            <span className="absolute -top-1 -right-1 flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
            </span>
          )}
        </div>
      }
      children={
        <div className="flex w-[350px] flex-col gap-2 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
          <p className="text-lg font-bold text-navy-700 dark:text-white">Messages</p>
          {error && <p className="text-sm text-red-500">{error}</p>}
          <div className="flex flex-col gap-3">
            {uniqueMessages.length > 0 ? (
              uniqueMessages.map((message, index) => (
                <div
                  key={`msg-${index}`}
                  className="flex items-start gap-3 cursor-pointer p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 transition-all"
                  onClick={() => handleMessageClick(message)}
                >
                  <img
                    src={message.sender?.imageUrl || "https://www.gravatar.com/avatar/?d=mp"}
                    alt={message.sender?.firstName || "Team"}
                    className="h-10 w-10 rounded-full object-cover"
                  />
                  <div className="flex-1">
                    <p className="text-sm font-semibold text-navy-700 dark:text-white">
                      {message.sender?.firstName ? `${message.sender.firstName} ${message.sender.lastName}` : "Team Messages"}
                      {message.unreadCount > 1 && (
                        <span className="ml-2 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                          {message.unreadCount} new
                        </span>
                      )}
                    </p>
                    <p className="text-xs text-gray-600 dark:text-gray-300">{message.content}</p>
                    <p className="text-xs text-gray-400 dark:text-gray-500">{formatTime(message.timestamp)}</p>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-sm text-gray-600 dark:text-gray-300">You're all caught up!</p>
            )}
          </div>
          <button
            onClick={handleViewAllMessages}
            className="mt-4 block w-full text-center text-sm font-bold text-brand-500 hover:text-brand-600 dark:text-brand-400 dark:hover:text-brand-300"
          >
            View All Messages
          </button>
        </div>
      }
      classNames={"py-2 top-6 -left-[250px] md:-left-[330px] w-max"}
      animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
    />
  );
};

export default MessageDropdown;
