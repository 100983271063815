import React from 'react';
import teamImage from '../../../assets/img/landing/background4.jpg';

const AboutSection = () => {
    return (
        <section className="bg-white py-20">
            <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
                <div className="lg:flex lg:items-center lg:justify-between">
                    {/* Text content */}
                    <div className="max-w-xl">
                        <h2 className="text-3xl font-extrabold sm:text-5xl">
                            About <span className="text-brand-900">iForge</span>
                        </h2>
                        <p className="mt-4 text-lg leading-relaxed text-gray-800">
                            At iForge, we believe in transforming education through hands-on experiences and cutting-edge technology. With a foundation in 3D printing education, we are expanding our horizons into the world of 3D printer sales. Our journey begins with a passion for fostering creativity, inspiring the next generation of innovators, and reshaping the landscape of STEM education.
                        </p>
                    </div>

                    {/* Image */}
                    <div className="mt-8 lg:mt-0 lg:ml-10">
                        <img
                            src={teamImage}
                            alt="Our Team"
                            className="rounded-xl shadow-lg"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection;
