import React, { useState } from 'react';
import styles from './Sidebar.module.css';

const Sidebar = ({ onSelect }) => {
  const [activeItem, setActiveItem] = useState('miniCourses');

  const handleSelect = (item) => {
    setActiveItem(item);
    onSelect(item);
  };

  return (
    <aside className={styles.sidebar}>
      <nav className={styles.nav}>
        <ul>
          <li
            className={activeItem === 'miniCourses' ? styles.active : ''}
            onClick={() => handleSelect('miniCourses')}
          >
            <a href="#">Mini Courses</a>
          </li>
          <li
            className={activeItem === 'projects' ? styles.active : ''}
            onClick={() => handleSelect('projects')}
          >
            <a href="#">Projects</a>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
