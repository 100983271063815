import { IoClose, IoBook, IoHammer, IoLeaf, IoPeople, IoRocket } from "react-icons/io5";
import { motion } from "framer-motion";
import React from "react";

const DescriptionModal = ({ onClose }) => {
  const courseTitle = "3D Printing with iForge – A Complete Skill-Building & Community Experience";
  const courseDescription = `
    Welcome to iForge! This 8-week, 80-hour course immerses you in the world of 3D printing, 
    guiding you through foundational concepts, advanced techniques, and real-world applications. 
    As you advance through 19 structured stages, you’ll earn POAP NFTs that mark milestones in your journey. 
    Between select stages, you’ll participate in special events that bring learners, mentors, and industry experts together—
    fostering a vibrant community of creators.
  `;

  const howPOAPWorks = [
    "Progressive Skill Building: Move from basic industry knowledge to expert-level design and engineering methods.",
    "POAP NFTs for Every Stage: Each stage completion grants you a unique NFT, celebrating your accomplishments.",
    "Community Events: After key learning segments, join events that let you test new skills, receive feedback, and build relationships."
  ];

  const timelineItems = [
    { type: "Stage", stageNumber: 0, title: "Join iForge Community", nft: "Explorer POAP", description: "Learn how to navigate the iForge platform, form teams, and collaborate effectively." },
    { type: "Stage", stageNumber: 1, title: "The 3D Printing Industry", nft: "Pioneer POAP", description: "Gain a broad understanding of the industry’s key players, trends, and opportunities." },
    { type: "Stage", stageNumber: 2, title: "3D Printing Technologies", nft: "TechMaster POAP", description: "Explore various additive manufacturing methods, materials, and applications." },
    { type: "Event", title: "Industry Networking Meetup", description: "Connect with professionals, discuss the evolving market, and share insights." },
    { type: "Stage", stageNumber: 3, title: "Slicing for 3D Printing", nft: "Precision POAP", description: "Learn the art of turning 3D models into printer-ready instructions." },
    { type: "Stage", stageNumber: 4, title: "Intro to 3D CAD with Tinkercad", nft: "TinkerAce POAP", description: "Get hands-on with beginner-friendly CAD tools to build functional models." },
    { type: "Event", title: "Beginners’ CAD Workshop", description: "Collaborate in guided workshops, improve slicing skills, and receive feedback." },
    { type: "Stage", stageNumber: 5, title: "Mechanism Cubes", nft: "MechaMecha POAP", description: "Design and print mechanical cubes to understand engineering principles." },
    { type: "Stage", stageNumber: 6, title: "Tinkercad to Fusion", nft: "Fusioneer POAP", description: "Transition from Tinkercad to Fusion 360 for advanced design skills." },
    { type: "Event", title: "Design Hackathon", description: "Solve design challenges under time pressure, reinforcing cross-platform modeling skills." },
    { type: "Stage", stageNumber: 7, title: "Intro to 3D CAD with Fusion", nft: "Craftsman POAP", description: "Create complex and refined models using professional-grade software." },
    { type: "Stage", stageNumber: 8, title: "Designing for 3D Printing", nft: "Optimizer POAP", description: "Master advanced techniques to optimize designs for quality and efficiency." },
    { type: "Event", title: "Materials & Methods Expo", description: "Showcase designs, explore materials, and learn from industry experts." },
    { type: "Stage", stageNumber: 9, title: "Design Methods", nft: "MethodMaker POAP", description: "Apply structured approaches to enhance workflow and problem-solving." },
    { type: "Stage", stageNumber: 10, title: "Assistive Bottle Openers", nft: "Assistive POAP", description: "Design functional objects improving accessibility." },
    { type: "Event", title: "Assistive Technology Showcase", description: "Present assistive prototypes, gather feedback, and explore design impact." },
    { type: "Stage", stageNumber: 11, title: "Micro:bit Switch Access", nft: "SwitchHero POAP", description: "Incorporate microcontrollers for interactive solutions." },
    { type: "Stage", stageNumber: 12, title: "Assistive Clips", nft: "ClipCrafter POAP", description: "Refine assistive designs for reliability and ease of use." },
    { type: "Event", title: "Interactive Gadget Fair", description: "Test devices, exchange tips, and solve coding or mechanical challenges." },
    { type: "Stage", stageNumber: 13, title: "Personalized Links", nft: "Linksmith POAP", description: "Craft custom connectors and personalized gifts to show versatility." },
    { type: "Stage", stageNumber: 14, title: "3D Printed Typography", nft: "TypoCrafter POAP", description: "Merge art and engineering, experimenting with typography and branding." },
    { type: "Event", title: "Artistic Expression Meetup", description: "Celebrate creativity and showcase personalized 3D-printed creations." },
    { type: "Stage", stageNumber: 15, title: "Rethinking Plastics", nft: "RePlast POAP", description: "Explore sustainable materials and eco-friendly design principles." },
    { type: "Stage", stageNumber: 16, title: "Functional Wrenches", nft: "ToolCrafter POAP", description: "Design precise, durable working tools." },
    { type: "Stage", stageNumber: 17, title: "Self Watering Planters", nft: "GrowSmart (POAP)", description: "Combine biology and engineering for sustainable systems." },
    { type: "Stage", stageNumber: 18, title: "Suspension Bridge", nft: "SpanMaster (POAP)", description: "Engineer architectural models demonstrating structural integrity." },
    { type: "Event", title: "Sustainability & Engineering Summit", description: "Present projects, network with experts, and celebrate achievements." }
  ];

  const postCourseBenefits = [
    "Practical Expertise: Graduate with the ability to design, print, and optimize complex 3D models.",
    "Professional Portfolio: Showcase a range of projects—assistive devices, artistic prints, engineering marvels—that reflect your growth and capabilities.",
    "Vibrant Community: Remain part of the iForge network, accessing continuous support, future competitions, and advanced workshops.",
    "Recognized Achievements: Your collection of 19 POAP NFTs stands as a permanent testament to your skill development and dedication."
  ];

  const readyToJoin = `
    Ready to Forge Ahead? Join iForge and embark on a transformative learning experience. 
    Gain technical mastery, nurture creativity, and become part of a thriving community of innovators—
    one that values skill, sustainability, and collaboration. Start your journey now and earn NFTs that tell the story of your evolution 
    as a maker, designer, and problem-solver!
  `;

  const getItemIcon = (type) => {
    if (type === "Stage") {
      return <IoHammer />;
    } else if (type === "Event") {
      return <IoPeople />;
    }
    return <IoRocket />;
  };

  // Redesign approach:
  // Present the stages and events in a clean vertical list with subtle dividers.
  // Use distinct background colors and icons for stages vs. events.
  // Keep the layout simple and card-like for better readability.
  // Highlight the NFT: ... (POAP) line with a small badge-like element.
  
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70 backdrop-blur-sm p-4">
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3 }}
        className="relative bg-white dark:bg-navy-800 rounded-2xl shadow-2xl max-w-4xl w-full max-h-[90vh] p-8 overflow-y-auto"
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white transition-colors"
          aria-label="Close Modal"
        >
          <IoClose size={24} />
        </button>

        {/* Modal Header */}
        <div className="mb-6">
          <h2 className="text-4xl font-extrabold text-navy-700 dark:text-white">
            {courseTitle}
          </h2>
        </div>

        {/* Course Description */}
        <div className="mb-8">
          <p className="text-lg text-gray-700 dark:text-gray-300 leading-relaxed whitespace-pre-line">
            {courseDescription}
          </p>
        </div>

        {/* How POAP Works */}
        <div className="mb-10 p-6 rounded-lg bg-gray-100 dark:bg-navy-700">
          <h3 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">
            How the POAP Structure Works
          </h3>
          <ul className="space-y-3 text-gray-700 dark:text-gray-300">
            {howPOAPWorks.map((item, index) => (
              <li key={index} className="flex items-start">
                <span className="mt-1 mr-2 text-orange-500"><IoBook /></span>
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Stages & Events */}
        <div className="mb-10">
          <h3 className="flex items-center text-2xl font-semibold text-navy-700 dark:text-white mb-6">
            <IoBook className="inline-block mr-2 text-orange-500" />
            Stages & Events Overview
          </h3>
          <div className="space-y-6">
            {timelineItems.map((item, idx) => {
              const isStage = item.type === "Stage";
              const containerClasses = isStage
                ? "bg-blue-50 dark:bg-navy-700 border-l-4 border-blue-500"
                : "bg-purple-100 dark:bg-navy-600 border-l-4 border-purple-500";

              return (
                <div key={idx} className={`relative p-4 rounded-md shadow-sm ${containerClasses}`}>
                  <div className="absolute -left-6 top-4 flex h-10 w-10 items-center justify-center bg-white dark:bg-navy-800 rounded-full border-2 border-gray-300 dark:border-gray-600 shadow">
                    <span className={isStage ? "text-blue-600 dark:text-blue-300" : "text-purple-600 dark:text-purple-300"}>
                      {getItemIcon(item.type)}
                    </span>
                  </div>
                  {isStage ? (
                    <div className="pl-8">
                      <h4 className="text-lg font-bold text-navy-700 dark:text-white mb-1">
                        Stage {item.stageNumber}: {item.title}
                      </h4>
                      {item.nft && (
                        <div className="mb-2 inline-block bg-orange-200 dark:bg-orange-600 text-orange-900 dark:text-white px-2 py-1 rounded text-sm font-semibold">
                          NFT: {item.nft}
                        </div>
                      )}
                      <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                        {item.description}
                      </p>
                    </div>
                  ) : (
                    <div className="pl-8">
                      <h4 className="text-lg font-bold text-purple-700 dark:text-purple-300 mb-1">
                        {item.title}
                      </h4>
                      <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                        {item.description}
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {/* Post-Course Benefits */}
        <div className="mb-10 p-6 rounded-lg bg-gray-100 dark:bg-navy-700">
          <h3 className="flex items-center text-2xl font-semibold text-navy-700 dark:text-white mb-4">
            <IoLeaf className="inline-block mr-2 text-green-500" />
            Post-Course Benefits
          </h3>
          <ul className="space-y-3 text-gray-700 dark:text-gray-300 list-disc list-inside">
            {postCourseBenefits.map((benefit, idx) => (
              <li key={idx}>{benefit}</li>
            ))}
          </ul>
        </div>

        {/* Ready to Join Section */}
        <div className="border-t border-gray-300 dark:border-gray-600 pt-6">
          <h3 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">
            Ready to Forge Ahead?
          </h3>
          <p className="text-lg text-gray-700 dark:text-gray-300 leading-relaxed whitespace-pre-line">
            {readyToJoin}
          </p>
        </div>

        {/* Close Modal Button */}
        <div className="mt-10 text-center">
          <button
            onClick={onClose}
            className="inline-flex items-center justify-center px-6 py-3 bg-orange-500 hover:bg-orange-600 text-white text-lg font-medium rounded-lg transition-colors"
          >
            Close
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default DescriptionModal;
