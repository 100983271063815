import React from "react";

function InputField(props) {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    disabled,
    value,
    onChange,
    error,
    icon,
    onIconClick,
  } = props;

  return (
    <div className={`${extra} relative`}>
      {label && (
        <label
          htmlFor={id}
          className={`block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1 ${
            variant === "auth" ? "ml-3 font-medium" : "ml-1.5 font-medium"
          }`}
        >
          {label}
        </label>
      )}
      <div className="relative">
        <input
          disabled={disabled}
          type={type}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={`mt-2 flex h-12 w-full items-center rounded-xl border bg-white p-3 pr-10 text-sm outline-none transition-colors duration-200 ${
            disabled
              ? "border-none bg-gray-100 text-gray-500 placeholder-gray-500 dark:bg-white/5 dark:text-gray-400 dark:placeholder-gray-400"
              : state === "error"
              ? "border-red-500 text-red-500 placeholder:text-red-500 dark:border-red-400 dark:text-red-400 dark:placeholder:text-red-400"
              : "border-gray-200 text-navy-700 placeholder-gray-400 focus:border-orange-500 focus:ring-1 focus:ring-orange-500 dark:border-white/10 dark:bg-navy-800 dark:text-white dark:placeholder-gray-500"
          }`}
        />
        {icon && (
          <span
            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500 dark:text-gray-300"
            onClick={onIconClick}
          >
            {icon}
          </span>
        )}
      </div>
      {error && (
        <p className="mt-1 text-xs text-red-500 dark:text-red-400 ml-3">
          {error}
        </p>
      )}
    </div>
  );
}

export default InputField;
