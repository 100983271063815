import React, { useState } from 'react';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import Description  from './components/Description';
import FeatureSection from './components/FeatureSection';
import BenefitsSection from './components/BenefitsSection';
import HowItWorksSection from './components/HowItWorksSection';
import ChampionshipSection from './components/ChampionshipSection';
import CallToAction from './components/CallToAction';
import Footer from 'components/footer/FooterAuthCentered';

const LandingPage = () => {
    const [hoverState, setHoverState] = useState('none');

    const getSectionStyle = (section) => {
        const isHovered = hoverState === section;
        const notHoveredButActive = hoverState !== 'none' && hoverState !== section;

        return {
            backgroundImage: `url('${section === 'left' ? "https://image.ibb.co/m56Czw/designer.jpg" : "https://image.ibb.co/m3ZbRb/programmer.png"}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            transition: 'width 1s ease-in-out, filter 1s ease-in-out',
            width: hoverState === 'none' ? '50%' : isHovered ? '75%' : '25%',
            height: '100vh', // Set height to viewport height
            filter: notHoveredButActive ? 'brightness(50%)' : 'brightness(100%)',
        };
    };

    return (
        <div className="flex flex-col w-full">
            <HeroSection />


            {/* Top Section with Left and Right */}

            {/* <div className="flex w-full h-screen"> */}
                {/* Left Side */}


                {/* <div
                    className="flex flex-col justify-center items-center w-1/2 bg-cover bg-center"
                    onMouseEnter={() => setHoverState('left')}
                    onMouseLeave={() => setHoverState('none')}
                    style={getSectionStyle('left')}
                >
                    <h1 className="text-4xl text-white z-10 text-center">iForge Community</h1>
                    <button className="mt-10 px-6 py-3 border-2 border-white text-white uppercase font-bold text-lg hover:bg-red-700 hover:border-red-700 transition-all duration-300">Access</button>
                </div> */}

                {/* Right Side */}

                {/* <div
                    className="flex flex-col justify-center items-center w-1/2 bg-cover bg-center"
                    onMouseEnter={() => setHoverState('right')}
                    onMouseLeave={() => setHoverState('none')}
                    style={getSectionStyle('right')}
                >
                    <h1 className="text-4xl text-white z-10 text-center">iForge Center</h1>
                    <button className="mt-10 px-6 py-3 border-2 border-white text-white uppercase font-bold text-lg hover:bg-gray-700 hover:border-gray-700 transition-all duration-300">Access</button>
                </div>
            </div> */}

            {/* Additional Parallax Pages Below with Solid Background Colors */}
            <div className="w-full h-screen flex justify-center items-center">            
                <AboutSection />
            </div>
            
            <div className="w-full h-full">
                <FeatureSection />
            </div>


            <div className="w-full h-full">
                <HowItWorksSection />
            </div>

            <div className="w-full h-full">
                <ChampionshipSection />
            </div>

            <div className="w-full h-full">
                <CallToAction/>
            </div>

            <div className="w-full h-full">
                <Footer />
            </div>



        </div>
    );
};

export default LandingPage;
