import { useState } from "react";
import SetUp from "components/actions/SetUp";
import Card from "components/card";

const TwoFactor = ({ isEnabled, onToggle }) => {
  const [enabled, setEnabled] = useState(isEnabled);

  
  
  const handleToggle = () => {
    const newStatus = !enabled;
    setEnabled(newStatus);
    onToggle(newStatus); 
  };

  return (
    <Card extra={"w-full px-8 py-8"}>
      {/* Header */}
      <div className="flex items-center justify-between">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Two-Factor Authentication
        </h4>
        <button
          onClick={handleToggle}
          className={`mt-1 flex items-center justify-center rounded-[10px] px-3 py-1 text-sm font-bold uppercase ${
            enabled ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'
          } dark:bg-green-50`}
        >
          {enabled ? 'Enabled' : 'Disabled'}
        </button>
      </div>
      {/* content */}
      <SetUp
        name="Authenticator App"
        value={enabled ? "Configured" : "Not configured"}
        actionName="Set up"
        py="py-6"
        border="border-b"
      />
      <SetUp
        py="py-6"
        border="border-b"
        name="Security Keys"
        value="No keys added"
        actionName="Set up"
      />
      <SetUp
        py="py-6"
        name="Telephone Number"
        value="Not Configured"
        actionName="Set up"
      />
    </Card>
  );
};

export default TwoFactor;
