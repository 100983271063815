import React from 'react';
import styles from './CourseContent.module.css';

const Card = ({ number, title, description, date, tags, bgImage, href }) => {
  return (
    <div className={styles.cardGridSpace}>
      {/* Number */}
      <div className={styles.num}>
        {number.toString().padStart(2, '0')}
      </div>

      {/* Card */}
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={`${styles.card}`}
        style={{
          '--bg-img': `url(${bgImage})`,
        }}
      >
        {/* Content */}
        <div>
          <h1>{title}</h1>
          <p>{description}</p>
          <div className={styles.date}>{date}</div>
          <div className={styles.tags}>
            {tags.map((tag, index) => (
              <div key={index} className={styles.tag}>
                {tag}
              </div>
            ))}
          </div>
        </div>
      </a>
    </div>
  );
};

export default Card;
