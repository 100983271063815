import React, { useEffect, useState } from 'react';
import Card from 'components/card';
import { fetchUnreadMessages, getUserProfile } from 'api'; // Add fetchUnreadMessages and getUserProfile
import { FiMail } from 'react-icons/fi';
import { useQuery } from '@tanstack/react-query';

const SERVER_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const InboxCard = () => {
  
  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError,
  } = useQuery({
    queryKey: ['userProfile'],
    queryFn: getUserProfile,
    refetchOnWindowFocus: true,
  });

  const userId = profileData?.user?.id;

  // Fetch unread messages
  const {
    data: messagesData,
    isLoading: messagesLoading,
    error: messagesError,
  } = useQuery({
    queryKey: ['unreadMessages', userId],
    queryFn: () => fetchUnreadMessages(userId),
    enabled: !!userId,
    refetchOnWindowFocus: true,
    refetchInterval: 60000,
  });

  if (profileLoading || messagesLoading) {
    return (
      <Card extra="p-6 rounded-[20px] bg-white dark:bg-navy-800 shadow-lg h-full">
        <h3 className="text-xl font-bold text-navy-700 dark:text-white mb-4 flex items-center">
          <FiMail className="mr-2" />
          Inbox
        </h3>
        <p className="text-gray-600 dark:text-gray-300">Loading messages...</p>
      </Card>
    );
  }

  if (profileError || messagesError) {
    console.error('Error loading messages:', profileError || messagesError);
    return (
      <Card extra="p-6 rounded-[20px] bg-white dark:bg-navy-800 shadow-lg h-full">
        <h3 className="text-xl font-bold text-navy-700 dark:text-white mb-4 flex items-center">
          <FiMail className="mr-2" />
          Inbox
        </h3>
        <p className="text-red-600 dark:text-red-400">
          Failed to load messages: {(profileError || messagesError).message}
        </p>
      </Card>
    );
  }

  const messages = messagesData || [];

  return (
    <Card extra="p-6 rounded-[20px] bg-white dark:bg-navy-800 shadow-lg h-full">
      <h3 className="text-xl font-bold text-navy-700 dark:text-white mb-4 flex items-center">
        <FiMail className="mr-2" />
        Inbox
      </h3>
      {messages.length === 0 ? (
        <p className="text-gray-600 dark:text-gray-300">No unread messages.</p>
      ) : (
        <ul className="space-y-4 overflow-y-auto" style={{ maxHeight: '24rem' }}>
          {messages.map((message) => (
            <li
              key={message.id}
              className="flex items-center space-x-4 p-4 dark:bg-navy-700 rounded-lg hover:bg-gray-100 dark:hover:bg-navy-600 transition duration-300 ease-in-out"
            >
              <img
                src={
                  message.sender.imageUrl
                    ? `${SERVER_BASE_URL}${message.sender.imageUrl}`
                    : 'https://via.placeholder.com/48'
                }
                alt={`${message.sender.firstName} ${message.sender.lastName}`}
                className="h-12 w-12 rounded-full object-cover border-2 border-white dark:border-navy-800 shadow-sm"
              />
              <div className="flex-1">
                <div className="flex justify-between items-center mb-1">
                  <h4 className="text-base font-semibold text-navy-700 dark:text-white">
                    {message.sender.firstName} {message.sender.lastName}
                  </h4>
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    {new Date(message.timestamp).toLocaleString()}
                  </span>
                </div>
                <p className="text-sm text-gray-600 dark:text-gray-300 leading-relaxed">
                  {message.content}
                </p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </Card>
  );
};

export default InboxCard;
