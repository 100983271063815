import React from 'react';
import logo from '../../../assets/img/dashboards/Logo iForge-8.png'
import heroBackground from '../../../assets/img/landing/3d printers.jpg'

const HeroSection = () => {
  return (
    <section className="relative bg-heroPattern bg-cover bg-center bg-no-repeat">
      {/* Overlay with gradient effect */}
      <div className="absolute inset-0 bg-white/50 sm:bg-transparent sm:bg-gradient-to-r from-white/95 to-white/25 z-10"></div>
      
      {/* Logo in the top left corner with a higher z-index to make it visible over the overlay */}
      <div className="absolute top-5 left-5 sm:left-10 lg:left-20 z-20">
        <img src={logo} alt="Logo" className="h-10 sm:h-12 lg:h-16"/>
      </div>

      <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8 z-10">
        <div className="max-w-xl text-center sm:text-left">
          <h1 className="text-3xl font-extrabold sm:text-5xl">
            Let us help you discover 
            <strong className="block font-extrabold text-brand-900"> Ideal Creation Space.</strong>
          </h1>

          <p className="mt-4 max-w-lg sm:text-xl leading-relaxed">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus
            numquam ea!
          </p>

          <div className="mt-8 flex flex-wrap gap-4 justify-center sm:justify-start">
            <button
              className="rounded-xl bg-gradient-to-br from-[#ff7a33] to-[#e65c00] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#FFC837]/50"
            >
              Get Started
            </button>

            <button             
              className="rounded-xl border-2 border-orange-500 px-5 py-3 text-base font-medium text-orange-500 transition duration-200 hover:bg-orange-600/5 active:bg-orange-700/5 dark:border-orange-400 dark:bg-orange-400/10 dark:text-white dark:hover:bg-orange-300/10 dark:active:bg-orange-200/10"
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
