export const tableColumnsTeams = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'School',
      accessor: 'schoolName',
    },
    {
      Header: 'Points',
      accessor: 'points',
    },
    {
      Header: 'Current POAP',
      accessor: 'currentPoapLevel',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
    },
  ];
  