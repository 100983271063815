import React from "react";
import Card from "components/card";

const PrivacyPolicy = () => {
  return (
    <div className="flex justify-center mt-10 px-4">
      <Card extra="w-full lg:w-2/3 p-8 bg-white dark:bg-navy-700 rounded-2xl shadow-lg">
        <h1 className="text-4xl font-bold text-navy-700 dark:text-white mb-6 text-center">Privacy Policy</h1>
        <div className="text-navy-700 dark:text-gray-200 space-y-8">
          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">1. Introduction</h2>
            <p className="text-lg">
              Welcome to InnovatorsForge.com We value your privacy and are committed to protecting your personal data. This privacy policy
              will inform you about how we handle your personal data when you use our services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">2. Information We Collect</h2>
            <p className="text-lg">
              We may collect various types of information in connection with your use of our services, including:
              <ul className="list-disc ml-5 mt-2">
                <li>Personal identification information (Name, email address, phone number, etc.)</li>
                <li>Technical data (IP address, browser type and version, time zone setting, etc.)</li>
                <li>Usage data (information about how you use our website, products, and services)</li>
              </ul>
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">3. How We Use Your Information</h2>
            <p className="text-lg">
              We use your information to:
              <ul className="list-disc ml-5 mt-2">
                <li>Provide, operate, and maintain our services</li>
                <li>Improve, personalize, and expand our services</li>
                <li>Understand and analyze how you use our services</li>
                <li>Communicate with you, either directly or through one of our partners</li>
                <li>Send you updates and other information relating to the service</li>
              </ul>
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">4. Sharing Your Information</h2>
            <p className="text-lg">
              We do not share or sell your personal data to third parties, except in the following cases:
              <ul className="list-disc ml-5 mt-2">
                <li>With your consent</li>
                <li>For external processing (with trusted partners who comply with our data protection policies)</li>
                <li>For legal reasons (to comply with any applicable law, regulation, legal process, or enforceable governmental request)</li>
              </ul>
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">5. Data Security</h2>
            <p className="text-lg">
              We have implemented appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed
              in an unauthorized way, altered, or disclosed.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">6. Your Data Protection Rights</h2>
            <p className="text-lg">
              Depending on your location, you may have the following rights regarding your personal data:
              <ul className="list-disc ml-5 mt-2">
                <li>The right to access – You have the right to request copies of your personal data.</li>
                <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate.</li>
                <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
                <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
                <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
                {/* Add any other rights that apply based on your specific regulations */}
              </ul>
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">7. Changes to This Privacy Policy</h2>
            <p className="text-lg">
              We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.
              You are advised to review this privacy policy periodically for any changes.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">8. Contact Us</h2>
            <p className="text-lg">
              If you have any questions about this privacy policy, please contact us at <a href="mailto:support@iforge.io" className="text-brand-500 dark:text-brand-400 hover:underline">support@iforge.io</a>.
            </p>
          </section>
        </div>
      </Card>
    </div>
  );
};

export default PrivacyPolicy;