import { useState, useEffect, useCallback } from "react";
import InputField from "components/fields/InputField";
import {
  IoSearchOutline,
  IoPersonAddOutline,
  IoPersonRemoveOutline,
  IoAlertCircle,
} from "react-icons/io5";
import { searchUsersBySchool, getUserProfile, checkSubscriptionEligibility } from "api";
import debounce from "lodash.debounce";

const roles = [
  "Design Specialist",
  "Technical Specialist",
  "Research & Development Specialist",
  "Documentation & Presentation Specialist",
];

const MIN_MEMBERS = 2;
const MAX_MEMBERS = 5;

const getImageUrl = (imageUrl) => {
  return imageUrl
    ? `${process.env.REACT_APP_API_BASE_URL}${imageUrl}`
    : "https://via.placeholder.com/150";
};

const AddMembers = ({ setLocalMembers, teamData }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [noUserFound, setNoUserFound] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState({});

  // Fetch current user profile on mount
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getUserProfile();
        setCurrentUser(profile.user);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };
    fetchUserProfile();
  }, []);

  // Handle search input change with debounce
  const handleSearchChange = useCallback(
    debounce(async (value) => {
      const trimmedValue = value.trim();

      // Check if the input is less than 3 characters
      if (!trimmedValue || !currentUser || trimmedValue.length < 2) {
        setSearchResults([]);
        setNoUserFound(false);
        return;
      }

      try {
        setSearchLoading(true);

        // Fetch users by school, excluding current user
        const users = await searchUsersBySchool(trimmedValue, currentUser.id);

        // Filter users to exclude mentor, current user, and already selected members
        const filteredUsers = users.filter(
          (user) =>
            user.id !== teamData.mentorId && // Exclude mentor
            user.username !== currentUser.username && // Exclude current user
            !selectedMembers.some((member) => member.id === user.id) // Exclude already selected members
        );

        if (filteredUsers.length > 0) {
          // Fetch subscription eligibility for all filtered users
          const userIds = filteredUsers.map((user) => user.id);
          const eligibilityData = await checkSubscriptionEligibility(userIds);

          // Create a map of userId to isEligible for efficient lookup
          const eligibilityMap = {};
          eligibilityData.forEach(({ userId, isEligible }) => {
            eligibilityMap[userId] = isEligible;
          });

          // Add subscription status to each user
          const usersWithEligibility = filteredUsers.map((user) => ({
            ...user,
            isSubscribed: eligibilityMap[user.id] || false, // Default to false if not found
          }));

          setSearchResults(usersWithEligibility);
          setNoUserFound(false);

          // Identify all roles to clear
          const rolesToClear = usersWithEligibility
            .filter((user) => !user.isSubscribed && selectedRoles[user.id])
            .map((user) => user.id);

          // Clear selected roles for users who are not subscribed
          if (rolesToClear.length > 0) {
            setSelectedRoles((prevRoles) => {
              const newRoles = { ...prevRoles };
              rolesToClear.forEach((id) => {
                delete newRoles[id];
              });
              return newRoles;
            });
          }
        } else {
          setSearchResults([]);
          setNoUserFound(true);
        }
      } catch (error) {
        console.error("Error searching user:", error);
        setSearchResults([]);
        setNoUserFound(true);
      } finally {
        setSearchLoading(false);
      }
    }, 300),
    [teamData, currentUser, selectedMembers, selectedRoles]
  );

  // Get list of roles already assigned in search results and selected members
  const assignedRoles = [
    ...Object.values(selectedRoles),
    ...selectedMembers.map((member) => member.role),
  ];

  // Add member to the selected members list
  const handleAddMember = useCallback(
    (user) => {
      if (selectedMembers.length >= MAX_MEMBERS) {
        setErrorMessage(`A team cannot have more than ${MAX_MEMBERS} members.`);
        return;
      }

      const alreadyInTeam = selectedMembers.some(
        (member) => member.id === user.id
      );

      if (alreadyInTeam) {
        setErrorMessage("This user is already in the team.");
        return;
      }

      const role = selectedRoles[user.id];
      if (!role) {
        setErrorMessage("Please select a role before adding the member.");
        return;
      }

      const newMember = { ...user, role };
      setSelectedMembers((prevMembers) => [...prevMembers, newMember]);

      // Remove the role from selectedRoles
      setSelectedRoles((prevRoles) => {
        const newRoles = { ...prevRoles };
        delete newRoles[user.id];
        return newRoles;
      });

      setSearchQuery("");
      setSearchResults([]);
      setNoUserFound(false);
      setErrorMessage("");
    },
    [selectedMembers, selectedRoles, MAX_MEMBERS]
  );

  // Remove member from the selected members list
  const handleRemoveMember = useCallback((userId) => { // Changed parameter to userId
    setSelectedMembers((prevMembers) =>
      prevMembers.filter((member) => member.id !== userId) // Changed filter condition to use id
    );
  }, []);

  // Handle role change for a user
  const handleRoleChange = useCallback(
    (userId, newRole) => {
      setSelectedRoles((prevRoles) => {
        // Check if the role is already assigned in selectedRoles or selectedMembers
        const isRoleAlreadyAssignedInRoles = Object.entries(prevRoles).some(
          ([id, role]) => role === newRole && id !== userId.toString()
        );

        const isRoleAlreadyAssignedInMembers = selectedMembers.some(
          (member) => member.role === newRole && member.id !== userId
        );

        if (isRoleAlreadyAssignedInRoles || isRoleAlreadyAssignedInMembers) {
          setErrorMessage(`The role "${newRole}" is already assigned to another member.`);
          return prevRoles; // Do not update
        }

        // Update selectedRoles
        const updatedRoles = {
          ...prevRoles,
          [userId]: newRole,
        };

        // Update selectedMembers if the user is already a member
        setSelectedMembers((prevMembers) =>
          prevMembers.map((member) =>
            member.id === userId ? { ...member, role: newRole } : member
          )
        );

        return updatedRoles;
      });
    },
    [setErrorMessage, selectedMembers]
  );

  // Update local members in the parent component
  useEffect(() => {
    setLocalMembers(selectedMembers);
  }, [selectedMembers, setLocalMembers]);

  return (
    <div className="h-full w-full p-4 md:p-8 bg-white dark:bg-navy-700 rounded-lg shadow-lg">
      <h4 className="flex items-center gap-2 mb-6 text-xl md:text-2xl font-bold text-navy-700 dark:text-white">
        <IoPersonAddOutline className="text-2xl md:text-3xl" /> Add Members
      </h4>
      <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
        Add at least {MIN_MEMBERS} members and up to {MAX_MEMBERS} members to complete your team.
      </p>

      {errorMessage && (
        <div className="mb-4 p-4 flex items-center text-red-600 dark:text-red-400 bg-red-100 dark:bg-red-900 rounded-lg">
          <IoAlertCircle className="mr-2 text-xl" />
          <p className="text-sm">{errorMessage}</p>
        </div>
      )}

      <InputField
        extra="w-full mb-6"
        label="Search Member by Username"
        placeholder="e.g., johndoe"
        id="searchmember"
        type="text"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          handleSearchChange(e.target.value);
        }}
        icon={<IoSearchOutline className="text-xl" />}
      />

      {searchLoading && (
        <div className="text-gray-500 dark:text-gray-400 mb-4">Searching...</div>
      )}

      {searchQuery && searchResults.length > 0 && (
        <div className="mb-4">
          {searchResults.map((user) => {
            // Get available roles for this user by excluding roles already assigned
            const availableRoles = roles.filter(
              (role) => !assignedRoles.includes(role) || selectedRoles[user.id] === role
            );

            // Determine why the "Add" button might be disabled
            let tooltipMessage = "";
            if (!user.isSubscribed) {
              tooltipMessage = "This user is not subscribed.";
            } else if (!selectedRoles[user.id]) {
              tooltipMessage = "Please select a role before adding.";
            }

            return (
              <div
                key={user.id}
                className="flex flex-col md:flex-row items-start md:items-center justify-between p-4 mb-2 rounded-lg bg-gray-50 dark:bg-navy-700 hover:bg-gray-100 dark:hover:bg-navy-600 transition-colors"
              >
                <div className="flex items-center mb-2 md:mb-0 flex-1 min-w-0">
                  <img
                    src={getImageUrl(user.imageUrl)}
                    alt=""
                    className="w-12 h-12 rounded-full object-cover shadow-lg mr-4"
                  />
                  <div className="truncate">
                    <p className="text-sm font-medium text-gray-800 dark:text-white">
                      {user.firstName} {user.lastName}
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      {user.username}
                    </p>
                  </div>

                </div>
                {/* Subscription Badge */}
                <div className="flex-shrink-0 min-w-[120px] md:min-w-[150px] flex items-center justify-center mb-2 md:mb-0">
                  <span
                    className={`text-xs font-semibold px-2 py-1 rounded ${
                      user.isSubscribed
                        ? "bg-green-100 text-green-600"
                        : "bg-red-100 text-red-600"
                    }`}
                    title={`This user is ${
                      user.isSubscribed ? "subscribed" : "not subscribed"
                    }.`}
                  >
                    {user.isSubscribed ? "Subscribed" : "Not Subscribed"}
                  </span>
                </div>
                <div className="flex flex-col sm:flex-row gap-2 items-stretch w-full md:w-auto">
                  <select
                    className="rounded-xl border border-gray-200 dark:border-white/10 bg-white dark:bg-navy-900 text-navy-700 dark:text-white focus:border-orange-400 dark:focus:border-orange-500 focus:outline-none text-sm w-full sm:w-auto px-4 py-2 transition duration-200 ease-in-out hover:bg-gray-50 dark:hover:bg-navy-800 shadow-md"
                    value={selectedRoles[user.id] || ""}
                    onChange={(e) => handleRoleChange(user.id, e.target.value)} // Use handleRoleChange
                    disabled={!user.isSubscribed} // Disable if not subscribed
                  >
                    <option value="" disabled className="text-gray-500 dark:text-gray-400">
                      Select Role
                    </option>
                    {availableRoles.map((role) => (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    ))}
                  </select>
                  <div className="relative group">
                    <button
                      className={`flex items-center justify-center px-3 py-2 text-sm font-medium rounded-xl w-full sm:w-auto ${
                        user.isSubscribed && selectedRoles[user.id]
                          ? "bg-orange-500 text-white hover:bg-orange-600 active:bg-orange-700"
                          : "bg-gray-300 text-gray-500 cursor-not-allowed"
                      }`}
                      onClick={() => user.isSubscribed && handleAddMember(user)}
                      disabled={!user.isSubscribed || !selectedRoles[user.id]} // Disable if not subscribed or no role selected
                      aria-label={`Add ${user.username}`}
                    >
                      <IoPersonAddOutline className="mr-1" />
                      Add
                    </button>
                    {/* Tooltip */}
                      {(!user.isSubscribed || !selectedRoles[user.id]) && (
                        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-2 py-1 bg-gray-800 text-white text-xs rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none z-10">
                          {tooltipMessage}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {searchQuery && noUserFound && (
        <div className="mb-4 p-2 bg-red-100 dark:bg-red-800 rounded-md shadow-inner">
          <p className="text-red-500 dark:text-red-400">User not found</p>
        </div>
      )}

      <div className="space-y-4">
        <h5 className="text-lg font-bold text-navy-700 dark:text-white">
          Selected Members ({selectedMembers.length}/{MAX_MEMBERS}):
        </h5>
        {selectedMembers.length === 0 ? (
          <p className="text-gray-500 dark:text-gray-400">
            No members selected.
          </p>
        ) : (
          <ul className="space-y-2">
            {selectedMembers.map((member) => (
              <li
                key={member.id} // Changed from member.username to member.id
                className="flex items-center justify-between p-2 bg-white dark:bg-navy-800 rounded-md shadow-sm"
              >
                <div className="flex items-center">
                  <img
                    src={getImageUrl(member.imageUrl)}
                    alt=""
                    className="w-10 h-10 rounded-full object-cover shadow-lg mr-4"
                  />
                  <div className="truncate">
                    <p className="font-medium text-navy-700 dark:text-white">
                      {member.firstName} {member.lastName}
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {member.username}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <select
                    value={member.role}
                    onChange={(e) =>
                      handleRoleChange(member.id, e.target.value) // Pass member.id
                    }
                    className="bg-white dark:bg-navy-700 border border-gray-300 dark:border-navy-600 rounded-lg text-navy-700 dark:text-white px-3 py-2 text-sm focus:outline-none"
                    aria-label={`Select role for ${member.username}`}
                  >
                    <option value="" disabled className="text-gray-500 dark:text-gray-400">
                      Select Role
                    </option>
                    {roles.map((role) => (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    ))}
                  </select>
                  <button
                    onClick={() => handleRemoveMember(member.id)} // Pass member.id
                    className="text-red-500 dark:text-red-400 focus:outline-none"
                    aria-label={`Remove ${member.username}`}
                  >
                    <IoPersonRemoveOutline className="text-xl" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AddMembers;
