import React, { useState } from "react";
import Modal from "components/modal/Modal";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField"
import { MdDelete } from "react-icons/md";

const EditTeamModal = ({ team, onClose, onSave, onDelete }) => {
  const [name, setName] = useState(team.name);
  const [description, setDescription] = useState(team.description);
  const [schoolName, setSchoolName] = useState(team.schoolName);
  const [points, setPoints] = useState(team.points);

  const handleSave = () => {
    const updatedTeam = { ...team, name, description, schoolName, points };
    onSave(updatedTeam);
    onClose();
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this team?")) {
      onDelete(team.id);
      onClose();
    }
  };

  return (
    <Modal onClose={onClose}>
      <div className="p-6 bg-white rounded-lg shadow-lg dark:bg-navy-800 max-w-lg mx-auto">
        <h3 className="text-2xl font-semibold text-navy-700 dark:text-white mb-6">
          Edit Team
        </h3>
        <form className="space-y-5">
          <div>
            <InputField
              type="text"
              label= "Team Name"
              className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-200 focus:ring-opacity-50 dark:bg-navy-900 dark:text-white"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter team name"
            />
          </div>
          <div>
            <TextField
              label= "Description"
              className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-200 focus:ring-opacity-50 dark:bg-navy-900 dark:text-white"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="4"
              placeholder="Describe the team's goals and objectives"
            />
          </div>
          <div>
            <InputField
              type="text"
              label= "School Name"
              className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-200 focus:ring-opacity-50 dark:bg-navy-900 dark:text-white"
              value={schoolName}
              onChange={(e) => setSchoolName(e.target.value)}
              placeholder="Enter school name"
            />
          </div>
          <div>

            <InputField
              type="number"
              label= "Points"
              className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-200 focus:ring-opacity-50 dark:bg-navy-900 dark:text-white"
              value={points}
              onChange={(e) => setPoints(e.target.value)}
              placeholder="Enter points"
            />
          </div>
          <div className="flex justify-between items-center mt-6">
            <button
              className="flex flex-row items-center rounded-xl bg-red-500 px-4 py-3 text-sm font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
              onClick={handleDelete}
              type="button"
            >
              <MdDelete className="mr-2" />
              Delete
            </button>
            <div className="flex space-x-4">
              <button
                className="rounded-lg bg-gray-200 py-2 px-4 text-sm font-medium text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 transition duration-200"
                onClick={onClose}
                type="button"
              >
                Cancel
              </button>
              <button
                className="flex flex-row items-center rounded-xl bg-orange-500 px-4 py-3 text-sm font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-orange-400 dark:text-white dark:hover:bg-orange-300 dark:active:bg-orange-200"
                onClick={handleSave}
                type="button"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditTeamModal;
