import Card from "components/card";
import React, { useEffect, useState } from "react";
import { getUserProfile } from "api";

const General = () => {
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    school: '',
    enrollmentYear: '2022',
    status: 'Online'
  });

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const data = await getUserProfile();
        setUser({
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          username: data.user?.username || "",
          email: data.user.email,
          school: data.user.school,
          enrollmentYear: '2024',
          status: 'Online'
        });
      } catch (error) {
        console.error("Error fetching user profile", error);
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <Card extra={"w-full h-full p-3"}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          General Information
        </h4>
        <p className="mt-2 px-2 text-base text-gray-600">
          Welcome to your profile! Here you can view and manage your personal information related to the 3D printing community.
        </p>
      </div>
      {/* Cards */}
      <div className="grid grid-cols-1 gap-4 px-2 sm:grid-cols-2">
        <div className="shadow-[30px] flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-shadow-500 dark:bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Full Name</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {user.firstName} {user.lastName}
          </p>
        </div>

        <div className="shadow-[30px] flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-shadow-500 dark:bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Username</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {user.username}
          </p>
        </div>

        <div className="shadow-[30px] flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-shadow-500 dark:bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Email</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {user.email}
          </p>
        </div>

        <div className="shadow-[30px] flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-shadow-500 dark:bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">School</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {user.school}
          </p>
        </div>

        <div className="shadow-[30px] flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-shadow-500 dark:bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Enrollment Year</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {user.enrollmentYear}
          </p>
        </div>

        <div className="shadow-[30px] flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-shadow-500 dark:bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Status</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {user.status}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default General;
