export const columnsDataTeamsOverview = [
    {
      Header: 'TEAM NAME',
      accessor: 'teamName',
    },
    {
      Header: 'TEAM TYPE',
      accessor: 'teamType',
    },
    {
      Header: 'CREATED DATE',
      accessor: 'createdDate',
    },
    {
      Header: 'STATUS',
      accessor: 'status',
    },
    {
      Header: 'ACTIONS',
      accessor: 'actions',
    },
  ];
  