import React, { useState, useEffect, useCallback } from 'react';
import {
  IoSearchOutline,
  IoPersonAddOutline,
  IoCloseOutline,
  IoCheckmarkCircle,
  IoAlertCircle,
} from 'react-icons/io5';
import {
  searchUsersBySchool,
  addMembersToTeam,
  getUserProfile,
  checkSubscriptionEligibility, // Importing the subscription eligibility function
} from 'api';
import debounce from 'lodash.debounce';

// Utility function to get user image URL
const getImageUrl = (imageUrl) => {
  return imageUrl
    ? `${process.env.REACT_APP_API_BASE_URL}${imageUrl}`
    : 'https://via.placeholder.com/150';
};

// Define available roles
const roles = [
  'Design Specialist',
  'Technical Specialist',
  'Research & Development Specialist',
  'Documentation & Presentation Specialist',
];

const SearchModal = ({ onClose }) => {
  // State variables
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState({});
  const [statusMessage, setStatusMessage] = useState({ type: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);

  // Fetch current user profile and team ID on component mount
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await getUserProfile();
        setCurrentUser(response.user);
        setTeamId(response.user.team?.id);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  // Debounced search handler to optimize API calls
  const handleSearchChange = useCallback(
    debounce(async (value) => {
      setSearchTerm(value);
      if (value.trim() === '' || !currentUser) {
        setSearchResults([]);
        return;
      }

      setIsLoading(true);
      try {
        // Search users by school, excluding the current user
        const users = await searchUsersBySchool(value, currentUser.id);

        // Filter out mentor, current user, and already selected members
        const filteredUsers = users.filter(
          (user) =>
            user.id !== currentUser.team?.mentorId &&
            user.username !== currentUser.username &&
            !searchResults.some((member) => member.id === user.id)
        );

        if (filteredUsers.length > 0) {
          // Fetch subscription eligibility
          const userIds = filteredUsers.map((user) => user.id);
          const eligibilityData = await checkSubscriptionEligibility(userIds);

          // Create a map of userId to isSubscribed
          const eligibilityMap = {};
          eligibilityData.forEach(({ userId, isEligible }) => {
            eligibilityMap[userId] = isEligible;
          });

          // Attach subscription status to each user
          const usersWithEligibility = filteredUsers.map((user) => ({
            ...user,
            isSubscribed: eligibilityMap[user.id] || false,
          }));

          setSearchResults(usersWithEligibility);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error('Error searching users:', error);
        setSearchResults([]);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    [currentUser]
  );

  // Handle role selection ensuring uniqueness
  const handleRoleChange = (personId, role) => {
    // Check if the selected role is already assigned to another member
    const isRoleTaken =
      Object.values(selectedRoles).includes(role) &&
      selectedRoles[personId] !== role;

    if (isRoleTaken) {
      setStatusMessage({
        type: 'error',
        message: `The role "${role}" is already assigned to another member.`,
      });
      setTimeout(() => setStatusMessage({ type: '', message: '' }), 3000);
      return;
    }

    setSelectedRoles((prevRoles) => ({
      ...prevRoles,
      [personId]: role,
    }));
  };

  // Handle adding a person to the team
  const handleAddPerson = async (person) => {
    const role = selectedRoles[person.id];

    if (!role) {
      setStatusMessage({
        type: 'error',
        message: 'Please select a role before adding the member.',
      });
      setTimeout(() => setStatusMessage({ type: '', message: '' }), 3000);
      return;
    }

    if (!person.isSubscribed) {
      setStatusMessage({
        type: 'error',
        message: 'Cannot add this user as they are not subscribed.',
      });
      setTimeout(() => setStatusMessage({ type: '', message: '' }), 3000);
      return;
    }

    try {
      // Add member to the team
      await addMembersToTeam(teamId, [{ id: person.id, teamRole: role }]);
      setStatusMessage({
        type: 'success',
        message: 'Person added to the team successfully!',
      });
      setTimeout(() => setStatusMessage({ type: '', message: '' }), 3000);
      // Remove the person from the search results
      setSearchResults((prevResults) =>
        prevResults.filter((p) => p.id !== person.id)
      );
      // Remove the assigned role from selectedRoles
      setSelectedRoles((prevRoles) => {
        const updatedRoles = { ...prevRoles };
        delete updatedRoles[person.id];
        return updatedRoles;
      });
    } catch (error) {
      setStatusMessage({
        type: 'error',
        message: 'Error adding person to the team.',
      });
      setTimeout(() => setStatusMessage({ type: '', message: '' }), 3000);
      console.error('Error adding person to the team:', error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-70 overflow-y-auto">
      <div className="bg-white dark:bg-navy-800 rounded-lg w-full max-w-4xl animate-slideInUp shadow-lg px-6 py-6">

        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-xl font-semibold text-gray-800 dark:text-white">
            Add Team Member
          </h3>
          <button onClick={onClose} className="focus:outline-none">
            <IoCloseOutline className="text-2xl text-gray-800 dark:text-white" />
          </button>
        </div>

        {/* Search Input */}
        <div className="px-4 py-2">
          <div className="relative">
            <IoSearchOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-xl text-gray-400 dark:text-gray-500" />
            <input
              type="text"
              className="pl-10 pr-4 py-2 w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-navy-700 text-gray-800 dark:text-white focus:border-orange-500 dark:focus:border-orange-300 focus:outline-none"
              placeholder="Search for a person..."
              value={searchTerm}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
          </div>
        </div>

        {/* Search Results */}
        <div className="px-6 py-4 overflow-y-auto max-h-[600px]">
          {isLoading ? (
            <div className="flex justify-center items-center">
              {/* Loader - ensure you have appropriate CSS for the loader */}
              <div className="w-6 h-6 border-4 border-gray-300 border-t-orange-500 rounded-full animate-spin"></div>

            </div>
          ) : searchResults.length > 0 ? (
            searchResults.map((person) => {
              const availableRoles = roles.filter(
                (role) =>
                  !Object.values(selectedRoles).includes(role) ||
                  selectedRoles[person.id] === role
              );

              // Determine tooltip message
              let tooltipMessage = '';
              if (!person.isSubscribed) {
                tooltipMessage = 'This user is not subscribed.';
              } else if (!selectedRoles[person.id]) {
                tooltipMessage = 'Please select a role before adding.';
              }

              return (
                <div
                  key={person.id}
                  className="flex flex-col md:flex-row items-start md:items-center justify-between p-6 mb-4 rounded-lg bg-gray-50 dark:bg-navy-700 hover:bg-gray-100 dark:hover:bg-navy-600 transition-colors"
                >
                  {/* User Info */}
                  <div className="flex items-center mb-2 md:mb-0 flex-1 min-w-0">
                    <img
                      src={getImageUrl(person.imageUrl)}
                      alt=""
                      className="w-12 h-12 rounded-full object-cover shadow-lg mr-4"
                    />
                    <div className="truncate">
                      <p className="text-sm font-medium text-gray-800 dark:text-white">
                        {person.firstName} {person.lastName}
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        {person.username}
                      </p>
                    </div>
                  </div>

                  {/* Subscription Badge */}
                  <div className="flex-shrink-0 min-w-[120px] md:min-w-[150px] flex items-center justify-center mb-2 md:mb-0">
                    <span
                      className={`text-xs font-semibold px-2 py-1 rounded ${
                        person.isSubscribed
                          ? 'bg-green-100 text-green-600'
                          : 'bg-red-100 text-red-600'
                      }`}
                      title={`This user is ${
                        person.isSubscribed ? 'subscribed' : 'not subscribed'
                      }.`}
                    >
                      {person.isSubscribed ? 'Subscribed' : 'Not Subscribed'}
                    </span>
                  </div>

                  {/* Role Select and Add Button */}
                  <div className="flex flex-col sm:flex-row gap-2 items-stretch w-full md:w-auto">
                    {/* Role Selection */}
                    <select
                      className="rounded-xl border border-gray-200 dark:border-white/10 bg-white dark:bg-navy-900 text-navy-700 dark:text-white focus:border-orange-400 dark:focus:border-orange-500 focus:outline-none text-sm w-full sm:w-auto px-4 py-2 transition duration-200 ease-in-out hover:bg-gray-50 dark:hover:bg-navy-800 shadow-md"
                      value={selectedRoles[person.id] || ''}
                      onChange={(e) => handleRoleChange(person.id, e.target.value)}
                      disabled={!person.isSubscribed}
                    >
                      <option value="" disabled className="text-gray-500 dark:text-gray-400">
                        Select Role
                      </option>
                      {availableRoles.map((role) => (
                        <option key={role} value={role}>
                          {role}
                        </option>
                      ))}
                    </select>

                    {/* Add Button with Tooltip */}
                    <div className="relative group">
                      <button
                        className={`flex items-center justify-center px-3 py-2 text-sm font-medium rounded-xl w-full sm:w-auto ${
                          person.isSubscribed && selectedRoles[person.id]
                            ? 'bg-orange-500 text-white hover:bg-orange-600 active:bg-orange-700'
                            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                        }`}
                        onClick={() => person.isSubscribed && handleAddPerson(person)}
                        disabled={!person.isSubscribed || !selectedRoles[person.id]}
                        aria-label={`Add ${person.username}`}
                      >
                        <IoPersonAddOutline className="mr-1" />
                        Add
                      </button>
                      {/* Tooltip */}
                      {(!person.isSubscribed || !selectedRoles[person.id]) && (
                        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-2 py-1 bg-gray-800 text-white text-xs rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none z-10">
                          {tooltipMessage}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : searchTerm.trim() !== '' ? (
            <div className="p-4 text-center text-gray-500 dark:text-gray-400">
              No users found.
            </div>
          ) : (
            <div className="p-4 text-center text-gray-500 dark:text-gray-400">
              Start typing to search for users.
            </div>
          )}
        </div>

        {/* Status Message */}
        {statusMessage.message && (
          <div
            className={`p-4 flex items-center justify-center ${
              statusMessage.type === 'success'
                ? 'bg-green-100 dark:bg-green-900 text-green-600 dark:text-green-400'
                : 'bg-red-100 dark:bg-red-900 text-red-600 dark:text-red-400'
            }`}
          >
            {statusMessage.type === 'success' ? (
              <IoCheckmarkCircle className="mr-2 text-2xl" />
            ) : (
              <IoAlertCircle className="mr-2 text-2xl" />
            )}
            <p className="text-sm">{statusMessage.message}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchModal;
