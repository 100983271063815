import { RiMastercardFill } from "react-icons/ri";
import { FaMobileAlt } from "react-icons/fa"; // Mpesa icon
import MpesaIcon from "components/icons/MpesaIcon";

function MasterCard(props) {
  const { number, CCV, expDate, type = "MasterCard", extraStyle } = props;

  return (
    <div
      className={`w-full rounded-xl py-2 px-[20px] ${extraStyle ? extraStyle : "bg-gradient-to-b from-brand-400 to-brand-600 bg-cover bg-no-repeat"}`}
    >
      <div className="flex items-center justify-between pt-3">
        <h2 className="text-2xl font-bold text-white">
          {type === "Mpesa" ? "Mpesa" : "Glassy."}
        </h2>
        <div className="text-[50px] text-white">
          {type === "Mpesa" ? <MpesaIcon size={90}/> : <RiMastercardFill />}
        </div>
      </div>
      <h3 className="mt-[40px] text-xl font-bold text-white">{number}</h3>
      {/* Card info */}
      <div className="mt-3 flex gap-[28px]">
        <div>
          <h5 className="text-xs font-extralight text-white">VALID THRU</h5>
          <p className="text-sm font-medium text-white">
            {type === "Mpesa" ? "N/A" : expDate}
          </p>
        </div>
        <div>
          <h5 className="text-xs font-extralight text-white">
            {type === "Mpesa" ? "ACCOUNT" : "CVV"}
          </h5>
          <p className="text-sm font-medium text-white">{CCV}</p>
        </div>
      </div>
    </div>
  );
}

export default MasterCard;
