import Card from 'components/card';
import { FaRegClock, FaProjectDiagram, FaExclamationCircle } from 'react-icons/fa';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { updateApprovalStatus } from 'api';
import moment from 'moment'; 

const ChartSection = ({ approvals }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  

  const handleApprovalClick = (approval) => {
    navigate('/admin/manage-team', { 
      state: { 
        selectedTeam: approval.teamId || approval.team?.id, 
        selectedPoap: approval.poapDescriptionId || approval.teamStep?.poapStep?.poapDescriptionId,
        selectedStepId: approval.teamStep?.poapStep?.id,
        categoryIndex: approval.categoryIndex || approval.teamStep?.poapStep?.category?.index,
        stepIndex: approval.stepIndex || approval.teamStep?.poapStep?.index,
        approvalRequestId: approval.approvalRequestId, // Pass the approvalRequestId
      } 
    });
  };
  

  return (
    <Card extra="p-6 bg-white dark:bg-navy-800 shadow-lg rounded-[20px]">
      <h3 className="text-xl font-bold text-navy-700 dark:text-white mb-6 flex items-center">
        <FaExclamationCircle className="text-orange-500 mr-3" />
        Pending Approvals
      </h3>
      <div className="space-y-6">
        {approvals && approvals.length > 0 ? (
          approvals.map((approval) => (
            <div
              key={approval.approvalRequestId} // Assuming approvalRequestId is unique
              className="flex items-start p-5 dark:bg-navy-700 bg-gray-50 rounded-lg shadow-sm border-l-4 border-transparent hover:border-brand-900 hover:bg-gray-100 dark:hover:bg-navy-500 transition-all duration-300 cursor-pointer"
              onClick={() => handleApprovalClick(approval)}
              title="View Details"
            >
              <div className="mr-4 flex-shrink-0">
                <div className="p-3 bg-blue-100 dark:bg-blue-800 rounded-full">
                  <FaProjectDiagram className="text-blue-500 dark:text-blue-400 text-lg" />
                </div>
              </div>
              <div className="flex-1">
                <div className="flex justify-between items-center mb-2">
                  <h4 className="text-lg font-semibold text-navy-700 dark:text-white">
                    {approval.stepName || 'Unnamed Step'}
                  </h4>
                  <div className="flex items-center text-gray-500 dark:text-gray-400 text-xs">
                    <FaRegClock className="mr-1" />
                    {moment(approval.createdAt).fromNow()} on {moment(approval.createdAt).format("MMM D, YYYY")}
                  </div>
                </div>
                <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">
                  {approval.shortDescription || 'No description available'}
                </p>
                <div className="text-sm font-semibold text-navy-700 dark:text-white mb-1">
                  Team: <span className="font-bold text-blue-600 dark:text-blue-400">{approval.teamName || 'Unknown Team'}</span>
                </div>
                <p className="text-xs text-gray-500 dark:text-gray-400 italic">
                  Click to review and approve this milestone.
                </p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-sm text-gray-600 dark:text-gray-300">All caught up! No pending approvals at the moment.</p>
        )}
      </div>
    </Card>
  );
};

export default ChartSection;
