import React, { useState, useEffect } from "react";
import Card from "components/card";
import { FaCalendarPlus } from "react-icons/fa";
import {
  createTimeline,
  fetchUserTimelines,
  getUserProfile
} from "api";

const Event = () => {
  const [events, setEvents] = useState([]);
  const [eventTitle, setEventTitle] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [mentorId, setMentorId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState("");

  // 1. Fetch user profile once on mount
  useEffect(() => {
    const loadUserProfile = async () => {
      try {
        const userProfile = await getUserProfile();
        console.log("User profile fetched:", userProfile);
  
        if (userProfile?.user) {
          setUserId(userProfile.user.id);
          setUserRole(userProfile.user.role);
  
          // Check if the user is a mentor
          if (userProfile.user.role === "mentor") {
            setMentorId(userProfile.user.id);
            loadEvents(userProfile.user.id);
          } else if (userProfile.user.mentor?.id) {
            // Fixed: Accessing mentor ID correctly from nested object
            setMentorId(userProfile.user.mentor.id);
            loadEvents(userProfile.user.mentor.id);
          } else {
            setErrorMessage("No mentor assigned. Please contact support.");
          }
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setErrorMessage("Failed to load user profile. Please try again.");
      }
    };
  
    loadUserProfile();
  }, []);

  // 2. Load events (timelines) from the user's mentor
  const loadEvents = async (userId) => {
    try {
      const timelines = await fetchUserTimelines(userId);
      setEvents(timelines);
    } catch (error) {
      console.error("Error fetching events:", error);
      setErrorMessage("Failed to fetch events.");
    }
  };

  // 3. Handle adding a new event (only if user is mentor)
  const handleAddEvent = async () => {
    if (eventTitle && eventDate && mentorId) {
      setLoading(true);
      try {
        const newEvent = {
          title: eventTitle,
          startDate: eventDate,
          endDate: eventDate,
          description: eventDescription,
          mentorId,
        };

        const response = await createTimeline(newEvent);
        setEvents((prev) => [...prev, response]);

        // Reset form
        setEventTitle("");
        setEventDate("");
        setEventDescription("");
        setErrorMessage("");
      } catch (error) {
        console.error("Error adding event:", error);
        setErrorMessage("Failed to add event. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage("Please provide both a title and a date for the event.");
    }
  };

  return (
    <Card extra="w-full h-full p-5">
      {/* Header: changes based on role */}
      <h4 className="text-xl font-bold text-navy-700 dark:text-white">
      {userRole === "mentor" ? "Add New Event" : ""}
      </h4>

      {/* If there's an error, show it */}
      {errorMessage && <p className="mt-2 text-sm text-red-500">{errorMessage}</p>}

      {/* If user is mentor, show form for adding new events */}
      {userRole === "mentor" && (
        <div className="mt-5 flex flex-col gap-4">
          <input
            type="text"
            placeholder="Event Title"
            value={eventTitle}
            onChange={(e) => setEventTitle(e.target.value)}
            className="w-full rounded-lg border border-gray-300 bg-gray-100 p-3 text-sm font-medium text-navy-700 placeholder-gray-500 outline-none focus:border-orange-500 focus:ring focus:ring-orange-200 dark:bg-navy-700 dark:text-white dark:placeholder-gray-400"
          />

          <input
            type="date"
            value={eventDate}
            onChange={(e) => setEventDate(e.target.value)}
            className="w-full rounded-lg border border-gray-300 bg-gray-100 p-3 text-sm font-medium text-navy-700 placeholder-gray-500 outline-none focus:border-orange-500 focus:ring focus:ring-orange-200 dark:bg-navy-700 dark:text-white dark:placeholder-gray-400"
          />

          <textarea
            placeholder="Event Description (optional)"
            value={eventDescription}
            onChange={(e) => setEventDescription(e.target.value)}
            className="w-full rounded-lg border border-gray-300 bg-gray-100 p-3 text-sm font-medium text-navy-700 placeholder-gray-500 outline-none focus:border-orange-500 focus:ring focus:ring-orange-200 dark:bg-navy-700 dark:text-white dark:placeholder-gray-400"
            rows="3"
          />

          <button
            onClick={handleAddEvent}
            className="flex items-center justify-center rounded-lg bg-orange-500 p-3 text-sm font-bold text-white transition duration-200 hover:bg-orange-600 active:scale-95"
            disabled={loading}
          >
            {loading ? "Adding..." : <FaCalendarPlus className="mr-2" />}
            {loading ? "" : "Add Event"}
          </button>
        </div>
      )}

      {/* Upcoming Events visible to everyone (mentor or student) */}
      <div className="mt-8">
  <h4 className="text-2xl font-bold text-navy-700 dark:text-white border-b-4 border-orange-500 pb-2">
    📅 Upcoming Events
  </h4>
  <ul className="mt-6 space-y-6">
    {events.length > 0 ? (
      events.map((event, index) => (
        <li
          key={index}
          className="flex flex-col md:flex-row items-center md:items-start rounded-lg border border-gray-200 bg-gradient-to-br from-orange-100 to-orange-300 dark:from-navy-800 dark:to-navy-700 p-6 shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-[1.02] transform"
        >
          <div className="flex items-center space-x-4">
            <div className="w-16 h-16 flex-shrink-0 flex items-center justify-center bg-orange-500 text-white rounded-full text-2xl font-bold shadow-md">
              {new Date(event.startDate).toLocaleDateString('en-US', { day: '2-digit' })}
            </div>
            <div>
              <h5 className="text-xl font-bold text-navy-700 dark:text-white leading-tight">
                {event.title}
              </h5>
              <p className="mt-2 text-md text-gray-700 dark:text-gray-300 flex items-center">
                <FaCalendarPlus className="mr-2 text-orange-600" />
                {new Date(event.startDate).toLocaleDateString('en-US', {
                  weekday: 'long',
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </p>
              {event.description && (
                <p className="mt-2 text-sm text-gray-700 dark:text-gray-400 italic">
                  {event.description}
                </p>
              )}
            </div>
          </div>
        </li>
      ))
    ) : (
      <div className="text-center py-6">
        <p className="text-md text-gray-600 dark:text-gray-300">
          🎉 No upcoming events.
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          {userRole === "mentor"
            ? "Start adding events to stay organized!"
            : "Check back later for exciting events."}
        </p>
      </div>
    )}
  </ul>
</div>
    </Card>
  );
};

export default Event;
