import Card from 'components/card';
import Banner from './components/Banner';
import Content from './components/Content';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { tableColumnsInvoice } from './variables/tableColumnsInvoice';
import { fetchInvoiceById } from 'api';

const Invoice = () => {
  const location = useLocation();
  const { invoiceId } = useParams();  // Get invoiceId from route params
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    // Check if state exists (from navigation), otherwise fallback to API fetch
    if (location.state && location.state.invoice) {
      setInvoice(location.state.invoice);
    } else if (invoiceId) {
      // Fallback: Fetch the invoice from the API if not available in state
      const fetchInvoice = async () => {
        try {
          const fetchedInvoice = await fetchInvoiceById(invoiceId);
          setInvoice(fetchedInvoice);
        } catch (error) {
          console.error("Error fetching invoice from API:", error);
        }
      };
      fetchInvoice();
    }
  }, [location.state, invoiceId]);

  // Handle the loading state
  if (!invoice) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mt-3 w-full rounded-[20px] font-dm md:h-[1150px] 3xl:px-52">
      <Card extra={'w-full xl:w-[95%] 3xl:w-[82%] mx-auto p-4 h-full'}>
        {/* banner */}
        <div>
          <Banner 
            invoiceNumber={invoice.invoiceNumber || "No Invoice Number"}
            address={invoice.billingAddress || "No Address Provided"}
            status={invoice.status || "Unknown Status"}
          />

          <div className="mx-auto -mt-[80px] overflow-visible px-2 md:-mt-10 md:px-14 lg:w-[90%] 3xl:w-[90%]">
            <Content 
              customerName={`${invoice.user?.firstName || 'Unknown'} ${invoice.user?.lastName || 'User'}`}
              amountDue={`Kshs ${invoice.totalAmount || 0}`}
              dueDate={invoice.dueDate ? new Date(invoice.dueDate).toLocaleDateString() : "No Due Date"}
              note={invoice.note || "No additional notes"}
              tableData={invoice.lineItems || []}
              columnsData={tableColumnsInvoice}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Invoice;
