import React from 'react';
import InfoSection from './components/InfoSection';
import CardsWrapper from './components/CardsWrapper';


const CourseContent = ({ section }) => {
  return (
    <div>
      <InfoSection />
      <CardsWrapper section={section} />
    </div>
  );
};

export default CourseContent;
