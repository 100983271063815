import React from "react";

function Transaction(props) {
  const { title, date, sum, icon, mb, status } = props;

  // Define badge color based on status
  const badgeColor = 
    status === "PAID"
      ? "bg-green-100 text-green-700 dark:bg-green-700 dark:text-green-200"
      : status === "Pending"
      ? "bg-yellow-100 text-yellow-700 dark:bg-yellow-700 dark:text-yellow-200"
      : status
      ? "bg-red-100 text-red-700 dark:bg-red-700 dark:text-red-200"
      : "bg-transparent"; // Transparent when there's no status 
       

  return (
    <div className={`flex justify-between ${mb} mt-2 items-center px-3 py-2 rounded-lg bg-white shadow-sm dark:bg-navy-800`}>
      {/* Left Section: Icon and Title */}
      <div className="flex items-center gap-3">
        {/* Icon section */}
        <div className="flex items-center justify-center rounded-full bg-lightPrimary p-2.5 text-xl text-brand-500 dark:bg-navy-700 dark:text-white">
          {icon}
        </div>

        {/* Title and Date */}
        <div>
          <h5 className="text-base font-bold text-navy-700 dark:text-white">
            {title}
          </h5>
          <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
            {date}
          </p>
        </div>
      </div>

      {/* Middle Section: Status Badge */}
      <div className="flex items-center">
        <span
          className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-semibold ${badgeColor}`}
        >
          {status}
        </span>
      </div>

      {/* Right Section: Amount */}
      <div className="text-right">
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {sum}
        </p>
      </div>
    </div>
  );
}

export default Transaction;
