export const tableColumnsManagement = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'School',
    accessor: 'school',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Team',
    accessor: 'team',
  },
  {
    Header: 'Role',
    accessor: 'teamRole',
  },
  {
    Header: '',
    accessor: 'button',
  },
];
