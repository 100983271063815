export default function StepperControl({ handleClick, currentStep, steps, hasError }) {
  return (
    <div className="mt-2 flex flex-col md:flex-row justify-end md:justify-between px-3 pt-2 md:px-8">
      {/* Conditional rendering for Prev button */}
      {currentStep !== 1 && (
        <button
          onClick={() => handleClick()}
          className={`mb-3 cursor-pointer rounded-xl bg-lightPrimary px-16 py-2 font-medium text-navy-700 transition duration-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20 md:mb-0 md:mr-auto`} // Use `md:mr-auto` to push the next button to the right
        >
          Prev
        </button>
      )}

      <button
        onClick={() => handleClick("next")}
        className={`cursor-pointer rounded-xl bg-orange-500 px-16 py-2 font-medium text-white transition duration-200 hover:bg-orange-700 active:bg-orange-900 dark:bg-orange-00 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-auto ${
          hasError ? "opacity-50 cursor-not-allowed" : ""
        }`}
        disabled={hasError} // Disable button if there's an error
      >
        {currentStep === steps.length ? "Submit" : "Next"}
      </button>
    </div>
  );
}

