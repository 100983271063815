import React from 'react';
import MpesaLogo from '../../assets/img/ecommerce/Mpesa.png'; // Make sure to update the path to your PNG or SVG file

function MpesaIcon({ size = 60 }) {
  return (
    <img 
      src={MpesaLogo} 
      alt="M-Pesa Logo" 
      width={size} 
      height={size}
    />
  );
}

export default MpesaIcon;

