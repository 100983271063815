import { useEffect, useState } from "react";
import Information from "./components/Information";
import Connect from "./components/Connect";
import Delete from "./components/Delete";
import NewsLetter from "./components/NewsLetter";
import Password from "./components/Password";
import Profile from "./components/Profile";
import Session from "./components/Session";
import Social from "./components/Social";
import TwoFactor from "./components/TwoFactor";
import SaveChanges from "./components/SaveChanges";
import { getUserProfile, updateUser, uploadProfilePic, updatePassword, toggleTwoFactorAuth, deleteUser  } from "api";
import Modal from "components/modal/Modal";
import SuccessAlert from "components/alerts/SuccessAlerts";
import ErrorAlerts from "components/alerts/ErrorAlerts";
import md5 from "md5";

const getGravatarUrl = (email) => {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
};

const Setting = () => {
  const [profile, setProfile] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    imageUrl: '',
    username: '',
    school: '',
    aboutMe:'',
    twoFactorEnabled: false,
  });

  const [loading, setLoading] = useState(true);
  const [ successMessage, setSuccessMessage ] = useState(null);
  const [ errorMessages, setErrorMessages ] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [confirmAction, setConfirmAction] = useState(() => () => {});

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getUserProfile();
        const user = data.user;
        // Use Gravatar URL if no profile image is provided
        const imageUrl = user.imageUrl
          ? `${process.env.REACT_APP_API_BASE_URL}${user.imageUrl}`
          : getGravatarUrl(user.email);
        setProfile({ ...user, imageUrl });
      } catch (error) {
        console.error("Error fetching profile:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(null), 5000); // Auto-hide success message after 5 seconds
      return () => clearTimeout(timer); // Clear timeout if the component unmounts or the success message changes
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessages.length > 0) {
      const timer = setTimeout(() => setErrorMessages([]), 5000); // Auto-hide error messages after 5 seconds
      return () => clearTimeout(timer); // Clear timeout if the component unmounts or the error messages change
    }
  }, [errorMessages]);

  const openModal = (content, action) => {
    setModalContent(content);
    setConfirmAction(() => action);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
    setConfirmAction(() => () => {});
  };


  // Handle profile update
  const handleProfileUpdate = async (updatedProfile) => {
    openModal("Do you want to save the changes to your profile?", async () => {
      try {
        const updatedUser = await updateUser(profile.id, updatedProfile);
        setProfile({ ...updatedUser.user });
        setSuccessMessage("Profile updated successfully!");
      } catch (error) {
        setErrorMessages([error.message]);
      }
      closeModal();
    });
  };

  // Handle profile picture change
  const handleImageChange = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("profilePic", file);

      openModal("Would you like to update your profile picture?", async () => {
        try {
          const response = await uploadProfilePic(formData);
          setProfile((prev) => ({ ...prev, imageUrl: response.imageUrl }));
          setSuccessMessage("Profile picture updated successfully!");
        } catch (error) {
          setErrorMessages(["Failed to upload profile picture."]);
        }
        closeModal();
      });
    }
  };

   // Handle password change
  const handlePasswordChange = async (oldPassword, newPassword) => {
    openModal("Are you sure you want to change your password?", async () => {
      try {
        await updatePassword(profile.id, { oldPassword, newPassword });
        setSuccessMessage("Password updated successfully!");
      } catch (error) {
        setErrorMessages([error.message]);
      }
      closeModal();
    });
  };

  // Handle Two-Factor Authentication toggle
  const handleTwoFactorToggle = async (isEnabled) => {
    try {
      await toggleTwoFactorAuth(profile.id, { isEnabled });
      setProfile((prevProfile) => ({ ...prevProfile, twoFactorEnabled: isEnabled }));
      alert("Two-Factor Authentication updated successfully!");
    } catch (error) {
      console.error("Error toggling Two-Factor Authentication:", error);
      alert("Failed to update Two-Factor Authentication");
    }
  };

  // Handle account deletion
  const handleDeleteAccount = () => {
    // Open modal with a confirmation message
    openModal(
      "Deleting your account is a permanent action and cannot be undone. Are you sure you want to proceed?",
      async () => {
        try {
          await deleteUser(profile.id);
          setSuccessMessage("Your account has been deleted successfully.");
          closeModal();
          // Add redirect or UI update logic after successful deletion
        } catch (error) {
          console.error("Error deleting account:", error);
          setErrorMessages(["Failed to delete account. Please try again later."]);
          closeModal();
        }
      }
    );
  };

  // Handle saving changes
  const handleSaveChanges = () => {
    handleProfileUpdate(profile);
    // Optionally handle other save actions or validations here
  };

  const closeSuccessAlert = () => setSuccessMessage(null);
  const closeErrorAlert = () => setErrorMessages([]);

  if (loading) return <div>Loading...</div>;


  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
      {successMessage && <SuccessAlert message={successMessage} onClose={closeSuccessAlert} />}
      {errorMessages.length > 0 && <ErrorAlerts errors={errorMessages} onClose={closeErrorAlert} />}

      {isModalOpen && (
        <Modal onClose={closeModal}>
          <div className="p-4 bg-white dark:bg-gray-800 rounded-lg">
            <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
              Confirmation
            </h3>
            <p className="text-gray-700 dark:text-gray-300">{modalContent}</p>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-gray-300 text-gray-900 rounded hover:bg-gray-400 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={confirmAction}
                className="px-4 py-2 bg-brand-900 text-white rounded hover:bg-orange-700 dark:bg-brand-400 dark:hover:bg-brand-300"
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}

      <div className="flex flex-col gap-5">
        <Profile profile={profile} onImageChange={handleImageChange} />
        <Information profile={profile} onUpdate={handleProfileUpdate} />
        {/* <Social /> */}
        <Password onChange={handlePasswordChange}/>
      </div>

      <div className="flex flex-col gap-5">
        <TwoFactor isEnabled={profile.twoFactorEnabled} onToggle={handleTwoFactorToggle} />
        {/* <NewsLetter /> */}
        {/* <Session /> */}
        
        <Connect /> 
        {/* <SaveChanges onSave={handleSaveChanges} /> */}
        <Delete onDelete={handleDeleteAccount} />
      </div> 
    </div>
  );
};

export default Setting;
