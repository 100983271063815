import React from 'react';
import { FaTrophy } from 'react-icons/fa'; // Importing trophy icon for visual emphasis

const ChampionshipSection = () => {
    return (
        <section className="relative min-h-screen flex items-center justify-center bg-gradient-to-b from-white to-gray-100">
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 w-full text-center">
                <h2 className="text-4xl font-extrabold text-gray-800 mb-8">Championship Event</h2>
                <p className="text-lg text-gray-600 mb-12">Experience the excitement of our annual championship events.</p>
                <div className="space-y-6">
                    <div className="flex flex-col items-center justify-center">
                        <FaTrophy className="text-6xl text-orange-500 mb-4" /> {/* Trophy icon for emphasis */}
                        <p className="text-xl font-medium">School-Level Showdowns and Regional Competitions lead up to the grand finale.</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <p className="text-xl font-medium">The season's best teams compete at the Championship Event for the prestigious Chairman's Award.</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <p className="text-xl font-medium">The Chairman's Award recognizes teams that exemplify innovation, collaboration, and commitment.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ChampionshipSection;
