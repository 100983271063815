import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import SingleChat from "./components/SingleChat";
import CardMenu from "components/card/CardMenu";
import { BsPencilSquare } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import Chat from "./components/Chat";
import Card from "components/card";
import {
  fetchMessages,
  fetchTeamMembers,
  getUserProfile,
  markMessagesAsRead,
  fetchUsersAssignedToMentor,
  fetchTeamsByMentor,
  fetchTeamMessages,
} from "api";
import Loader from "components/loaders/Loader";
import { io } from "socket.io-client";
import { useAuth } from "contexts/authContext";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const Messages = () => {
  const [open, setOpen] = useState(false);
  const [selectedChatEntity, setSelectedChatEntity] = useState(null);
  const [chatType, setChatType] = useState("individual");
  const [searchTerm, setSearchTerm] = useState("");
  const [creatingNewChat, setCreatingNewChat] = useState(false);
  const searchInputRef = useRef(null);
  const [mentor, setMentor] = useState(null);
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
  const [chatsWithUnread, setChatsWithUnread] = useState({}); // New state for unread messages

  const location = useLocation();
  const { user } = useAuth();

  const queryClient = useQueryClient();

  // Fetch user profile
  const {
    data: userProfileData,
    isLoading: userProfileLoading,
    error: userProfileError,
  } = useQuery({
    queryKey: ["userProfile"],
    queryFn: getUserProfile,
  });

  const userProfile = userProfileData?.user;

  // Set mentor once userProfile is fetched
  useEffect(() => {
    if (userProfile && userProfile.mentor) {
      setMentor(userProfile.mentor);
    }
  }, [userProfile]);

  // Fetch users including mentor
  const fetchUsers = async () => {
    let users = [];
    if (userProfile) {
      if (userProfile.role === "mentor") {
        users = await fetchUsersAssignedToMentor(userProfile.id);
      } else if (userProfile.team?.id) {
        users = await fetchTeamMembers(userProfile.team.id);
      }

      // Include mentor if not already included
      if (mentor && !users.some((user) => user.id === mentor.id)) {
        users.push(mentor);
      }
    }
    return users;
  };

  // Fetch teams for mentor or user
  const fetchTeams = async () => {
    if (userProfile) {
      if (userProfile.role === "mentor") {
        // Mentor's teams
        const teams = await fetchTeamsByMentor(userProfile.id);
        return teams;
      } else if (userProfile.team) {
        // User's team
        return [userProfile.team];
      }
    }
    return [];
  };

  // Fetch users data
  const {
    data: usersData,
    isLoading: usersLoading,
    error: usersError,
  } = useQuery({
    queryKey: ["users", userProfile?.id],
    queryFn: fetchUsers,
    enabled: !!userProfile,
    onSuccess: () => setIsInitialLoadComplete(true),
  });

  // Fetch teams data
  const {
    data: teamsData,
    isLoading: teamsLoading,
    error: teamsError,
  } = useQuery({
    queryKey: ["teams", userProfile?.id],
    queryFn: fetchTeams,
    enabled: !!userProfile,
  });

  // Fetch individual messages
  const {
    data: messagesData,
    isLoading: messagesLoading,
    error: messagesError,
  } = useQuery({
    queryKey: ["messages", userProfile?.id],
    queryFn: () => fetchMessages(userProfile.id),
    enabled: !!userProfile,
    refetchInterval: 5000, // Time to fetch
  });

  // Fetch team messages
  const {
    data: teamMessagesData,
    isLoading: teamMessagesLoading,
    error: teamMessagesError,
  } = useQuery({
    queryKey: ["teamMessages", "userTeam", userProfile?.team?.id],
    queryFn: async () => {
      if (userProfile && userProfile.role !== "mentor" && userProfile.team) {
        return await fetchTeamMessages(userProfile.team.id);
      }
      return [];
    },
    enabled: !!userProfile && userProfile.role !== "mentor",
    refetchInterval: 5000,
  });

  // Prepare messages and users for display
  const allMessages = messagesData || [];
  const allUsers = usersData || [];
  const allTeams = teamsData || [];

  // Handle WebSocket connections and events
  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_BASE_URL, {
      transports: ["websocket"],
      reconnectionDelay: 500, // Reconnect every 500 ms
    });

    const handleUserStatusUpdate = ({ userId, status }) => {
      if (isInitialLoadComplete && userProfile) {
        queryClient.setQueryData(["users", userProfile.id], (oldData) => {
          return oldData.map((user) =>
            user.id === userId ? { ...user, status } : user
          );
        });

        if (mentor && userId === mentor.id) {
          setMentor((prevMentor) => ({ ...prevMentor, status }));
        }
      }
    };

    const handleNewMessage = (message) => {
      if (
        userProfile &&
        message.senderId !== userProfile.id &&  // Ignore self-sent messages
        (message.receiverId === userProfile.id ||
          (message.teamId && message.teamId === userProfile.team?.id))
      ) {
        setChatsWithUnread((prev) => {
          const chatId = message.teamId || message.senderId;
          return {
            ...prev,
            [chatId]: (prev[chatId] || 0) + 1,
          };
        });
      }
    };    

    socket.on("userStatusUpdate", handleUserStatusUpdate);
    socket.on("newMessage", handleNewMessage);

    return () => {
      socket.off("userStatusUpdate", handleUserStatusUpdate);
      socket.off("newMessage", handleNewMessage);
      socket.disconnect();
    };
  }, [isInitialLoadComplete, userProfile, mentor, queryClient]);

  // Handle URL query params to open specific chat
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const chatWithId = queryParams.get("chatWith");
    const teamChatWithId = queryParams.get("teamChatWith");
  
    if (chatWithId && usersData?.length > 0) {
      const chatUser = usersData.find((user) => user.id === parseInt(chatWithId));
      if (chatUser) {
        setSelectedChatEntity(chatUser);
        setChatType("individual");
        setOpen(true);
        queryClient.invalidateQueries(['messages', userProfile?.id]);
      }
    } else if (teamChatWithId && teamsData?.length > 0) {
      const chatTeam = teamsData.find((team) => team.id === parseInt(teamChatWithId));
      if (chatTeam) {
        setSelectedChatEntity(chatTeam);
        setChatType("team");
        setOpen(true);
        queryClient.invalidateQueries(['teamMessages', chatTeam.id]);
      }
    }
  }, [location.search, usersData, teamsData, queryClient, userProfile?.id]);
  

  // Calculate unread messages per chat
  useEffect(() => {
    if (messagesData && usersData) {
      const unreadCounts = {};

      // For individual chats
      usersData.forEach((user) => {
        const unreadMessages = messagesData.filter(
          (msg) =>
            msg.sender.id === user.id &&
            msg.receiver.id === userProfile.id &&
            !msg.isRead
        );
        if (unreadMessages.length > 0) {
          unreadCounts[user.id] = unreadMessages.length;
        }
      });

      // For team chats
      if (teamMessagesData && userProfile.team) {
        const teamUnreadMessages = teamMessagesData.filter(
          (msg) => msg.sender.id !== userProfile.id && !msg.isRead
        );
        if (teamUnreadMessages.length > 0) {
          unreadCounts[userProfile.team.id] = teamUnreadMessages.length;
        }
      }

      setChatsWithUnread(unreadCounts);
    }
  }, [messagesData, teamMessagesData, usersData, userProfile]);

  // Filter and sort users for display
  let filteredUsers = allUsers
    .filter(
      (user) =>
        user.id !== userProfile.id &&
        (userProfile.role === "mentor" ||
          (userProfile.role !== "mentor" &&
            (user.teamId === userProfile.teamId || user.id === mentor?.id)))
    )
    .sort((a, b) => {
      // Keep mentor at the top
      if (a.id === mentor?.id) return -1;
      if (b.id === mentor?.id) return 1;

      const aHasUnread = chatsWithUnread[a.id] ? 1 : 0;
      const bHasUnread = chatsWithUnread[b.id] ? 1 : 0;
      if (bHasUnread - aHasUnread !== 0) {
        return bHasUnread - aHasUnread;
      }
      return 0; // Keep original order otherwise
    });

  // Include mentor if not already included
  if (
    mentor &&
    userProfile.role !== "mentor" &&
    !filteredUsers.some((user) => user.id === mentor.id)
  ) {
    filteredUsers.unshift(mentor);
  }

  // Sort team chats
  let filteredTeamChats = allTeams
    .filter((team) =>
      team.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      const aHasUnread = chatsWithUnread[a.id] ? 1 : 0;
      const bHasUnread = chatsWithUnread[b.id] ? 1 : 0;
      return bHasUnread - aHasUnread;
    });

  const handleCreateNewChat = () => {
    setSelectedChatEntity(null);
    setCreatingNewChat(true);
    setOpen(true);
    searchInputRef.current.focus();
  };
  const handleChatOpen = async (chatEntity, chatType) => {
    setOpen(true);
    setSelectedChatEntity(chatEntity);
    setChatType(chatType);
    setCreatingNewChat(false);
  
    // Remove local unread state
    const chatId = chatEntity.id;
    setChatsWithUnread((prev) => {
      const newUnread = { ...prev };
      delete newUnread[chatId];
      return newUnread;
    });
  
    // Collect unread messages for the selected chat
    const unreadMessages = allMessages.filter((msg) =>
      chatType === "team"
        ? msg.teamId === chatEntity.id && !msg.isRead
        : msg.sender.id === chatEntity.id && msg.receiver.id === userProfile.id && !msg.isRead
    );
  
    if (unreadMessages.length > 0) {
      try {
        // Ensure message IDs are extracted and formatted as an array
        const messageIds = unreadMessages.map((m) => m.id);
  
        // Debugging the payload before sending to API
        console.log("Marking messages as read, messageIds:", messageIds);
  
        // Mark messages as read API call with correct payload
        await markMessagesAsRead(messageIds);
  
        // Invalidate the query to reflect changes
        queryClient.invalidateQueries(["messages", userProfile?.id]);
      } catch (error) {
        console.error("Failed to mark messages as read:", error);
      }
    }
  };

  // Filter chats based on search term
  const filteredChats = filteredUsers.filter((user) =>
    `${user.firstName} ${user.lastName}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Use the Loader component during loading states
  if (
    userProfileLoading ||
    usersLoading ||
    messagesLoading ||
    teamsLoading ||
    teamMessagesLoading
  ) {
    return <Loader />;
  }

  if (
    userProfileError ||
    usersError ||
    messagesError ||
    teamsError ||
    teamMessagesError
  ) {
    return <div>Error loading data</div>;
  }

  return (
    <div className="mt-[80px] flex w-full grid-cols-10 flex-col gap-5 md:mt-[20px] lg:mt-6 lg:min-h-[85vh] lg:flex-row">
      <div className="w-full lg:w-[35%]">
        <Card extra={"w-full h-full p-4"}>
          {/* Header */}
          <div className="mb-3 flex items-center justify-between">
            <h1 className="font-dm text-[24px] font-bold text-navy-700 dark:text-white">
              Your Chats
            </h1>
            <CardMenu vertical />
          </div>
          {/* Search */}
          <div className="flex w-full items-center gap-2">
            <div className="flex h-[42px] w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-gray-100" />
              </p>
              <input
                type="text"
                placeholder="Search..."
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-gray-100 sm:w-fit"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                ref={searchInputRef}
              />
            </div>
            <button
              className="flex items-center justify-center rounded-full bg-brand-500 p-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-700 active:bg-blue-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
              onClick={handleCreateNewChat}
            >
              <BsPencilSquare />
            </button>
          </div>
          {/* Messages */}
          <div className="mt-4">
            {/* Team Chats */}
            {allTeams.length > 0 && (
              <>
                <h2 className="text-lg font-bold text-navy-700 dark:text-white mb-2">
                  Team Chats
                </h2>
                {filteredTeamChats.length === 0 ? (
                  <div className="text-center text-gray-500 dark:text-gray-300">
                    No teams found.
                  </div>
                ) : (
                  filteredTeamChats.map((team) => (
                    <div
                      key={team.id}
                      onClick={() => handleChatOpen(team, "team")}
                    >
                      <Chat
                        photo={null}
                        active={true}
                        name={team.name}
                        time=""
                        message="Group Chat"
                        isMentor={false}
                        isTeam={true}
                        hasUnreadMessages={Boolean(chatsWithUnread[team.id])}
                      />
                    </div>
                  ))
                )}
              </>
            )}
            {/* Individual Chats */}
            <h2 className="text-lg font-bold text-navy-700 dark:text-white mt-4 mb-2">
              Individual Chats
            </h2>
            {filteredChats.length === 0 ? (
              <div className="text-center text-gray-500 dark:text-gray-300">
                No users found.
              </div>
            ) : (
              filteredChats.map((user) => (
                <div
                  key={user.id}
                  onClick={() => handleChatOpen(user, "individual")}
                >
                  <Chat
                    photo={
                      user.imageUrl
                        ? `${process.env.REACT_APP_API_BASE_URL}${user.imageUrl}`
                        : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                    }
                    active={user.status === "Online"}
                    name={`${user.firstName || ""} ${
                      user.lastName || "Unknown User"
                    }`.trim()}
                    time=""
                    message="Private Chat"
                    isMentor={user.role === "mentor"}
                    isTeam={false}
                    hasUnreadMessages={user.id !== userProfile.id && Boolean(chatsWithUnread[user.id])}
                  />
                </div>
              ))
            )}
          </div>
        </Card>
      </div>
      <div className="w-full lg:w-[64.5%]">
        {selectedChatEntity && (
          <SingleChat
            open={open}
            onClose={() => setOpen(false)}
            chatEntity={selectedChatEntity}
            chatType={chatType}
            creatingNewChat={creatingNewChat}
            userId={userProfile.id}
            userProfile={userProfile}
            updateChatsWithUnread={(id) =>
              setChatsWithUnread((prev) => {
                const newUnread = { ...prev };
                delete newUnread[id];
                return newUnread;
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default Messages;
