import React, { useState, useEffect } from "react";
import Card from "components/card";
import { BsAward } from "react-icons/bs";
import { getUserProfile, fetchCurrentDescription, fetchTeamPointsInfo } from 'api';

const Storage = () => {
  const [progress, setProgress] = useState({
    currentPoints: 0,
    totalPoints: 0,
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPoints = async () => {
      try {
        const profile = await getUserProfile();

        if (profile.user && profile.user.team) {
          const teamId = profile.user.team.id;

          const { descriptionId, projectName } = await fetchCurrentDescription(teamId);

          const pointsInfo = await fetchTeamPointsInfo(teamId, descriptionId);

          const totalPoints = pointsInfo.currentPoints + pointsInfo.remainingPoints;
          setProgress({
            currentPoints: pointsInfo.currentPoints,
            totalPoints: totalPoints,
          });
        } else {
          setError('No team assigned. Join a team to track your progress.');
        }
      } catch (error) {
        console.error("Error fetching data", error);
        setError('Failed to load data');
      }
    };

    fetchPoints();
  }, []);

  const progressPercentage = (progress.currentPoints / progress.totalPoints) * 100;

  return (
    <Card extra={"w-full h-full p-4"}>
      <div className="flex flex-col items-center justify-center text-center">
        <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-4xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
          <BsAward />
        </div>
        <p className="mt-3 text-xl font-bold text-navy-700 dark:text-white md:text-2xl">
          Next POAP Progress
        </p>
        <p className="mt-1 px-4 text-base font-normal text-gray-600 dark:text-gray-300 md:px-0">
          {error ? error : 'Track your team\'s progress towards the next POAP achievement'}
        </p>
      </div>

      {!error && (
        <div className="mt-4 flex flex-col">
          <div className="flex justify-between px-4 md:px-0">
            <p className="text-sm font-medium text-gray-600 dark:text-gray-300">{progress.currentPoints} Points</p>
            <p className="text-sm font-medium text-gray-600 dark:text-gray-300">{progress.totalPoints} Points</p>
          </div>
          <div className="mt-2 h-3 w-full rounded-full bg-lightPrimary dark:!bg-navy-700">
            <span className="h-full rounded-full bg-brand-500 dark:!bg-white" style={{ width: `${progressPercentage}%` }} />
          </div>
        </div>
      )}
    </Card>
  );
};

export default Storage;
