import React, { useEffect, useRef, useState, useMemo } from "react";
import Card from "components/card";
import { BiSearch } from "react-icons/bi";
import CardMenu from "components/card/CardMenu";
import Modal from "components/modal/Modal";
import SuccessAlert from "components/alerts/SuccessAlerts";
import ErrorAlerts from "components/alerts/ErrorAlerts";
import EmojiPicker from "emoji-picker-react";
import SingleMessage from "./SingleMessage";
import {
  MdChevronLeft,
  MdDelete,
  MdImage,
  MdOutlineAttachment,
  MdOutlineTagFaces,
} from "react-icons/md";
import {
  renderTrackMessages,
  renderThumbMessages,
  renderViewMessages,
} from "components/scrollbar/Scrollbar";
import { IoPaperPlane } from "react-icons/io5";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FaUsers } from "react-icons/fa";
import {
  createMessage,
  createTeamMessage,
  fetchTeamMessages,
  markMessagesAsRead,
  uploadImageFile,
  uploadModelFile,
  uploadDocumentFile,
  clearChatHistory,
  clearTeamChatHistory
} from "api";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";


const SingleChat = ({
  open,
  onClose,
  chatEntity,
  chatType,
  creatingNewChat,
  userId,
  userProfile,
  updateChatsWithUnread, 
}) => {
  const [newMessage, setNewMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [ showClearChatModal, setShowClearChatModal] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [inputHeight, setInputHeight] = useState("auto");
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const scrollRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const fileInputRef = useRef(null);
  const queryClient = useQueryClient();


    // Debounce search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const filtered = messages.filter((msg) =>
      msg.content.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );
    setFilteredMessages(filtered);
  }, [debouncedSearchTerm]);


  const handleClickOutside = (e) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(e.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(e.target) // Check if click is outside
      ) {
        setShowEmojiPicker(false); // Close emoji picker
      }
    };
  
    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside); // Attach listener only when picker is visible
    } else {
      document.removeEventListener("mousedown", handleClickOutside); // Remove listener
    }
  
    return () => document.removeEventListener("mousedown", handleClickOutside); // Cleanup on unmount
  }, [showEmojiPicker]);


  // Keyboard visibility detection
  useEffect(() => {
    const handleResize = () => {
      const isKeyboardOpen = window.visualViewport.height < window.innerHeight - 200;
      setIsKeyboardOpen(isKeyboardOpen);

      if (isKeyboardOpen) {
        setShowEmojiPicker(false); // Automatically hide picker when keyboard is open
      }    
  
      if (isKeyboardOpen && scrollRef.current) {
        scrollRef.current.scrollToBottom(); // Ensure input and previews remain visible
      }
    };
  
    window.visualViewport.addEventListener("resize", handleResize);
  
    return () => window.visualViewport.removeEventListener("resize", handleResize);
  }, []);


  // Fetch messages based on chat type
  const { data: messagesData, error: messagesError } = useQuery({
    queryKey:
      chatType === "team"
        ? ["teamMessages", chatEntity.id]
        : ["messages", userId],
    queryFn: async () => {
      if (chatType === "team") {
        return await fetchTeamMessages(chatEntity.id);
      } else {
        return queryClient.getQueryData(["messages", userId]) || [];
      }
    },
    enabled: !!chatEntity,
    refetchInterval: showClearChatModal ? false : 5000,
  });

  useEffect(() => {
    if (messagesError) {
      console.error("Error fetching messages in SingleChat:", messagesError);
    }
  }, [messagesError]);

  // Filter messages for the selected chat entity
  const messages = useMemo(() => {
    let msgs = [];
    if (chatType === "team") {
      msgs = messagesData || [];
    } else {
      msgs = messagesData?.filter(
        (message) =>
          (message.sender.id === chatEntity.id &&
            message.receiver.id === userProfile.id) ||
          (message.receiver.id === chatEntity.id &&
            message.sender.id === userProfile.id)
      ) || [];
    }
  
    // Filter out deleted messages if they have a deleted flag
    msgs = msgs.filter((msg) => !msg.deletedForUserId?.includes(userId));
  
    // Ensure messages are sorted by timestamp
    return msgs.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  }, [chatType, messagesData, chatEntity.id, userProfile.id]);
  

  useEffect(() => {
    setFilteredMessages(messages);
  }, [messages]);


  const scrollToBottom = () => {
    if (scrollRef.current) {
        scrollRef.current.scrollToBottom();
      }
  };

  useEffect(() => {
    if (chatEntity) {
      setTimeout(scrollToBottom, 100);  // Add a delay to ensure proper scrolling
    }
  }, [chatEntity, filteredMessages]);

  // Mutation for sending a new message
  const mutation = useMutation({
    mutationFn: chatType === "team" ? createTeamMessage : createMessage,
    onMutate: async (newMessageData) => {
      await queryClient.cancelQueries(
        chatType === "team"
          ? ["teamMessages", chatEntity.id]
          : ["messages", userId]
      );

      const previousMessages = queryClient.getQueryData(
        chatType === "team"
          ? ["teamMessages", chatEntity.id]
          : ["messages", userId]
      );

      queryClient.setQueryData(
        chatType === "team"
            ? ["teamMessages", chatEntity.id]
            : ["messages", userId],
        (old) => {
            return old
                ? [
                    ...old,
                    {
                        ...newMessageData,
                        id: Date.now(), // Temporary ID
                        timestamp: new Date().toISOString(),
                        sender: { id: userId },
                        ...(chatType === "team"
                            ? {}
                            : { receiver: { id: chatEntity.id } }),
                    },
                ]
                : [
                    {
                        ...newMessageData,
                        id: Date.now(),
                        timestamp: new Date().toISOString(),
                        sender: { id: userId },
                        ...(chatType === "team"
                            ? {}
                            : { receiver: { id: chatEntity.id } }),
                    },
                ];
        }
    );
    

      return { previousMessages };
    },
    onError: (err, newMessageData, context) => {
      queryClient.setQueryData(
        chatType === "team"
          ? ["teamMessages", chatEntity.id]
          : ["messages", userId],
        context.previousMessages
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        chatType === "team"
          ? ["teamMessages", chatEntity.id]
          : ["messages", userId]
      );
    },
  });

  const handleEmojiClick = (emoji) => {
    setNewMessage((prevMessage) => prevMessage + emoji.emoji);
    setShowEmojiPicker(false); // Close picker after selection
  };

  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Determine the file type by extension or mime-type
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (file.type.startsWith("image/")) {
      setFileType("image");
      setFilePreviewUrl(URL.createObjectURL(file));
    } else if (fileExtension === "stl" || fileExtension === "obj") {
      setFileType("model");
      setFilePreviewUrl(null); // no direct preview for 3D models
    } else if (
      fileExtension === "pdf" ||
      fileExtension === "doc" ||
      fileExtension === "docx"
    ) {
      setFileType("document");
      setFilePreviewUrl(null); // no direct preview for these documents
    } else {
      alert("Unsupported file type");
      return;
    }

    setSelectedFile(file);
  };

  // Send message, with or without file
  const handleSendMessage = async () => {
    if (!chatEntity) return;

    // If both text and file are empty, do nothing
    const trimmedMessage = newMessage.trim();
    if (!trimmedMessage && !selectedFile) return;

    let uploadedFileUrl = null;

    // If a file is selected, upload it now
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("senderId", userId);

        if (chatType === "team") {
          formData.append("teamId", chatEntity.id);
        } else {
          formData.append("receiverId", chatEntity.id);
        }

        formData.append("content", "");

        if (fileType === "image") {
          const uploadResult = await uploadImageFile(formData);
          uploadedFileUrl = uploadResult?.url
          ? `https://academy.innovatorsforge.com/${uploadResult.url.replace(/^\//, "")}`
          : null;
        } else if (fileType === "model") {
          const uploadResult = await uploadModelFile(formData);
          uploadedFileUrl = uploadResult?.url
          ? `https://academy.innovatorsforge.com/${uploadResult.url.replace(/^\//, "")}`
          : null;
        } else if (fileType === "document") {
          const uploadResult = await uploadDocumentFile(formData);
          uploadedFileUrl = uploadResult?.url
          ? `https://academy.innovatorsforge.com/${uploadResult.url.replace(/^\//, "")}`
          : null;
        }
      } catch (error) {
        console.error("File upload failed:", error);
      }
    }

    // Now mutate (create message) with or without the file
    if (chatType === "team") {
      const messageData = {
        senderId: userId,
        teamId: chatEntity.id,
        content: trimmedMessage,
        fileUrl: uploadedFileUrl,
        fileType: fileType || null,
      };
      mutation.mutate(messageData, { onSuccess: () => scrollToBottom() });
    } else {
      const isMentorMessagingAllowed =
        userProfile.role === "mentor" &&
        chatEntity.schoolId === userProfile.schoolId;
      const isUserMessagingAllowed = chatEntity.teamId === userProfile.teamId;

      if (isMentorMessagingAllowed || isUserMessagingAllowed) {
        const messageData = {
          senderId: userId,
          receiverId: chatEntity.id,
          content: trimmedMessage,
          fileUrl: uploadedFileUrl,
          fileType: fileType || null,
        };
        mutation.mutate(messageData, { onSuccess: () => scrollToBottom() });
      } else {
        console.error("You are not allowed to message this user.");
        return;
      }
    }

    // Clear out the input and file preview
    setNewMessage("");
    setSelectedFile(null);
    setFilePreviewUrl(null);
    setFileType(null);
    setFileInputKey(Date.now());
  };

  // Function to clear chat history
  const handleClearChatHistory = async () => {
    try {
      setShowClearChatModal(true);  // Show the modal for confirmation
    } catch (error) {
      console.error("Error preparing chat history clear action:", error);
    }
  };


  const confirmClearChatHistory = async () => {
    try {
      if (chatType === "team") {
        await clearTeamChatHistory(userId, chatEntity.id);
        // Clear chat history locally instead of triggering a refetch
        queryClient.setQueryData(["teamMessages", chatEntity.id], []);
      } else {
        await clearChatHistory(userId, chatEntity.id);
        queryClient.setQueryData(["messages", userId], []);
      }
    
      setShowClearChatModal(false);
      setShowSuccessAlert(true); // Show the success alert
  
      // Auto-close alert after 3 seconds
      setTimeout(() => {
        if (showSuccessAlert) {
          setShowSuccessAlert(false);
        }
      }, 3000);
    } catch (error) {
      console.error("Error clearing chat history:", error);
      setErrorMessages([error.message || "An error occurred while clearing the chat history."]);
      setShowErrorAlert(true);
  
      // Auto-close error alert after 5 seconds
      setTimeout(() => {
        if (showErrorAlert) {
          setShowErrorAlert(false);
          setErrorMessages([]);
        }
      }, 5000);
    }
  };
  

  const closeSuccessAlert = () => {
    setShowSuccessAlert(false);
  };

  const closeErrorAlert = () => {
    setShowErrorAlert(false);
    setErrorMessages([]);
  };


  const chatName =
    chatType === "team"
      ? chatEntity.name
      : `${chatEntity.firstName} ${chatEntity.lastName}`;
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const chatImageUrl =
    chatType === "team"
      ? null
      : chatEntity.imageUrl
      ? `${baseURL}${chatEntity.imageUrl}`
      : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";

  const handleSearchMessages = () => {
    if (searchTerm.trim() === "") {
      setFilteredMessages(messages); // Reset to original messages
    } else {
      const filtered = messages.filter((msg) =>
        msg.content.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredMessages(filtered);
    }
  };
      

  return (
    <div
  className={`duration-175 !fixed left-0 top-[20px] !z-[50] h-full max-h-[100vh] w-full flex-col !font-dm transition-all 
    md:h-[calc(100vh-40px)] lg:!relative lg:left-[unset] lg:top-[unset] lg:!z-[unset] lg:my-0 lg:flex lg:h-full lg:w-[unset] ${
      open
        ? "translate-x-[20px] lg:translate-x-[0px]"
        : "-translate-x-[120%] lg:translate-x-[0px]"
    } sm:w-full sm:h-full sm:top-0 sm:left-0`}
    >
      <Card extra={"w-full h-full p-4 !font-dm"}>
        {/* Header */}
        <div className="mb-auto flex flex-col lg:flex-row items-center justify-between border-b border-gray-200 pb-3 dark:border-navy-700">
          <div className="flex items-center gap-3 w-full lg:w-auto">
            <div className="flex items-center justify-center gap-2">
              <div onClick={onClose} className={"block lg:hidden"}>
                <MdChevronLeft className="h-5 w-5 text-gray-500 dark:text-white" />
              </div>
              <div className="h-[50px] w-[50px] rounded-full flex items-center justify-center bg-gray-300 dark:bg-gray-700">
                {chatType === "team" ? (
                  <FaUsers className="h-6 w-6 text-white" />
                ) : (
                  <img
                    src={chatImageUrl}
                    className="h-full w-full rounded-full object-cover"
                    alt={chatName}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col items-center lg:items-start">
              <h1 className="font-dm text-[18px] sm:text-[20px] font-bold text-navy-700 dark:text-white">
                {creatingNewChat ? "Create New Chat" : chatName}
                {chatType === "team" && (
                  <span className="ml-2 bg-gradient-to-r from-green-400 to-green-600 text-white text-xs px-2 py-1 rounded-full">
                    Team
                  </span>
                )}
                {chatEntity.role === "mentor" && chatType !== "team" && (
                  <span className="ml-2 bg-gradient-to-r from-orange-400 to-orange-600 text-white text-xs px-2 py-1 rounded-full">
                    Mentor
                  </span>
                )}
              </h1>
              {!creatingNewChat && chatType !== "team" && (
                <div className="flex items-center gap-2">
                  <div
                    className={`h-3 w-3 rounded-full border-2 border-white ${
                      chatEntity.status === "Online"
                        ? "bg-green-500"
                        : "bg-red-500"
                    }`}
                  ></div>
                  <h4 className="text-base text-navy-700 dark:text-white">
                    {chatEntity?.status || "Offline"}
                  </h4>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-2 mt-3 lg:mt-0 w-full lg:w-auto">
            <input
              type="text"
              placeholder="Search messages..."
              className="block h-10 w-full lg:w-40 px-4 rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-gray-100 dark:py-2 dark:px-4"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <BiSearch
              className="h-5 w-5 text-dark dark:text-white hover:cursor-pointer"
              onClick={handleSearchMessages}
            />
            <CardMenu
            vertical
            menuItems={[
              {
                label: "Clear Chat",
                icon: <MdDelete className="text-red-500" />,
                onClick: handleClearChatHistory  // Show modal on click
              },
            ]}
          />
          </div>
        </div>
        {/* Message Content */}
        <div className="relative flex-grow overflow-y-auto scrollbar-hide">
          <div className="flex h-[calc(100%-74px)] w-full">
            <Scrollbars
              renderTrackVertical={renderTrackMessages}
              renderThumbVertical={renderThumbMessages}
              renderView={renderViewMessages}
              ref={scrollRef}
            >
              <div className="relative max-h-max overflow-hidden pb-[30px] lg:max-h-[unset] lg:overflow-visible flex flex-col">
                {filteredMessages.map((msg, index) => {
                  const isSent = msg.sender.id === userId;
                  const isTeamChat = chatType === "team";

                  // Only set senderName and senderImageUrl for team chats
                  const senderName =
                    isTeamChat && !isSent
                      ? `${msg.sender.firstName} ${msg.sender.lastName}`
                      : null;
                  const senderImageUrl =
                    isTeamChat && !isSent
                      ? msg.sender.imageUrl
                        ? `${process.env.REACT_APP_API_BASE_URL}${msg.sender.imageUrl}`
                        : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                      : null;

                  // Determine if sender is a mentor
                  const isSenderMentor =
                    isTeamChat && msg.sender.role === "mentor";

                  return (
                    <SingleMessage
                      key={index}
                      message={msg.content}
                      time={msg.timestamp}
                      isSent={isSent}
                      senderName={senderName}
                      senderImageUrl={senderImageUrl}
                      isTeamChat={isTeamChat}
                      isSenderMentor={isSenderMentor} 
                      fileUrl={msg.fileUrl}
                      fileType={msg.fileType}
                    />
                  );
                })}
              </div>
            </Scrollbars>
          </div>

{/* Message Input & Previews */}
<div className="sticky bottom-0 flex items-center gap-2 p-3 bg-lightPrimary dark:bg-navy-700 z-10 rounded-full shadow-lg">
  {/* Input Container (round bubble) */}
  <div className="relative flex items-center w-full rounded-full bg-lightPrimary pr-3 dark:bg-navy-700">

    {/* Emoji Picker Toggle */}
    <p
      className="text-lightSecondary pl-3 text-xl hover:cursor-pointer"
      onClick={() => setShowEmojiPicker((prev) => !prev)}
    >
      <MdOutlineTagFaces />
    </p>

    {/* Emoji Picker */}
    {showEmojiPicker && (
      <div
      className="absolute bottom-[60px] left-0 z-1000"
      ref={emojiPickerRef}
      onClick={(e) => e.stopPropagation()} // Prevent clicks inside picker from closing it
      >
        <EmojiPicker onEmojiClick={handleEmojiClick} />
      </div>
    )}

    {/* Text Input Container */}
    <div className="flex flex-col w-full">
      {/* File Preview (Inline, inside input) */}
      {selectedFile && (
      <div className="flex items-center gap-2 p-2 rounded-lg bg-gray-200 dark:bg-navy-800 mb-1 max-h-[150px] overflow-hidden w-full">
        {/* Preview or File Name */}
        <div className="flex items-center gap-3 flex-1 truncate">
          {fileType === "image" && filePreviewUrl ? (
            <img
              src={filePreviewUrl}
              alt="Preview"
              className="h-10 w-10 rounded object-cover cursor-pointer"
              onClick={() => window.open(filePreviewUrl, "_blank")}
            />
          ) : (
            <div className="h-10 w-10 rounded bg-gray-300 dark:bg-gray-600 flex items-center justify-center">
              <MdOutlineAttachment className="text-gray-500 dark:text-white" />
            </div>
          )}
          <span className="text-sm text-gray-700 dark:text-white truncate">
            {selectedFile.name}
          </span>
        </div>

        {/* Close Button */}
        <button
          className="text-red-500 text-sm underline ml-2"
          onClick={() => {
            setSelectedFile(null);
            setFilePreviewUrl(null);
            setFileType(null);
            setFileInputKey(Date.now());
          }}
        >
          ✕
        </button>
      </div>
        )}


      {/* Text Input */}
      <textarea
        placeholder={
          selectedFile
            ? "Add a caption or type your message..."
            : "Write your message..."
        }
        className="
          w-full
          rounded-full
          bg-lightPrimary
          px-4
          py-[10px]
          text-sm
          text-navy-700
          outline-none
          dark:bg-navy-700
          dark:text-white
          placeholder:text-gray-400
          dark:placeholder:text-gray-300
        "
        value={newMessage}
        rows="1"
        ref={(textarea) => {
          if (textarea) {
            textarea.style.height = "40px";
            textarea.style.height = `${textarea.scrollHeight}px`;
          }
        }}
        style={{
          overflow: "hidden", // prevent scrollbars
          resize: "none",     // disable manual resizing
          minHeight: "40px",
          maxHeight: "150px",
          lineHeight: "1.5",
        }}
        onChange={(e) => {
          setNewMessage(e.target.value);
          e.target.style.height = "40px";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 150)}px`;
          e.target.style.overflowY =
            e.target.scrollHeight > 150 ? "scroll" : "hidden";
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
          }
        }}
      />
    </div>

    {/* File Icons */}
    <div className="flex items-center gap-4">
      {/* File Selection (Documents / 3D Models) */}
      <label className="text-lightSecondary text-xl hover:cursor-pointer">
        <MdOutlineAttachment />
        <input
          key={fileInputKey}
          type="file"
          accept=".obj,.stl,.pdf,.doc,.docx"
          onChange={handleFileSelection}
          ref={fileInputRef} 
          style={{ display: "none" }}
        />
      </label>

      {/* Image Selection */}
      <label className="text-lightSecondary pr-2 text-xl hover:cursor-pointer">
        <MdImage />
        <input
          key={fileInputKey}
          type="file"
          accept="image/*"
          onChange={handleFileSelection}
          ref={fileInputRef} 
          style={{ display: "none" }}
        />
      </label>
    </div>
  </div>

  {/* Send Button */}
  <button
    className={`
      linear flex items-center justify-center
      rounded-full bg-brand-500 p-3 text-base text-white transition duration-200
      hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700
      dark:bg-brand-400 dark:hover:bg-brand-300
      ${!newMessage.trim() && !selectedFile ? "opacity-50 cursor-not-allowed" : ""}
    `}
    onClick={handleSendMessage}
    disabled={!newMessage.trim() && !selectedFile} // Disable send button if nothing is input
  >
    <IoPaperPlane />
  </button>
</div>

          
          
        </div>

      {/* Clear Chat Confirmation Modal */}
    {showClearChatModal && (
      <Modal onClose={() => setShowClearChatModal(false)}>
        <div className="p-6">
          <h2 className="text-lg font-bold text-navy-700 dark:text-white">
            Clear Chat History
          </h2>
          <p className="mt-2 text-gray-600 dark:text-gray-300">
            Ready to clean up? Clearing the chat will delete messages, but you can always send more!
          </p>
          <div className="mt-4 flex justify-end gap-3">
            <button
              className="px-4 py-2 bg-gray-300 rounded-md text-gray-700 dark:bg-gray-600 dark:text-white"
              onClick={() => setShowClearChatModal(false)}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded-md"
              onClick={confirmClearChatHistory}
            >
              Clear Chat
            </button>
          </div>
        </div>
      </Modal>
    )}
      </Card>
    {showSuccessAlert && (
        <SuccessAlert 
          message="Chat has been cleared successfully!" 
          onClose={closeSuccessAlert}
        />
      )}
      {showErrorAlert && (
        <ErrorAlerts 
          errors={errorMessages} 
          onClose={closeErrorAlert} 
        />
      )}
    </div>
  );
};

export default SingleChat;
