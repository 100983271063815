// src/components/Course/InfoSection.jsx
import React from 'react';
import { PiBooks } from 'react-icons/pi';

const InfoSection = () => {
  return (
    <section className="flex justify-center items-center px-8 py-6 bg-white">
      <PiBooks className="h-12 w-12 mr-4" style={{ color: '#FF6600' }} />
      <h1 className="text-2xl font-normal">
        Course Content &mdash;{' '}
        <a
          href="https://codetheweb.blog/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          3D Printing
        </a>
      </h1>
    </section>
  );
};

export default InfoSection;
