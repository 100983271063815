import Card from "components/card";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";

const Information = () => {
  return (
    <Card extra={"w-full px-[20px] py-[22px] h-fit"}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          POAP Information
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Fill out the details for your POAP.
        </p>
      </div>
      {/* Inputs */}
      <div className="mt-9 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <InputField
            extra="mb-3"
            label="POAP Title"
            placeholder="e.g. 3D Printing Explorer"
            id="title"
            type="text"
          />
          <InputField
            extra="mb-3"
            label="Total Points"
            placeholder="e.g. 25"
            id="totalPoints"
            type="number"
          />
          <InputField
            label="Project Name"
            placeholder="e.g. Team Formation"
            id="projectName"
            type="text"
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <TextField
            label="Description"
            placeholder="Short description about the POAP"
            id="description"
            cols="30"
            rows="6"
          />
          <TextField
            label="Learnings"
            placeholder="Key learnings from the POAP"
            id="learnings"
            cols="30"
            rows="6"
          />
        </div>
      </div>
    </Card>
  );
};

export default Information;
