import { useQuery } from "@tanstack/react-query";
import { fetchCoursesSubStatusByUserId } from "../api";

export const useSubscriptionStatus = (userId) => {
  return useQuery({
    queryKey: ["subscriptionStatus", userId],
    queryFn: () => fetchCoursesSubStatusByUserId(userId),
    staleTime: 0,
    cacheTime: 0, 
    enabled: !!userId,
  });
};
