import React, { useEffect, useState } from 'react';
import Card from "components/card";
import CircularProgress from "components/charts/CircularProgress";
import "react-circular-progressbar/dist/styles.css";
import { getUserProfile, fetchCurrentDescription, fetchTeamPointsInfo } from 'api';

const calculateCompletionPercentage = (points, maxPoints) => {
  return maxPoints === 0 ? 0 : Math.floor((points / maxPoints) * 100);
};

function ProfitEstimation() {
  const [teamPoints, setTeamPoints] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [maxPoints, setMaxPoints] = useState(0);
  const [currentProject, setCurrentProject] = useState('');
  const [error, setError] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [hasTeam, setHasTeam] = useState(false);
  const [descriptionId, setDescriptionId] = useState(null);

  useEffect(() => {
    const fetchProfileAndPoints = async () => {
      try {
        const profile = await getUserProfile();

        if (profile.user && profile.user.team) {
          const { id: teamId, projectName } = profile.user.team;
          setTeamId(teamId);
          setHasTeam(true);
          

          // Fetch current description ID
          const { descriptionId, projectName: fetchedProjectName } = await fetchCurrentDescription(teamId);
          setDescriptionId(descriptionId);
          setCurrentProject(fetchedProjectName);

          // Fetch team points info
          const pointsInfo = await fetchTeamPointsInfo(teamId, descriptionId);
          setTeamPoints(pointsInfo.currentPoints);
          setMaxPoints(pointsInfo.currentPoints + pointsInfo.remainingPoints);
        } else {
          setHasTeam(false); // User does not have a team
        }
      } catch (error) {
        console.error("Error fetching data", error);
        setError('Failed to load data');
      }
    };
    fetchProfileAndPoints();
  }, []);

  useEffect(() => {
    setPercentage(calculateCompletionPercentage(teamPoints, maxPoints));
  }, [teamPoints, maxPoints]);

  useEffect(() => {
  }, [teamPoints, maxPoints, percentage]);

  if (error) return <p>{error}</p>;

  return (
    <Card extra={"p-5 lg:h-full"}>
      <div className="mb-auto flex flex-col px-2 text-center">
        <p className="text-lg font-bold text-navy-700 dark:text-white">
          Project Completion Status
        </p>
        <p className="mt-2 px-4 text-sm font-medium text-gray-600">
          Complete the project to earn your exclusive POAP!
        </p>
      </div>
      {/* Circular progress bar */}
      <div className="mx-auto mb-5 mt-2 flex h-40 w-36 items-center justify-center">
        <CircularProgress title="Completion" percentage={percentage} startAtTop={true} />
      </div>

      {/* Conditional rendering based on completion */}
      {percentage >= 100 ? (
        <div className="text-center my-4">
          <p className="text-lg font-bold text-green-500">
            Congratulations! 🎉
          </p>
          <p className="text-md text-navy-700">
            Your team has earned the POAP!
          </p>
          {/* Additional interactive elements could be included here */}
        </div>
      ) : (
        <div className="text-center text-sm text-gray-600 my-4">
          <p>Complete the project to unlock your POAP.</p>
        </div>
      )}

      {/* Displaying task and points details */}
      <Card extra="!flex-row !justify-between px-6 py-4 mb-2 rounded-[20px] dark:!bg-navy-700">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-start justify-center">
            <p className="mb-1 text-xs font-medium text-gray-600">Total Tasks Completed</p>
          </div>
          <p className="text-lg font-bold text-navy-700 dark:text-white">
            {percentage}%
          </p>
        </div>
        <div className="h-12 w-[1px] bg-gray-300 dark:bg-white/10" />
        <div className="flex flex-col items-start justify-center">
          <div className="flex items-center justify-center">
            <p className="mb-1 text-xs font-medium text-gray-600">
              Total Points Earned
            </p>
          </div>
          <p className="text-lg font-bold text-navy-700 dark:text-white">
            {teamPoints}
          </p>
        </div>
      </Card>
    </Card>
  );
}

export default ProfitEstimation;
