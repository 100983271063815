import Card from 'components/card';
import React, { useEffect, useRef, useState } from 'react';
import { getUserProfile } from 'api';
import './dashhome.css'; // Assuming you have a separate CSS file for custom styles

const DashHome = () => {
  const [firstName, setFirstName] = useState('');
  const [loading, setLoading] = useState(true);
  const cardRef = useRef(null);
  const circleRef = useRef(null);
  const smallCircleRef = useRef(null);
  const greetingRef = useRef(null);
  const cardOrangeShineRef = useRef(null);
  const cardGreetingRef = useRef(null);
  const cardCometRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserProfile();
        if (userData && userData.user && userData.user.firstName) {
          setFirstName(userData.user.firstName);
        }
      } catch (error) {
        console.error("Error fetching user profile", error);
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    const images = [
      "https://s3-us-west-2.amazonaws.com/s.cdpn.io/279756/2017_bg.png",
      "https://s3-us-west-2.amazonaws.com/s.cdpn.io/279756/orange_shine.png"
    ];
    
    const loadImages = images.map(src => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(loadImages)
      .then(() => {
        setLoading(false); // Hide the loader when images are fully loaded
      })
      .catch((err) => {
        console.error("Error loading images", err);
        setLoading(false); // Even if there is an error, hide the loader
      });
  }, []);

  const generateTranslate = (el, e, value) => {
    el.style.transform = `translate(${e.clientX * value}px, ${e.clientY * value}px)`;
  };

  const handleMouseMove = (event) => {
    const card = cardRef.current;
    const circle = circleRef.current;
    const smallCircle = smallCircleRef.current;
    const greeting = greetingRef.current;
    const cardOrangeShine = cardOrangeShineRef.current;
    const cardGreeting = cardGreetingRef.current;
    const cardComet = cardCometRef.current;

    if (!card) return;

    const e = event;
    const x = (e.pageX - card.offsetLeft - (350 / 2)) * -1 / 100;
    const y = (e.pageY - card.offsetTop - (350 / 2)) * -1 / 100;

    generateTranslate(smallCircle, e, 0.03);
    generateTranslate(cardGreeting, e, 0.03);
    generateTranslate(cardOrangeShine, e, 0.09);
    generateTranslate(circle, e, 0.05);
    generateTranslate(greeting, e, 0.03);
    generateTranslate(cardComet, e, 0.05);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <Card extra="pb-7 p-5">
      {loading ? (
        <div className="max-w-xl mx-auto">
          <div className="p-4 bg-white border border-primary rounded-md">
            <div className="flex">
              <div className="mr-4 bg-gray-200 border border-gray-200 h-16 w-16 rounded animate-pulse"></div>
              <div className="space-y-1 flex flex-col w-full">
                <div className="flex w-full flex items-center">
                  <div className="bg-gray-200 border border-gray-200 w-60 h-5 animate-pulse"></div>
                  <div className="ml-4 bg-ternary w-12 h-5 animate-pulse"></div>
                </div>
                <div className="bg-gray-200 border border-gray-200 w-36 h-5 animate-pulse"></div>
                <div className="bg-gray-200 border border-gray-200 w-full h-44 animate-pulse"></div>
              </div>
            </div>

            <div className="mt-4 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <div className="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
                <span className="bg-tertiary h-1 w-1 rounded animate-pulse"></span>
                <div className="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
              </div>
              <div className="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="inset-0 m-auto flex justify-center items-center w-full h-[342px] lg:h-[342px] lg:w-[655px] max-w-full md:w-[90%] sm:w-[80%]">
          <div ref={cardRef} className="relative w-full h-full rounded-lg overflow-hidden hidden lg:block" style={{
            backgroundImage: "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/279756/2017_bg.png')",
            backgroundSize: 'cover',
            boxShadow: '-20px 30px 116px rgba(92, 15, 15, 0.54)'
          }}>
            <div ref={greetingRef} className="absolute z-10 text-white font-light text-center" style={{
              fontSize: '48px',
              lineHeight: '56px',
              top: '25%',
              left: '10%',
              right: '10%',
              fontFamily: "'Oswald', sans-serif"
            }}>
              Welcome<br /> {firstName}
            </div>
            <div ref={cardCometRef} className="absolute w-2 h-2 bg-white rounded-full" style={{
              top: '30%',
              left: '25%'
            }}></div>
            <div ref={circleRef} className="absolute rounded-full" style={{
              right: '68px',
              bottom: '34px',
              width: '230px',
              height: '230px',
              background: 'linear-gradient(-239deg, #3B4576 0%, #242A48 59%)',
              boxShadow: '-10px -15px 90px #191C41'
            }}></div>
            <div ref={smallCircleRef} className="absolute rounded-full" style={{
              right: '40%',
              top: '-7%',
              width: '50px',
              height: '50px',
              background: 'linear-gradient(-239deg, #3B4576 0%, #242A48 59%)',
              boxShadow: '-10px -15px 90px #191C41'
            }}></div>
            <div ref={cardOrangeShineRef} className="absolute" style={{
              backgroundImage: "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/279756/orange_shine.png')",
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              right: '-190px',
              top: '-90px',
              bottom: '50px',
              width: '570px',
              height: '500px',
            }}></div>
            <div ref={cardGreetingRef} className="absolute z-10 text-white font-light uppercase" style={{
              fontFamily: "'Oswald', sans-serif",
              left: '33%',
              bottom: '20%',
              letterSpacing: '5px',
              lineHeight: '17px',
              fontSize: '12px',
              color: 'rgba(255, 255, 255, 0.75)'
            }}>
              Greetings<br />to you!
            </div>
          </div>
          <div className="block lg:hidden text-center text-3xl font-light" style={{ fontFamily: "'Oswald', sans-serif" }}>
            Hey {firstName}
          </div>
        </div>
      )}
    </Card>
  );
};

export default DashHome;
