// Assets
import error from "assets/img/others/error.png";

const Alerts = () => {
  return (
    <div className="flex w-full flex-col items-center justify-center rounded-[20px] bg-white py-8 dark:bg-navy-900 md:py-12 lg:py-16 xl:py-24">
      {/* Image Section */}
      <img
        src={error}
        className="mt-4 w-[200px] sm:w-[300px] md:w-[400px] lg:w-[500px]"
        alt="Error"
      />
      
      {/* Title Section */}
      <h1 className="mt-3 text-center text-2xl font-bold text-navy-700 dark:text-white sm:text-3xl md:text-4xl lg:text-5xl">
        Ah, dang. We didn't find that page.
      </h1>
      
      {/* Description Section */}
      <p className="mt-4 px-4 text-center text-sm font-normal text-navy-700 dark:text-white sm:text-base md:text-lg xl:mt-8">
        Maybe it’s best you start back at our home page...
        <a
          href="/admin/dashboard"
          className="ml-1 font-bold text-brand-500 hover:text-brand-600 dark:text-white dark:hover:text-gray-300"
        >
          Return to the homepage here.
        </a>
      </p>
    </div>
  );
};

export default Alerts;
