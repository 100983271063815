import { useEffect, useState } from "react";

import Loader from "components/loaders/Loader";

import { fetchUserTimelines, getUserProfile } from "api";

import EventCalendar from "components/calendar/EventCalendar";
import Event from "../Calendar/components/Event";
import TimeLine from "../Calendar/components/TimeLine";



const Calendar = () => {
  const [calendarData, setCalendarData] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        setLoading(true);
        const profile = await getUserProfile();
        setUserProfile(profile.user);
      } catch (error) {
        console.error("Error loading user profile:", error);
      } finally {
        setLoading(false);
      }
    };

    loadUserData();
  }, []);

  // 2. Once we have the user profile, fetch timelines (events)
  useEffect(() => {
    const loadCalendarData = async () => {
      if (!userProfile) return;

      try {
        setLoading(true);

        // If you want the user’s mentor’s timelines, do:
        // const timelines = await fetchTimelinesByMentor(userProfile.mentorId);

        // If you want all timelines linked to the user’s mentor, do:
        const timelines = await fetchUserTimelines(userProfile.id);


        const formattedData = timelines.map((timeline) => {
          let startDate = new Date(timeline.startDate);
          let endDate = new Date(timeline.endDate || timeline.startDate);

          // Example special-case logic for certain schools
          if (userProfile.schoolName === "Itqan Australian Academy") {
            // Force next Friday
            startDate.setDate(
              startDate.getDate() + ((5 - startDate.getDay() + 7) % 7)
            );
            startDate.setHours(0, 0, 0);
            endDate = new Date(startDate);
            endDate.setHours(23, 59, 59);
          } else if (userProfile.schoolName === "Little Innovators School") {
            // Force next Monday
            startDate.setDate(
              startDate.getDate() + ((1 - startDate.getDay() + 7) % 7)
            );
            startDate.setHours(0, 0, 0);
            endDate = new Date(startDate);
            endDate.setHours(23, 59, 59);
          } else if (userProfile.schoolName === "MIT") {
            // Force next Thursday
            startDate.setDate(
              startDate.getDate() + ((4 - startDate.getDay() + 7) % 7)
            );
            startDate.setHours(0, 0, 0);
            endDate = new Date(startDate);
            endDate.setHours(23, 59, 59);
          }

          return {
            // Example: remove the school name from the title if it exists
            title: timeline.title.replace(
              `${timeline.schoolName || ""} `,
              ""
            ),
            start: startDate.toISOString().split("T")[0],
            end: endDate.toISOString().split("T")[0],
            borderColor: "transparent",
            backgroundColor: "#ff8533",
            className: "info",
          };
        });

        setCalendarData(formattedData);
      } catch (error) {
        console.error("Error loading calendar data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadCalendarData();
  }, [userProfile]);

  // Utility to get the current date in YYYY-MM-DD
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  if (loading) {
    return <Loader />;
  }


  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 lg:grid-cols-11 gap-5 rounded-[20px]">
      <div className="col-span-1 lg:col-span-4 w-full rounded-[20px]">
        <div>
          <TimeLine />
        </div>
        <div className="mt-5">
          <Event />
        </div>
      </div>
      {/* Calendar */}
      <div className="z-0 col-span-1 lg:col-span-7 h-full w-full rounded-[20px] bg-white bg-clip-border px-[24px] py-[20px] shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none">
        {/* Calendar Header */}
        <div>
          <h5 className="text-xl font-bold text-navy-700 dark:text-white">
            Calendar
          </h5>
          <p className="mt-1 text-base font-medium text-gray-600 dark:text-white">
            {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}
          </p>
        </div>
        <div className="mt-[28px] h-[700px] w-full rounded-[20px] dark:text-white">
          <EventCalendar initialDate={getCurrentDate()} calendarData={calendarData} />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
