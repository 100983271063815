import {
  MdDashboard,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdGroup,
  MdEvent,
  MdMessage,
  MdCardMembership,
  MdStars,
  MdSchool,
} from "react-icons/md";

// Admin Imports
import DashboardsDefault from "views/admin/dashboards/default";
import DashboardsRTLDefault from "views/admin/dashboards/rtl";
import DashboardsCarInterface from "views/admin/dashboards/carInterface";
import DashboardsSmartHome from "views/admin/dashboards/smartHome";

//Teams
import TeamsHome from "views/admin/teams/TeamsHome";

// NFT Imports
import NFTMarketplace from "views/admin/nfts/marketplace";
import NFTPage from "views/admin/nfts/page";
import NFTCollection from "views/admin/nfts/collection";
import NFTProfile from "views/admin/nfts/profile";

// Main Imports
import AccountBilling from "views/admin/main/account/billing";
import AccountApplications from "views/admin/main/account/application";
import AccountInvoice from "views/admin/main/account/invoice";
import AccountSettings from "views/admin/main/account/settings";
import AccountAllMilestones from "views/admin/main/account/ProjectMilestones";
import AccountMilestonePage from "views/admin/main/account/MilestonePage";

import UserNew from "views/admin/main/users/newUser";
import UsersOverview from "views/admin/main/users/overview";
import UsersReports from "views/admin/main/users/reports";

import ProfileSettings from "views/admin/main/profile/settings";
import ProfileOverview from "views/admin/main/profile/overview";
import ProfileNewsfeed from "views/admin/main/profile/newsfeed";

import ApplicationsKanban from "views/admin/main/applications/kanban";
import ApplicationsDataTables from "views/admin/main/applications/dataTables";
import ApplicationsCalendar from "views/admin/main/applications/calendar";

import EcommerceNewProduct from "views/admin/main/ecommerce/newProduct";
import EcommerceNewProject from "views/admin/main/ecommerce/newProject"
import EcommerceProductSettings from "views/admin/main/ecommerce/settingsProduct";
import EcommerceProductPage from "views/admin/main/ecommerce/productPage";
import EcommerceOrderList from "views/admin/main/ecommerce/orderList";
import EcommerceOrderDetails from "views/admin/main/ecommerce/orderDetails";
import EcommerceReferrals from "views/admin/main/ecommerce/referrals";

// Others
import OthersNotifications from "views/admin/main/others/notifications";
//import OthersPricing from 'views/admin/main/others/pricing';
import OthersPricing from "views/admin/main/others/pricing";
import OthersError from "views/admin/main/others/404";
import Buttons from "views/admin/main/others/buttons";
import Messages from "views/admin/main/others/messages";
import LandingPage from "views/landing";

// Auth Imports
import ForgotPasswordCentered from "views/auth/forgotPassword/ForgotPasswordCenter.jsx";
import ForgotPasswordDefault from "views/auth/forgotPassword/ForgotPasswordDefault.jsx";
import LockCentered from "views/auth/lock/LockCenter.jsx";
import LockDefault from "views/auth/lock/LockDefault.jsx";
import SignInCentered from "views/auth/signIn/SignInCenter.jsx";
import SignInDefault from "views/auth/signIn/SignInDefault.jsx";
import SignUpCentered from "views/auth/signUp/SignUpCenter.jsx";
import SignUpDefault from "views/auth/signUp/SignUpDefault.jsx";
import VerificationCentered from "views/auth/verification/VerificationCenter.jsx";
import VerificationDefault from "views/auth/verification/VerificationDefault.jsx";
import NftPage from "views/admin/nfts/page";
import TermsOfService from "components/general/TermsOfService";
import PrivacyPolicy from "components/general/PrivacyPolicy";
import { layout } from "@chakra-ui/system";
import ManagementTable from "views/admin/main/account/application/components/ManagementTable";
import MiniStatistics from "views/admin/main/account/application/components/MiniStatistics";
import ResetPassword from "views/auth/forgotPassword/ResetPassword";
import MentorHub from "views/admin/dashboards/mentorHub";
import { Permissions } from "utils/permissions";
import ManageTeam from "views/admin/dashboards/mentorHub/pages/ManageTeam";

import ProtectedRoute from "contexts/ProtectedRoute";
import Calendar from "views/admin/main/applications/calendar";


const routes = [
  
  {
    name: "Home",
    path: "/dashboard",
    icon: <MdHome className="text-inherit h-5 w-5" />,
    layout: "/admin",
    component: <DashboardsDefault />,
    requiredPermission: Permissions.USER_VIEW
  },
  
  // ---Teams---
  {
    name: "Teams",
    path: "/teams",
    icon: <MdGroup className="text-inherit h-5 w-5" />, // Assuming you're using MdGroup for the teams icon
    layout: "/admin",
    component: <TeamsHome />,
    requiredPermission: Permissions.USER_VIEW
  },



 //---POAPS---
  {
  name: "NFTs",
  path: "/poaps",
  icon: <MdCardMembership className="text-inherit h-5 w-5" />, // Assuming you're using MdMessage for the messages icon
  layout: "/admin",
  component: <AccountAllMilestones />,
  requiredPermission: Permissions.USER_VIEW
  },
  

  {
    name: "Course",
    layout: "/admin",
    path: "/course",
    component: (
      <ProtectedRoute
        element={<NFTProfile />}
        requiredPermissions={[Permissions.USER_VIEW, Permissions.MENTOR_VIEW]}
        requiresSubscription={true} // Let ProtectedRoute handle mentor role check
        courseId={1}
      />
    ),
    secondary: true,
    hidden: true,
  },
//---MentorView---
  {
    name:"Mentor Hub",
    path:"/mentor-hub",
    layout:"/admin",
    icon: <MdDashboard className="text-inherit h-5 w-5" />, 
    component: <MentorHub />,
    requiredPermission: Permissions.MENTOR_VIEW
     
  },

  {
    name: "Manage",
    path:"/manage-team",
    layout: "/admin",
    icon: <MdStars className="text-inherit h-5 w-5" />,
    component: <ManageTeam />,
    requiredPermission: Permissions.MENTOR_VIEW
  },
  {
    name: "Courses",
    layout: "/admin",
    path: "/courses",
    component: <NFTCollection />,
    icon: <MdSchool className="text-inherit h-5 w-5" />,
    secondary: true,
    requiredPermission: [Permissions.USER_VIEW, Permissions.MENTOR_VIEW]
  },

  // --- Events---
  {
    name: "Calendar",
    path: "/calendar",
    icon: <MdEvent className="text-inherit h-5 w-5" />, // Assuming you're using MdEvent for the events icon
    layout: "/admin",
    component: <Calendar />,
    requiredPermission: [Permissions.USER_VIEW, Permissions.MENTOR_VIEW]
    },
  
  // ---Messages---
  {
    name: "Community",
    path: "/inbox",
    icon: <MdMessage className="text-inherit h-5 w-5" />, // Assuming you're using MdMessage for the messages icon
    layout: "/admin",
    component: <Messages />, 
    requiredPermission: [Permissions.USER_VIEW, Permissions.MENTOR_VIEW, Permissions.ADMIN_VIEW]
  },
   

  //New Project
  {
    name: "New Project",
    path:"/new-project",
    layout: "/admin",
    component: <EcommerceNewProject />,
    hidden: true,
    requiredPermission: Permissions.USER_VIEW
  }, 

  //Profile-Settings
  {
    name: "profile settings",
    path:"/settings",
    layout:"/admin",
    component: <ProfileOverview />,
    hidden: true,
    requiredPermission: [Permissions.USER_VIEW, Permissions.MENTOR_VIEW, Permissions.ADMIN_VIEW]
  },

  //Billing
  {
    name: "Billing",
    layout: "/admin",
    path: "/billing",
    exact: false,
    component: <AccountBilling />,
    hidden: true,
    requiredPermission: [Permissions.USER_VIEW]
  },

  //POAP Description Page
  {
    name: "POAP Description",
    layout: "/admin",
    path: "/poaps-desc",
    exact: false,
    component: <AccountMilestonePage />,
    hidden: true,
    requiredPermission: Permissions.USER_VIEW
  },

  //Team Creation
  {
    name: "Create Team",
    layout: "/admin",
    path: "/create-team",
    exact: false,
    component: <EcommerceNewProduct />,
    hidden: true,
    requiredPermission: Permissions.USER_VIEW
  },

  {
    name: "Terms",
    layout: "/auth",
    path:"/terms-of-service",
    component: <TermsOfService />,
    hidden: true

  },

  {
    name: "Privacy-Policy",
    layout: "/auth",
    path:"/privacy-policy",
    component: <PrivacyPolicy />,
    hidden: true
  },


  //Authentication
  {
    name: "Default",
    layout: "/auth",
    path: "/sign-in",
    component: <SignInDefault />,
    hidden: true
  },

  {
    name: "Default",
    layout: "/auth",
    path: "/sign-up",
    component: <SignUpDefault />,
    hidden: true
  },

  {
    name: "Default",
    layout: "/auth",
    path: "/verification",
    component: <VerificationDefault />,
    hidden: true
  },

  {
    name: "Default",
    layout: "/auth",
    path: "/forgot-password",
    component: <ForgotPasswordDefault />,
    hidden: true
  },

  {
    name: "Default",
    layout: "/auth",
    path: "/reset-password",
    component: <ResetPassword />,
    hidden: true
  },


  //Admin  
  {
    name: "Management",
    layout:"/admin",
    path: "/main/account/application",
    icon: <MdDashboard className="text-inherit h-5 w-5" />,      
    exact: false,
    component: <AccountApplications />,
    requiredPermission: Permissions.ADMIN_VIEW, 
  },
 
  
  {
    name: "Order Details",
    layout: "/admin",
    path: "/main/ecommerce/order-details/:orderId",
    exact: false,
    hidden:true,
    component: <EcommerceOrderDetails />,
    requiredPermission: Permissions.ADMIN_VIEW,
  },
  {
    name: "Order List",
    layout: "/admin",
    path: "/main/ecommerce/order-list",
    exact: false,
    hidden:true,
    component: <EcommerceOrderList />,
    requiredPermission: Permissions.ADMIN_VIEW,
  },
    

  {
    name: "Settings",
    layout: "/admin",
    path: "/main/account/settings",
    exact: false,
    hidden: true,
    component: <AccountSettings />,
    requiredPermission: [Permissions.USER_VIEW, Permissions.MENTOR_VIEW, Permissions.ADMIN_VIEW]
  },


  {
    name: "Data Tables",
    layout: "/admin",
    path: "/main/applications/data-tables",
    exact: false,
    component: <ApplicationsDataTables />,
    hidden: true
  },

  {
    name: "Invoice",
    layout: "/admin",
    path: "/account/invoice/:invoiceId",
    exact: true,
    component: <AccountInvoice />,
    hidden: true
  },
  {
    name: "Referrals",
    layout: "/admin",
    path: "/main/ecommerce/referrals",
    exact: false,
    component: <EcommerceReferrals />,
    hidden: true
  },
  {
    name: "Users Overview",
    layout: "/admin",
    path: "/main/users/users-overview",
    exact: false,
    component: <UsersOverview />,
    requiredPermission: Permissions.MENTOR_VIEW,
    hidden:true,
  },
  {
    name: "Mentor Reports",
    layout: "/admin",
    path: "/main/users/users-reports",
    exact: false,
    component: <UsersReports />,
    requiredPermission: Permissions.ADMIN_VIEW,
    hidden: true
  },


];
export default routes;
