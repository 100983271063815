import { useState } from "react";
import Card from "components/card";
import InputField from "components/fields/InputField";
import SuccessAlert from "components/alerts/SuccessAlerts";
import ErrorAlerts from "components/alerts/ErrorAlerts";

const Password = ({ onChange }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);

  const handlePasswordChange = async () => {
    // Reset previous error messages
    setErrorMessages([]);
  
    // Validate all fields are filled
    if (!oldPassword || !newPassword || !confirmPassword) {
      setErrorMessages(["All fields are required."]);
      return;
    }
  
    // Validate password match
    if (newPassword !== confirmPassword) {
      setErrorMessages(["New passwords do not match."]);
      return;
    }
  
    try {
      // Trigger the password update callback from the parent component
      await onChange({ oldPassword, newPassword });
  
      // Show success message
      setSuccessMessage("Password updated successfully!");
  
      // Clear fields after successful password change
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      setErrorMessages([error.message || "Failed to change password."]);
    }
  };
  

  const closeSuccessAlert = () => setSuccessMessage(null);
  const closeErrorAlert = () => setErrorMessages([]);

  return (
    <Card extra={"w-full p-4"}>
      {/* Success Alert */}
      {successMessage && <SuccessAlert message={successMessage} onClose={closeSuccessAlert} />}
      {/* Error Alerts */}
      {errorMessages.length > 0 && <ErrorAlerts errors={errorMessages} onClose={closeErrorAlert} />}

      {/* Header */}
      <div className="w-full px-2">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Change Password
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can set your new password
        </p>
      </div>

      {/* Inputs */}
      <div className="mt-7 grid grid-cols-1 gap-3">
        <InputField
          label="Old Password"
          placeholder="Your Old Password"
          id="oldPassword"
          type="password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <InputField
          label="New Password"
          placeholder="Your New Password"
          id="newPassword"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <InputField
          label="Confirm New Password"
          placeholder="Confirm New Password"
          id="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        <div className="mt-12 flex w-full justify-end">
          <button
            className="rounded-xl bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={handlePasswordChange}
          >
            Change Password
          </button>
        </div>
      </div>
    </Card>
  );
};

export default Password;
