import React from 'react';

const CallToAction = () => {
    return (
        <div className="bg-dark text-white text-center p-12">
            <h2 className="text-3xl font-bold mb-2">Ready to Transform Your Curriculum?</h2>
            <p className="mb-4">Join the educational revolution and empower your students with iForge.</p>
            <button className="bg-white text-orange-500 font-bold py-2 px-4 rounded">Get Started</button>
        </div>
    );
}

export default CallToAction;
