import React, { useState } from "react";
import { FaFileAlt, FaFileImage, FaCubes, FaEye, FaDownload } from "react-icons/fa";

// Function to detect and render clickable links
const renderMessageWithLinks = (message = "") => {
  // Ensure message is always a string
  if (!message) return "";

  // Regex to match a single URL
  const urlRegex = /^(https?:\/\/[^\s]+|www\.[^\s]+)$/;

  // If the entire message is just a URL, render it directly as a clickable link
  if (urlRegex.test(message)) {
    const url = message.startsWith("http") ? message : `http://${message}`;
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:underline break-words"
      >
        {url}
      </a>
    );
  }

  // Otherwise, detect and render links inside the message
  const splitRegex = /((https?:\/\/[^\s]+)|(www\.[^\s]+))/g;

  return message.split(splitRegex).map((part, index) => {
    if (part && splitRegex.test(part)) {
      const url = part.startsWith("http") ? part : `http://${part}`;
      return (
        <a
          key={index}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline break-words"
        >
          {part}
        </a>
      );
    }
    return part; // Return plain text for non-URL parts
  });
};


const SingleMessage = ({
  message,
  time,
  isSent,
  senderName,
  senderImageUrl,
  isTeamChat,
  isSenderMentor,
  isLatest,
  fileUrl,
  fileType
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const messageClasses = isSent
    ? "bg-orange-500 text-white rounded-l-xl rounded-br-xl dark:bg-orange-700"
    : "bg-navy-600 text-white rounded-r-xl rounded-bl-xl dark:bg-gray-700 dark:text-white";

  const timeClasses = isSent
    ? "text-white dark:text-gray-300"
    : "text-gray-500 dark:text-gray-300";

  const formattedTime = new Date(time).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  const handleDownload = async (fileName) => {
    try {
      const response = await fetch(fileUrl); // Fetch the file
      const blob = await response.blob(); // Get the data as a blob
      const blobUrl = window.URL.createObjectURL(blob); // Create object URL
      const link = document.createElement("a"); // Create a link element
      link.href = blobUrl;
      link.download = fileName; // Set the filename for download
      link.click(); // Trigger download
      window.URL.revokeObjectURL(blobUrl); // Clean up the URL
    } catch (error) {
      console.error("Download failed:", error);
    }
  };
  

  const renderFileAttachment = () => {
    if (!fileUrl) return null;

    const fileName = fileUrl.split("/").pop();
    const isImage = fileType === "image";
    const isModel = fileType === "model";

    const getFilePreview = () => {
      if (isImage) {
        return (
          <img
            src={fileUrl}
            alt="Preview"
            className="w-full h-32 object-cover rounded-lg"
          />
        );
      }
      if (isModel) {
        return (
          <div className="flex items-center justify-center w-full h-32 rounded-lg bg-gray-200 dark:bg-gray-700">
            <FaCubes className="text-4xl text-gray-500 dark:text-gray-300" />
          </div>
        );
      }
      return (
        <div className="flex items-center justify-center w-full h-32 rounded-lg bg-gray-200 dark:bg-gray-700">
          <FaFileAlt className="text-4xl text-gray-500 dark:text-gray-300" />
        </div>
      );
    };

    return (
      <div className="mt-3">
        {/* File Preview Box */}
        <div
          className="relative w-48 rounded-lg overflow-hidden border border-gray-300 dark:border-gray-600"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {getFilePreview()}

          {/* Hover Overlay */}
          {isHovered && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60">
              <a
                href={fileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-lg p-3 rounded-full bg-gray-700 hover:bg-gray-600 transition"
              >
                <FaEye />
              </a>
            </div>
          )}
        </div>

        {/* File Name and Download Button */}
        <div className="mt-2 flex items-center justify-between gap-4">
          <span className="truncate text-sm text-gray-500 dark:text-gray-300">
            {fileName}
          </span>
          <button
            onClick={() => handleDownload(fileName)}
            className="flex items-center px-3 py-1 text-sm font-medium rounded-lg
            bg-brand-500 text-white hover:bg-brand-600
            dark:bg-brand-500 dark:hover:bg-brand-700 transition duration-150"
          >
            <FaDownload className="mr-2" /> Download
          </button>
        </div>
      </div>
    );
  };

  return (
      <div
      className={`mb-3 flex ${isSent ? "justify-end" : "justify-start"} ${
        isLatest && !isSent ? "bg-blue-50 dark:bg-blue-900 p-2 rounded-lg" : ""
      }`}
      >
      {/* Display avatar only in team chats */}
      {isTeamChat && !isSent && senderImageUrl && (
        <img
          src={senderImageUrl}
          alt={senderName}
          className="h-8 w-8 rounded-full object-cover mr-2"
        />
      )}
      <div className={`p-5 ${messageClasses} max-w-[75%]`}>
        {/* Display sender's name and mentor badge only in team chats */}
        {isTeamChat && senderName && (
          <p className="text-sm font-bold mb-3 flex items-center">
            {senderName}
            {isSenderMentor && (
              <span className="ml-2 bg-blue-500 dark:bg-blue-600 text-white text-xs font-semibold px-2 py-0.5 rounded-full flex items-center">
                <svg
                  className="w-3 h-3 mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zm0 18.333c-1.11 0-2.167-.217-3.125-.617a8.272 8.272 0 0 1-1.792-1.125 1 1 0 0 1-.166-1.391l.5-.5a1 1 0 0 1 1.445-.14A6.75 6.75 0 1 0 10 3.25a6.75 6.75 0 0 0-6.75 6.75A6.75 6.75 0 0 0 10 16.25a8.247 8.247 0 0 0 3.6-.858 1 1 0 0 1 .879 1.792A9.998 9.998 0 0 1 10 18.333z" />
                </svg>
                Mentor
              </span>
            )}
          </p>
        )}

        {/* Render attachments */}
        {renderFileAttachment()}

        {/* Render message with clickable links */}
        <h1 className="text-md pb-2 break-words">
          {renderMessageWithLinks(message)}
        </h1>
        <p className={`text-sm font-medium ${timeClasses}`}>{formattedTime}</p>
      </div>
    </div>
  );
};

export default SingleMessage;
