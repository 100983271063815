import React, { useState, useEffect } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom"; // Use useNavigate for navigation in React Router v6
import Card from "components/card";
import Instructor from "./Instructor";
import Modal from "components/modal/Modal"; // Ensure Modal component exists
import { fetchCoursesSubStatusByUserId } from "api"; // Import the function

const CourseInfo = ({ description, totalPoints, mentor, resourceLink, userId }) => {
  const [toggleState, setToggleState] = useState(1);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
  const [subscriptionStatus, setSubscriptionStatus] = useState(null); // To store the subscription status
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const status = await fetchCoursesSubStatusByUserId(userId);
        setSubscriptionStatus(status);
        if (status && status.active) {
          setIsSubscribed(true);
        } else {
          setIsSubscribed(false);
        }
      } catch (error) {
        console.error("Error fetching subscription status:", error);
      }
    };
    
    checkSubscriptionStatus();
  }, [userId]);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const handleResourceClick = (event) => {
    if (!isSubscribed) {
      event.preventDefault(); // Prevent the default link behavior
      setModalOpen(true); // Show the modal if not subscribed
    }
  };

  const handleSubscribeClick = () => {
    // Add the subscription logic here (e.g., make API call)
    setIsSubscribed(true);
    setModalOpen(false);
    // Optionally, show a success message after subscribing
    navigate('/admin/courses'); // Navigate to /admin/courses after subscribing
  };

  const renderContent = () => {
    if (toggleState === 1) {
      return (
        <div className="mt-10">
          <p className="pr-4 text-lg text-gray-700 dark:text-white mb-10 leading-6">
            {description}
          </p>
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div>
              <p className="text-[74px] font-bold text-yellow-500">{totalPoints}</p>
              <p className="text-base font-bold text-gray-800">Total Points</p>
            </div>
          </div>
        </div>
      );
    } else if (toggleState === 2) {
      return (
        <div className="mt-10">
          <p className="text-lg font-medium text-navy-700 dark:text-white mb-4">Resources</p>
          {resourceLink ? (
            <ul className="list-disc pl-5 space-y-2 text-gray-700 dark:text-white">
              <li>
                <a
                  href={resourceLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                  onClick={handleResourceClick} // Handle click event
                >
                  PrintLab
                </a>
              </li>
            </ul>
          ) : (
            <p className="text-sm text-gray-500 dark:text-white">No resources available.</p>
          )}
        </div>
      );
    }
  };

  return (
    <Card extra={"w-full !py-6 !pb-16 !px-4 md:!px-10"}>
      {/* Modal */}
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)} className="max-w-3xl w-full"> {/* Added class to make modal larger */}
          <div className="text-center">
            <p className="text-xl font-semibold text-gray-700 dark:text-white">
              Oops! You need an active subscription to access this resource.
            </p>
            <p className="text-md text-gray-500 dark:text-white mt-4">
              Subscribe to our course to gain access to exclusive content and resources. Click below to subscribe and start enjoying benefits.
            </p>
            <div className="mt-6">
              <button
                onClick={handleSubscribeClick}
                className="px-6 py-2 bg-brand-500 text-white rounded-full hover:bg-brand-600 transition"
              >
                Subscribe Now
              </button>
              <button
                onClick={() => setModalOpen(false)}
                className="ml-4 px-6 py-2 bg-gray-300 text-gray-700 rounded-full hover:bg-gray-400 transition"
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}

      {/* Header with navigation arrows */}
      <div className="mb-2 mt-3 flex items-center justify-end">
        <p className="text-base font-medium uppercase text-gray-600 dark:text-white">
          Section {toggleState}/2
        </p>
        <div className="flex items-center gap-2 ml-3">
          <button
            className="flex items-center justify-center rounded-full border-2 border-gray-600 p-1.5 text-3xl text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:border-white dark:bg-none dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            onClick={() => toggleTab(toggleState - 1)}
            disabled={toggleState === 1}
          >
            <MdChevronLeft />
          </button>
          <button
            className="flex items-center justify-center rounded-full bg-brand-500 p-2 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={() => toggleTab(toggleState + 1)}
            disabled={toggleState === 2}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>

      {/* Navigation tabs */}
      <div className="mb-1 flex w-full flex-col items-center rounded-xl xl:mb-3">
        <div className="flex w-full justify-start gap-8 overflow-hidden text-3xl">
          {["Notes", "Resources"].map((tab, index) => (
            <div
              key={tab}
              className={`flex items-center gap-3 border-b-[4px] ${
                toggleState === index + 1
                  ? "border-brand-500 dark:border-brand-400"
                  : "border-white dark:border-navy-700"
              } pb-3 hover:cursor-pointer`}
              onClick={() => toggleTab(index + 1)}
            >
              <p className="text-[18px] font-medium text-navy-700 dark:text-white">
                {tab}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Main content */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {renderContent()}
        <Instructor mentor={mentor} />
      </div>
    </Card>
  );
};

export default CourseInfo;
