import { useState } from "react";
import { MdVerified, MdOutlineContentCopy } from "react-icons/md";
import { BiBook } from "react-icons/bi";
import { HiOutlineBriefcase } from "react-icons/hi";
import { FcGraduationCap } from "react-icons/fc";

const Banner = (props) => {
  const { image, address, name = "3D Printing", wallet, profile, onToggle, counts } = props;

  let formattedWallet = `${wallet.slice(0, 4)}...${wallet.slice(wallet.length - 4)}`;

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
    if (index === 1) onToggle("miniCourses");
    if (index === 2) onToggle("projects");
  };

  // Set dynamic date text based on the toggle state
  const dynamicDate =
    toggleState === 1
      ? `Total: ${counts.miniCourses} Mini Courses`
      : `Total: ${counts.projects} Projects`;

  return (
    <div className="flex h-full w-full flex-col items-center rounded-[20px]">
      {/* Banner Image */}
      <div
        style={{ backgroundImage: `url(${image})` }}
        className="relative flex h-[150px] w-full justify-center rounded-[20px] bg-cover bg-no-repeat xl:h-[220px]"
      >
        <div className="absolute -bottom-[70px] flex h-[180px] w-[180px] items-center justify-center rounded-full border-[9px] border-white bg-white dark:border-navy-900 xl:h-[165px] xl:w-[165px]">
        <FcGraduationCap className="text-[90px] text-navy-700 dark:text-white" />
        </div>
      </div>

      {/* Profile Info */}
      <div className="mt-[90px] flex flex-col items-center">
        <div className="flex items-center gap-2">
          <p className="text-[35px] font-semibold text-navy-700 dark:text-white xl:text-[54px]">
            {name}
          </p>
          <MdVerified className="mt-3 text-3xl text-[#3965FF]" />
        </div>
        <p className="mt-3 text-center text-lg font-medium text-[#3965FF] dark:text-white">
          {address}
        </p>
        <p className="mt-3 text-center text-lg font-medium text-navy-700 dark:text-white">
          {dynamicDate}
        </p>
      </div>

      {/* Tabs Section */}
      <div className="flex h-full w-full flex-col items-center overflow-x-scroll px-4 pt-[45px] lg:overflow-x-hidden lg:px-10">
        <div className="mt-[44px] flex w-full justify-between gap-16 overflow-hidden border-b-2 border-gray-200 text-3xl dark:border-white/10 lg:gap-[50px] xl:justify-center xl:gap-[100px]">
          {/* Mini Courses Tab */}
          <div
            className={
              toggleState === 1
                ? "flex items-center gap-2 border-b-[4px] border-brand-500 pb-3 hover:cursor-pointer dark:border-brand-400"
                : "flex items-center gap-2 pb-[20px] hover:cursor-pointer"
            }
            onClick={() => toggleTab(1)}
          >
            <BiBook className="text-lg text-navy-700 dark:text-white" />
            <p className="text-[18px] font-medium text-navy-700 dark:text-white">
              Mini Courses
            </p>
          </div>

          {/* Projects Tab */}
          <div
            className={
              toggleState === 2
                ? "flex items-center gap-2 border-b-[4px] border-brand-500 pb-3 hover:cursor-pointer dark:border-brand-400"
                : "flex items-center gap-2 pb-[20px] hover:cursor-pointer"
            }
            onClick={() => toggleTab(2)}
          >
            <HiOutlineBriefcase className="text-lg text-navy-700 dark:text-white" />
            <p className="text-[18px] font-medium text-navy-700 dark:text-white">
              Projects
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
