import React, { useEffect, useState } from "react";
import { BsArrowsAngleExpand } from "react-icons/bs";
import { MdLockOutline } from "react-icons/md";

import { fetchPoapDescriptions, fetchTeamStepStatus, getUserProfile } from "api";

import LaunchPoap from "../../../assets/img/profile/LaunchPoap.png";
import firstPOAP from 'assets/img/poap-images/explorerPOAP.png';
import secondPOAP from 'assets/img/poap-images/pioneerPOAP.png';
import thirdPOAP from 'assets/img/poap-images/techMasterPOAP.png';
import fourthPOAP from 'assets/img/poap-images/precisionPOAP.png';
import fifthPOAP from 'assets/img/poap-images/tinkerAcePOAP.png';
import sixthPOAP from 'assets/img/poap-images/fusioneerPOAP.png';
import sevethPOAP from 'assets/img/poap-images/craftsmanPOAP.png';
import eithPOAP from 'assets/img/poap-images/optimizerPOAP.png';
import ninthPOAP from 'assets/img/poap-images/methodMakerPOAP.png';
import tenth from 'assets/img/poap-images/mechaMechaPOAP.png';
import eleventh from 'assets/img/poap-images/assistivePOAP.png';
import twelveth from 'assets/img/poap-images/switchHeroPOAP.png';
import thirteen from 'assets/img/poap-images/clipCrafterPOAP.png';
import fourteen from 'assets/img/poap-images/linksmithPOAP.png';
import fifteen from 'assets/img/poap-images/typoCrafterPOAP.png';
import sixteen from 'assets/img/poap-images/replastPOAP.png';
import seventeen from 'assets/img/poap-images/toolCrafterPOAP.png';
import eighteen from 'assets/img/poap-images/growSmartPOAP.png';
import nineteen from 'assets/img/poap-images/spanMasterPOAP.png';

// Helper function to retrieve the correct POAP image
const getPoapImage = (id) => {
  switch (id) {
    case 1:
      return firstPOAP;
    case 2:
      return secondPOAP;
    case 3:
      return thirdPOAP;
    case 4:
      return fourthPOAP;
    case 5:
      return fifthPOAP;
    case 6:
      return sixthPOAP;
    case 7:
      return sevethPOAP;
    case 8:
      return eithPOAP;
    case 9:
      return ninthPOAP;
    case 10:
      return tenth;
    case 11:
      return eleventh;
    case 12:
      return twelveth;
    case 13:
      return thirteen;
    case 14:
      return fourteen;
    case 15:
      return fifteen;
    case 16:
      return sixteen;
    case 17:
      return seventeen;
    case 18:
      return eighteen;
    case 19:
      return nineteen;
    default:
      return LaunchPoap;
  }
};

const FreeCard = ({ mini, hovered, userProfile }) => {
  const [teamId, setTeamId] = useState(null);
  const [currentPoap, setCurrentPoap] = useState(null);
  const [isPoapComplete, setIsPoapComplete] = useState(false);
  const [isMentor, setIsMentor] = useState(false);
  const [poapDescriptions, setPoapDescriptions] = useState([]);

  // Fetch user profile and set initial states
  useEffect(() => {
    if (userProfile) {
      const userTeamId = userProfile?.user?.team?.id || null;
      setTeamId(userTeamId);
      setIsMentor(userProfile.user.role === "mentor");
    }
  }, [userProfile]);

  // Fetch POAP descriptions and determine the current POAP
  useEffect(() => {
    const fetchCurrentPoap = async () => {
      if (!teamId) {
        console.error("Team ID is undefined or null, skipping fetchCurrentPoap");
        return;
      }

      try {
        const descriptions = await fetchPoapDescriptions();

        const statusPromises = descriptions.map(async (poap) => {
          const teamStepStatus = await fetchTeamStepStatus(teamId, poap.id);
          const isComplete = teamStepStatus.every((step) => step.status === "completed");
          return { poap, isComplete };
        });

        const statuses = await Promise.all(statusPromises);

        // Find the most recent completed POAP
        const completedPoaps = statuses.filter((status) => status.isComplete);
        const latestPoap = completedPoaps.reduce((prev, current) =>
          prev.poap.id > current.poap.id ? prev : current
        );

        setPoapDescriptions(descriptions);
        setCurrentPoap(latestPoap?.poap || null);
        setIsPoapComplete(!!latestPoap);
      } catch (error) {
        console.error("Error fetching POAP descriptions or statuses:", error.message);
      }
    };

    fetchCurrentPoap();
  }, [teamId]);

  return (
    <div className="p-6 space-y-6">
      {/* Main FreeCard view */}
      <div
        className={`relative flex h-[280px] w-[240px] flex-col items-center rounded-[20px] bg-white/30 dark:bg-[#FFFFFF]/10 backdrop-blur-lg border border-gray-200 dark:border-gray-700 ${
          mini === false ? "" : mini === true && hovered === true ? "" : "xl:mx-3.5 xl:justify-center"
        }`}
      >
        <BsArrowsAngleExpand
          className={`my-[100px] mx-5 h-6 w-6 text-white ${mini === true && hovered === false ? "block" : "hidden"}`}
        />

        <div
          className={`mt-8 flex flex-col items-center ${
            mini === false ? "block" : mini === true && hovered === true ? "block" : "hidden"
          }`}
        >
          <div className="mt-3 flex items-center justify-center rounded-[20px] bg-[#C9FBD5] py-1 px-2">
            <p className="text-xs font-bold text-green-500">
              {isMentor ? "Mentor" : currentPoap ? currentPoap.title : "No POAP Acquired"}
            </p>
          </div>
        </div>

        <div
          className={`relative h-full w-full flex justify-center items-center px-3 pb-3 ${
            mini === false ? "block" : mini === true && hovered === true ? "block" : "block xl:hidden"
          }`}
        >
          <div className="relative">
            <img
              src={isMentor ? LaunchPoap : currentPoap ? getPoapImage(currentPoap.id) : LaunchPoap}
              alt="Current POAP"
              className={`w-32 h-32 rounded-full object-cover ${isPoapComplete || isMentor ? "" : "blur-md"}`}
            />
            {!isPoapComplete && !isMentor && (
              <div className="absolute inset-0 flex items-center justify-center bg-black/30 backdrop-blur-lg rounded-full">
                <MdLockOutline size={40} className="text-white opacity-80" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeCard;
