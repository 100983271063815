import SearchTableUsersOverview from './components/SearchTableUsersOverview';
import tableDataUsersOverview from './variables/tableDataUsersOverview.json';
import tableDataTeamsOverview from './variables/tableDataTeamsOverview.json';
import { columnsDataUsersOverview } from './variables/columnsDataUsersOverview';
import { columnsDataTeamsOverview } from './variables/columnsDataTeamsOverview';
import Card from 'components/card';

const UserOverview = () => {
  return (
    <Card extra={'w-full h-full mt-3'}>
      <SearchTableUsersOverview
        userTableData={tableDataUsersOverview}
        teamTableData={tableDataTeamsOverview}
        columnsDataUsers={columnsDataUsersOverview}
        columnsDataTeams={columnsDataTeamsOverview}
      />
    </Card>
  );
};

export default UserOverview;
