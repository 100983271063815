import React from 'react';
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md"; // Import dropdown icons

function useOutsideAlerter(ref, setX) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setX(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setX]);
}

const Dropdown = (props) => {
  const { button, children, classNames, animation, label, variant } = props;
  const wrapperRef = React.useRef(null);
  const [openWrapper, setOpenWrapper] = React.useState(false);
  useOutsideAlerter(wrapperRef, setOpenWrapper);

  // Determine styles based on variant
  const isManageVariant = variant === 'manage';

  return (
    <div className={`relative flex flex-col ${isManageVariant ? 'w-full' : ''}`}>
      {label && (
        <label className={`block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1 ${isManageVariant ? 'ml-1.5 font-medium' : 'ml-3 font-bold'}`}>
          {label}
        </label>
      )}
      <div ref={wrapperRef} className={`relative ${isManageVariant ? 'w-full' : 'flex'}`}>
        <div
          className={`flex items-center ${isManageVariant ? 'w-full border rounded-xl border-gray-200 dark:border-white/10 bg-white dark:bg-navy-900 h-12 p-3 cursor-pointer transition-colors duration-200' : ''} ${
            isManageVariant ? 'hover:bg-gray-50 dark:hover:bg-navy-700 dark:hover:text-white hover:text-gray-900' : ''
          }`}
          onMouseDown={() => setOpenWrapper(!openWrapper)}
        >
          {button}
          {isManageVariant && (
            openWrapper 
            ? <MdArrowDropUp className="ml-auto text-gray-700 dark:text-gray-300 dark:hover:text-white" /> 
            : <MdArrowDropDown className="ml-auto text-gray-700 dark:text-gray-300 dark:hover:text-white" />
          )}
        </div>
        <div
          className={`${classNames} absolute z-10 ${
            animation
              ? animation
              : 'origin-top-right transition-all duration-300 ease-in-out'
          } ${openWrapper ? 'scale-100' : 'scale-0'} ${isManageVariant ? 'w-full' : ''} mt-1 bg-white dark:bg-navy-800 rounded-xl shadow-lg`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
