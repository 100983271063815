import React from 'react';
import { FaRegCircle, FaRegCheckCircle } from 'react-icons/fa'; // Icons for timeline nodes

const HowItWorksSection = () => {
    return (
        <section className="relative min-h-screen flex items-center justify-center bg-gradient-to-b from-gray-100 to-white text-center">
            <div className="w-full max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-4xl font-extrabold text-gray-800 mb-10">How It Works</h2>
                <p className="text-lg text-gray-600 mb-12">Join us in a step-by-step journey through our exciting 3D printing challenge.</p>
                <div className="relative w-full">
                    <ol className="relative z-10 space-y-10">
                        <li className="flex justify-center">
                            <div className="flex flex-col items-center">
                                <FaRegCheckCircle className="text-4xl text-orange-500" />
                                <span className="mt-4">Team Formation</span>
                                <p className="text-sm text-gray-600 w-64 mt-2">
                                    Teams form in early January and begin preparing for the competition.
                                </p>
                            </div>
                        </li>
                        <li className="flex justify-center">
                            <div className="flex flex-col items-center">
                                <FaRegCheckCircle className="text-4xl text-orange-500" />
                                <span className="mt-4">Kickoff Event</span>
                                <p className="text-sm text-gray-600 w-64 mt-2">
                                    The competition officially begins in early June with a kickoff event.
                                </p>
                            </div>
                        </li>
                        <li className="flex justify-center">
                            <div className="flex flex-col items-center">
                                <FaRegCheckCircle className="text-4xl text-orange-500" />
                                <span className="mt-4">Design and Build</span>
                                <p className="text-sm text-gray-600 w-64 mt-2">
                                    Teams have six weeks to design, build, and refine their projects.
                                </p>
                            </div>
                        </li>
                        <li className="flex justify-center">
                            <div className="flex flex-col items-center">
                                <FaRegCheckCircle className="text-4xl text-orange-500" />
                                <span className="mt-4">Championship</span>
                                <p className="text-sm text-gray-600 w-64 mt-2">
                                    The season culminates in a grand championship event in late September.
                                </p>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </section>
    );
}

export default HowItWorksSection;
