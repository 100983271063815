import React, { useState, useEffect } from 'react';
import Card from 'components/card';

const StudentList = ({ teamData }) => {
  const [teams, setTeams] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    if (Array.isArray(teamData) && teamData.length > 0) {
      setTeams(teamData);
    } else {
      console.warn("StudentList: Received invalid teamData:", teamData);
    }
  }, [teamData]);

  const sortTeamsByProgress = () => {
    const sortedTeams = [...teams].sort((a, b) => {
      return sortOrder === 'asc'
        ? a.pointsInfo.currentPoints - b.pointsInfo.currentPoints
        : b.pointsInfo.currentPoints - a.pointsInfo.currentPoints;
    });

    setTeams(sortedTeams);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };


  return (
    <Card extra="p-6 bg-white dark:bg-navy-800 shadow-sm rounded-lg">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-semibold text-navy-700 dark:text-white">Team Performance</h3>
        <div className="bg-orange-200 text-orange-800 dark:bg-orange-600 dark:text-white px-3 py-1 rounded-full font-semibold text-sm shadow-md">
          {teamData[0]?.schoolName} {/* Display the school name from the first team */}
        </div>
      </div>
      <div className="overflow-x-auto">
        {teams.length > 0 ? (
          <table className="min-w-full bg-white dark:bg-navy-800 rounded-lg">
            <thead>
              <tr className="bg-gray-100 dark:bg-navy-700">
                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-700 dark:text-gray-300">Team</th>
                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-700 dark:text-gray-300">Current POAP Level</th>
                <th
                  className="px-6 py-4 text-left text-sm font-semibold text-gray-700 dark:text-gray-300 cursor-pointer"
                  onClick={sortTeamsByProgress}
                >
                  Progress {sortOrder === 'asc' ? '▲' : '▼'}
                </th>
              </tr>
            </thead>
            <tbody>
              {teams.map((data) => (
                <tr key={data.id} className="hover:bg-gray-50 dark:hover:bg-navy-600 transition-colors">
                  <td className="px-6 py-4 text-md text-navy-700 dark:text-white font-medium">
                    {data.name}
                  </td>
                  <td className="px-6 py-4 text-md text-navy-700 dark:text-white font-medium">
                    {data.currentPoapLevel ? data.currentPoapLevel.join(', ') : 'Explorer POAP'}
                  </td>
                  <td className="px-6 py-4 text-md text-navy-700 dark:text-white font-medium">
                    <div className="flex items-center">
                      <span className="mr-2 text-gray-700 dark:text-gray-300">{data.points}%</span>
                      <div className="w-full h-2 bg-gray-300 dark:bg-gray-600 rounded">
                        <div
                          className={`h-full rounded ${
                            data.points > 70
                              ? 'bg-gradient-to-r from-green-400 to-green-600'
                              : data.point > 50
                              ? 'bg-gradient-to-r from-yellow-400 to-yellow-600'
                              : 'bg-gradient-to-r from-red-400 to-red-600'
                          }`}
                          style={{ width: `${data.points}%` }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center py-10">
            <p className="text-lg text-gray-600 dark:text-gray-300">No teams found for the assigned school.</p>
            <p className="text-sm text-gray-500 dark:text-gray-400">Please create a team or check the assigned school.</p>
          </div>
        )}
      </div>
    </Card>
  );
};

export default StudentList;
