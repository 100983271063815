import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";

const Project = () => {
  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Project Info
      </h4>

      {/* content */}
      {/* inputs */}
      <div className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-3">
        <div>
          <InputField
            extra="mb-4"
            label="Project Name"
            placeholder="eg. Elegant Chair"
            id="productname"
            type="text"
          />
          <InputField
            extra="mb-4"
            label="Material"
            placeholder="eg. PLA"
            id="weight"
            type="text"
          />
          <InputField
            extra="mb-4"
            label="Color"
            placeholder="eg. purple"
            id="color"
            type="text"
          />
        </div>
        {/* right side */}
        <div>
          <InputField
            extra="mb-3"
            label="Theme"
            placeholder="eg. Space"
            id="collection"
            type="text"
          />

          {/* text area */}
          <TextField
            label="Description"
            placeholder="Short description about the product!"
            id="textarea"
            cols="30"
            rows="7"
          />
        </div>
      </div>
    </div>
  );
};

export default Project;
