import React from "react";
import Card from "components/card";

const TermsOfService = () => {
  return (
    <div className="flex justify-center mt-10 px-4">
      <Card extra="w-full lg:w-2/3 p-8 bg-white dark:bg-navy-700 rounded-2xl shadow-lg">
        <h1 className="text-4xl font-bold text-navy-700 dark:text-white mb-6 text-center">Terms of Service</h1>
        <div className="text-navy-700 dark:text-gray-200 space-y-8">
          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">1. Introduction</h2>
            <p className="text-lg">
              Welcome to our website and dashboard. By accessing or using our services, you agree to be bound by these terms of service.
              If you do not agree to these terms, you may not use our services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">2. Use of Services</h2>
            <p className="text-lg">
              You agree to use our services only for lawful purposes. You may not use our services in any way that could damage our 
              services or impair anyone else’s use of our services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">3. User Accounts</h2>
            <p className="text-lg">
              To access some of our services, you may be required to create an account. You agree to provide accurate and complete 
              information when creating your account and to keep your account information up-to-date.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">4. Privacy</h2>
            <p className="text-lg">
              Our <a href="/auth/privacy-policy" className="text-brand-500 dark:text-brand-400 hover:underline">Privacy Policy</a> describes 
              how we handle the information you provide to us when you use our services. By using our services, you agree to our privacy practices.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">5. Intellectual Property</h2>
            <p className="text-lg">
              All content on our services, including text, graphics, logos, and images, is the property of our company or our content 
              suppliers and is protected by intellectual property laws. You may not use any content from our services without our express 
              written permission.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">6. Termination</h2>
            <p className="text-lg">
              We may terminate or suspend your access to our services at any time, without prior notice or liability, for any reason 
              whatsoever, including if you breach these terms of service.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">7. Changes to Terms</h2>
            <p className="text-lg">
              We may update these terms of service from time to time. We will notify you of any changes by posting the new terms on 
              our website. You are advised to review these terms periodically for any changes. Changes to these terms are effective 
              when they are posted on this page.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-navy-700 dark:text-white mb-4">8. Contact Us</h2>
            <p className="text-lg">
              If you have any questions about these terms of service, please contact us at <a href="mailto:support@iforge.io" className="text-brand-500 dark:text-brand-400 hover:underline">support@iforge.io</a>.
            </p>
          </section>
        </div>
      </Card>
    </div>
  );
};

export default TermsOfService;
