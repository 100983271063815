import axios from "axios";
import axiosInstance from "./axiosInstance.js";



//Fetch the logged-in users profile
export const getUserProfile = async () => {
  try {
    const response = await axiosInstance.get('/api/users/profile');
    return response.data;
  } catch (error) {
    console.error("Error fetching user profile", error);
    throw error;
  }
};

export const searchUsersMinus = async (username) => {
  try {
    const response = await axiosInstance.get('/api/users/search-exclude-mentors', {
      params: { username } // This should be sent as a query parameter
    });
    return response.data;
  } catch (error) {
    console.error("Error searching users excluding mentors:", error);
    return [];
  }
};

// Change user's password
export const updatePassword = async (userId, { oldPassword, newPassword }) => {
  try {
      const response = await axiosInstance.put(`/api/users/${userId}/update-password`, { oldPassword, newPassword });
      return response.data;
  } catch (error) {
      if (error.response) {
          // Return error from server
          throw new Error(error.response.data.message || "Failed to update password.");
      }
      console.error("Error updating password:", error);
      throw new Error("Failed to update password.");
  }
};

// Toggle Two-Factor Authentication
export const toggleTwoFactorAuth = async (userId, { isEnabled }) => {
  try {
    const response = await axiosInstance.put(`/api/users/${userId}/two-factor-auth`, { isEnabled });
    return response.data;
  } catch (error) {
    console.error("Error toggling Two-Factor Authentication:", error);
    throw error;
  }
};

// Search users by school
export const searchUsersBySchool = async (username, excludeUserId) => {
  try {
    const response = await axiosInstance.get('/api/users/search', {
      params: { username, excludeUserId }
    });
    return response.data;
  } catch (error) {
    console.error("Error searching users by school:", error);
    return [];
  }
};

// Upload user's profile picture
export const uploadProfilePic = async (formData) => {
  try {
    const response = await axiosInstance.post('/api/users/upload-profile-pic', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading profile picture:", error);
    throw error;
  }
};



//Fetch team-members for logged-in user
export const fetchTeamMembers = async (teamId) => {
  try {
    const response = await axiosInstance.get(`/api/teams/${teamId}/members`);
    return response.data;
  } catch (error) {
    console.error("Error fetching team members:", error.response ? error.response.data : error.message);
    throw error;
  }
};

// Fetch a specific user by ID
export const fetchUserById = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/users/${userId}`);
    return response.data;
  } catch (error) {
    console.log("Error fetching user details:", error);
    return null;
  }
};

export const fetchUsersBySchool = async (schoolName) => {
  try {
    const response = await axiosInstance.get(`/api/users/school/${encodeURIComponent(schoolName)}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching users by school:", error);
    throw error;
  }
};

// Fetch messages from a specific user
export const fetchMessages = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/messages/user/${userId}`);
    return response.data;
  } catch (error) {
    console.log("Error fetching messages:", error);
    return [];
  }
};

//Create a new Message
export const createMessage = async (messageData) => {
  try {
    const response = await axiosInstance.post(`/api/messages`, messageData);
    return response.data;
  } catch (error) {
    console.error("Error creating message", error);
    throw error;
  }
};

export const fetchUnreadMessages = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/messages/unread/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching unread messages:", error);
    return [];
  }
};


export const markMessagesAsRead = async (messageIds) => {
  try {
    // Normalize to array for consistent API payload
    const ids = Array.isArray(messageIds) ? messageIds : [messageIds];
    const response = await axiosInstance.post(`/api/messages/markAsRead`, { messageIds: ids });
    return response.data;
  } catch (error) {
    console.error("Error marking messages as read:", error);
    throw error;
  }
};

// Clear chat history between two users
export const clearChatHistory = async (userId, chatWithId) => {
  try {
    const response = await axiosInstance.post(`/api/messages/clear-history`, {
      userId,
      chatWithId,
    });
    return response.data;
  } catch (error) {
    console.error("Error clearing chat history:", error);
    throw error;
  }
};

// Clear team chat history
export const clearTeamChatHistory = async (userId, teamId) => {
  try {
    const response = await axiosInstance.post(`/api/messages/teamMessages/clear-history`, {
      userId,
      teamId,
    });
    return response.data;
  } catch (error) {
    console.error("Error clearing team chat history:", error);
    throw error;
  }
};


// Team ranking
export const fetchTeamRankings = async () => {
  try {
    const response = await axiosInstance.get(`/api/teams/ranked`);
    return response.data;
  } catch (error) {
    console.error("Error fetching rankings:", error);
    return [];
  }
};

//Achievements
export const fetchTopAchievements = async () => {
  try {
    const response = await axiosInstance.get(`/api/teams/achievements`);
    return response.data;
  } catch (error) {
    console.error(" Error fetching top achievements:", error);
    return [];
  }
};


// Add members to a team
export const addMembersToTeam = async (teamId, members) => {
  try {
    const response = await axiosInstance.post(`/api/teams/${teamId}/add-members`, { members });
    return response.data;
  } catch (error) {
    console.error("Error adding members to team:", error);
    throw error;
  }
};

// Check if team name exists
export const checkTeamName = async (name) => {
  try {
    const response = await axiosInstance.get('/api/teams/checkTeamName', { params: { name } });
    return response.data;
  } catch (error) {
    console.error("Error checking team name:", error);
    throw error;
  }
};


// Fetch tasks for teams
export const fetchUniversalTasks = async () => {
  try {
    const response = await axiosInstance.get(`/api/tasks`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tasks:", error);
    return [];
  }
};

// Complete a task for a team
export const completeTaskForTeam = async (taskId, teamId) => {
  try {
    const response = await axiosInstance.post(`/api/tasks/complete`, { taskId, teamId });
    return response.data;
  } catch (error) {
    console.error("Error completing task:", error);
    throw error;
  }
};

// Fetch team specific task completion status
export const fetchTeamTaskStatuses = async (teamId) => {
  try {
    const response = await axiosInstance.get(`/api/teams/${teamId}/task-status`);
    return response.data;
  } catch (error) {
    console.error("Error fetching team status", error);
    return [];
  }
};



// Fetch all POAP descriptions
export const fetchPoapDescriptions = async () => {
  try {
    const response = await axiosInstance.get(`/api/poap-descriptions`);
    return response.data;
  } catch (error) {
    console.error("Error fetching POAP descriptions:", error);
    return [];
  }
};

// Fetch a specific POAP description by ID
export const fetchPoapDescriptionById = async (id) => {
  try {
    const response = await axiosInstance.get(`/api/poap-descriptions/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching POAP description:", error);
    return null;
  }
};

// Fetch TeamStep Statuses
export const fetchTeamStepStatus = async (teamId, descriptionId) => {
    try {
        const response = await axiosInstance.get(`/api/poap-descriptions/teams/${teamId}/poap-descriptions/${descriptionId}/steps-status`);
        return response.data;
    } catch (error) {
        console.error("Error fetching team step statuses", error);
        throw error;
    }
};

//Complete TeamSteps
export const completeStep = async (teamId, stepId, descriptionId) => {
  try {
    const response = await axiosInstance.post(`/api/poap-descriptions/poap/completeStep/${teamId}/${stepId}`, { descriptionId });
    return response.data;
  } catch (error) {
    console.error("Error completing step:", error);
    throw error;
  }
};

// Fetch team points info
export const fetchTeamPointsInfo = async (teamId, descriptionId) => {
  try {
    const response = await axiosInstance.get(`/api/poap-descriptions/points-info/${teamId}/${descriptionId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching team points info:", error);
    throw error;
  }
};

//Fetch current DescriptionId
export const fetchCurrentDescription = async (teamId) => {
  try {
    const response = await axiosInstance.get(`/api/poap-descriptions/poap/currentDescription/${teamId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching current description:", error);
    throw error;
  }
};

// Subscribe to newsletter
export const subscribeToNewsletter = async (email) => {
  try {
    const response = await axiosInstance.post('/api/subscribe', { email });
    return response.data;
  } catch (error) {
    console.error("Error subscribing to newsletter:", error);
    throw error;
  }
};

export const fetchSpecificStepStatus = async (teamId, descriptionId, poapStepId) => {
    try {
      const response = await axiosInstance.get(`/api/poap-descriptions/teams/${teamId}/poap-descriptions/${descriptionId}/steps/${poapStepId}/status`);
      return response.data;
    } catch (error) {
      console.error("Error fetching specific description", error)
      throw error
    }
    
};

export const fetchProjectStatus = async (teamId, descriptionId) => {
  try {
      const response = await axiosInstance.get(`/api/poap-descriptions/teams/${teamId}/poap-descriptions/${descriptionId}/status`);
      return response.data;
  } catch (error) {
      console.error("Error fetching project status", error);
      throw error;
  }
};

// Fetch all tasks
export const fetchTasks = async () => {
  try {
    const response = await axiosInstance.get(`/api/tasks`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tasks:", error);
    return [];
  }
};

// Fetch tasks for a specific MintLink and Team
export const fetchTasksByMintLinkAndTeam = async (mintLinkId, teamId) => {
  try {
    const response = await axiosInstance.get(`/api/tasks/${mintLinkId}/${teamId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tasks:", error);
    return [];
  }
};

// Fetch all club points
export const fetchClubPoints = async () => {
  try {
    const response = await axiosInstance.get(`/api/points`);
    return response.data;
  } catch (error) {
    console.error("Error fetching club points:", error);
    return [];
  }
};

// Fetch club points by team ID
export const fetchClubPointsByTeam = async (teamId) => {
  try {
    const response = await axiosInstance.get(`/api/points/team/${teamId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching club points by team ID:", error);
    return [];
  }
};

// Create a new club point
export const createClubPoint = async (clubPointData) => {
  try {
    const response = await axiosInstance.post(`/api/points`, clubPointData);
    return response.data;
  } catch (error) {
    console.error("Error creating club point:", error);
    throw error;
  }
};

// Update a club point
export const updateClubPoint = async (clubPointId, clubPointData) => {
  try {
    const response = await axiosInstance.put(`/api/points/${clubPointId}`, clubPointData);
    return response.data;
  } catch (error) {
    console.error("Error updating club point:", error);
    throw error;
  }
};

// Delete a club point
export const deleteClubPoint = async (clubPointId) => {
  try {
    const response = await axiosInstance.delete(`/api/points/${clubPointId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting club point:", error);
    throw error;
  }
};


//Email Verification
export const handleVerification = async (code) => {
  try {
    const response = await axiosInstance.get(`/api/auth/verify-email`, {
      params: { code }
    });
    return response.data
  } catch (error) {
    console.error("Error verifying email", error);
    throw error;
  }
};



//Resend Verification code
export const resendVerificationCode = async ({ email }) => {
  try {
    const response = await axiosInstance.post(`/api/auth/resend-verification`, {email});
    return response.data;
  } catch (error) {
    console.error("Error resending verification code", error);
    throw error;
  }  
};

//ADMIN FUNCTIONS
// Create a new user
export const createUser = async (userData) => {
  try {
    const response = await axiosInstance.post('/api/users', userData);
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

// Fetch Users
export const fetchUsers = async () => {
  try {
    const response = await axiosInstance.get(`/api/users`);
    // Transform the data to match the table's expected format
    return response.data.map(user => ({
      name: [`${user.firstName} ${user.lastName}`,user.email, user.imageUrl],
      email: user.email,
      status: user.status,
      teamId: user.teamId,
      school: user.school,
      teamRole: user.teamRole,
      team: user.team ? user.team.name : "No Team"
    }));
  } catch (error) {
    console.log("Error fetching users:", error);
    return [];
  }
};


// Update an existing user
export const updateUser = async (userId, userData) => {
  try {
    const response = await axiosInstance.put(`/api/users/update/${userId}`, userData);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Handle server errors (e.g., validation issues)
      throw new Error(error.response.data.message || "Failed to update user.");
    } else {
      console.error("Error updating user:", error);
      throw new Error("Failed to update user.");
    }
  }
};

// Delete a user
export const deleteUser = async (userId) => {
  try {
    const response = await axiosInstance.delete(`/api/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

// Fetch all teams
export const fetchTeams = async () => {
  try {
    const response = await axiosInstance.get(`/api/teams`);
    // Ensure the response data is in the expected format
    if (Array.isArray(response.data)) {
      return response.data.map(team => ({
        id: team.id,
        name: team.name,
        description: team.description,
        schoolName: team.schoolName,
        points: team.points,
        currentPoapLevel: team.currentLevels
      }));
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching teams:", error);
    return [];
  }
};

//Get Mentor's teams
export const fetchTeamsByMentor = async (mentorId) => {
  try {
    const response = await axiosInstance.get(`/api/teams/mentor/${mentorId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching teams associated with this mentor:", error);
    throw error;
  }
};

// Create a new team
export const createTeam = async (teamData) => {
  try {
    const response = await axiosInstance.post('/api/teams', teamData);
    return response.data;
  } catch (error) {
    console.error("Error creating team:", error);
    throw error;
  }
};

// Update an existing team
export const updateTeam = async (teamId, teamData) => {
  try {
    const response = await axiosInstance.put(`/api/teams/${teamId}`, teamData);
    return response.data;
  } catch (error) {
    console.error("Error updating team:", error);
    throw error;
  }
};

// Delete a team
export const deleteTeam = async (teamId) => {
  try {
    const response = await axiosInstance.delete(`/api/teams/${teamId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting team:", error);
    throw error;
  }
};


// Issue POAPs to teams via mint links
export const issuePoapsToTeam = async (teamId) => {
  try {
    const response = await axiosInstance.post(`/api/teams/${teamId}/issue-poaps`);
    return response.data;
  } catch (error) {
    console.error("Error issuing POAPs to team:", error);
    throw error;
  }
};


// Create a new POAP description
export const createPoapDescription = async (poapData) => {
  try {
    const response = await axiosInstance.post(`/api/poap-descriptions`, poapData);
    return response.data;
  } catch (error) {
    console.error("Error creating POAP description:", error);
    throw error;
  }
};

// Update an existing POAP description
export const updatePoapDescription = async (id, poapData) => {
  try {
    const response = await axiosInstance.put(`/api/poap-descriptions/${id}`, poapData);
    return response.data;
  } catch (error) {
    console.error("Error updating POAP description:", error);
    throw error;
  }
};

// Delete a POAP description
export const deletePoapDescription = async (id) => {
  try {
    const response = await axiosInstance.delete(`/api/poap-descriptions/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting POAP description:", error);
    throw error;
  }
};

// Complete team step
export const completeTeamStep = async (teamId, stepId, descriptionId) => {
  try {
    const response = await axiosInstance.post(`/api/poap-descriptions/teams/${teamId}/steps/${stepId}/complete`, { descriptionId });
    return response.data;
  } catch (error) {
    console.error("Error completing team step:", error);
    throw error;
  }
};

// Fetch notifications for a specific user
export const fetchNotifications = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/notifications/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    return [];
  }
};

// Mark a notification as read
export const markNotificationAsRead = async (notificationId) => {
  try {
    const response = await axiosInstance.put(`/api/notifications/${notificationId}/read`);
    return response.data;
  } catch (error) {
    console.error("Error marking notification as read:", error);
    throw error;
  }
};

// Create a new notification (This can be used if needed)
export const createNotification = async (notificationData) => {
  try {
    const response = await axiosInstance.post('/api/notifications', notificationData);
    return response.data;
  } catch (error) {
    console.error("Error creating notification:", error);
    throw error;
  }
};

// Forgot Password
export const forgotPassword = async (email) => {
  try {
    const response = await axiosInstance.post('/api/users/forgot-password', { email });
    return response.data;
  } catch (error) {
    console.error("Error requesting password reset:", error);
    throw error;
  }
};

// Reset Password
export const resetPassword = async (token, newPassword) => {
  try {
    const response = await axiosInstance.post('/api/users/reset-password', { token, newPassword });
    return response.data;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

export const fetchMentorBySchool = async (school) => {
  try {
    const response = await axiosInstance.get(`/api/teams/schools/${encodeURIComponent(school)}/mentor`);
    return response.data.mentorId;
  } catch (error) {
    console.error("Error fetching mentor by school:", error);
    throw error;
  }
};

export const fetchUsersAssignedToMentor = async (mentorId) => {
  try {
    const response = await axiosInstance.get(`/api/users/assigned-to-mentor/${mentorId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching users assigned to mentor", error);
    throw error;
  }
};


// Approval requests
export const fetchApprovalRequests = async () => {
  try {
    const response = await axiosInstance.get('/api/approvals/mentor/requests');

    console.log("Approval Requests Data:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching approval requests:", error);
    throw error;
  }
};

// Get Approval Status
export const updateApprovalStatus = async (approvalRequestId, status) => {
  try {
    const response = await axiosInstance.put(`/api/approvals/requests/${approvalRequestId}/status`, {status});
    return response.data;
  } catch (error) {
    console.error("Error updating approval status:", error);
    throw error;
  }
}

export const rejectApprovalRequest = async (approvalRequestId) => {
  try {
    const response = await axiosInstance.post(`/api/approvals/requests/${approvalRequestId}/reject`);
    return response.data;
  } catch (error) {
    console.error("Error rejecting approval request:", error);
    throw error;
  }
};

export const createApprovalRequest = async (stepId, teamId) => {
  try {
    const response = await axiosInstance.post(`/api/approvals/requests`, {
      stepId,
      teamId,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating approval request:", error);
    throw error;
  }
};


// Courses & CourseSubscriptions
// Fetch all courses
export const fetchAllCourses = async () => {
  try {
    const response = await axiosInstance.get('/api/courses');
    return response.data;
  } catch (error) {
    console.error("Error fetching courses:", error);
    throw error;
  }
};

// Fetch courses by user ID
export const fetchCoursesByUserId = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/courses/user/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching courses by user ID:", error);
    throw error;
  }
};

// Fetch a specific course by ID
export const fetchCourseById = async (courseId) => {
  try {
    const response = await axiosInstance.get(`/api/courses/${courseId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching course:", error);
    throw error;
  }
};

// Create a new course
export const createCourse = async (courseData) => {
  try {
    const response = await axiosInstance.post('/api/courses', courseData);
    return response.data;
  } catch (error) {
    console.error("Error creating course:", error);
    throw error;
  }
};

// Update an existing course
export const updateCourse = async (courseId, courseData) => {
  try {
    const response = await axiosInstance.put(`/api/courses/${courseId}`, courseData);
    return response.data;
  } catch (error) {
    console.error("Error updating course:", error);
    throw error;
  }
};

// Delete a course
export const deleteCourse = async (courseId) => {
  try {
    const response = await axiosInstance.delete(`/api/courses/${courseId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting course:", error);
    throw error;
  }
};

// Subscribe to a course
export const subscribeToCourse = async (subscriptionData) => {
  try {
    const response = await axiosInstance.post('/api/course-subscriptions', subscriptionData);
    return response.data;
  } catch (error) {
    console.error("Error subscribing to course:", error);
    throw error;
  }
};

// Fetch all course subscriptions
export const fetchAllCourseSubscriptions = async () => {
  try {
    const response = await axiosInstance.get('/api/course-subscriptions');
    return response.data;
  } catch (error) {
    console.error("Error fetching course subscriptions:", error);
    throw error;
  }
};

export const checkSubscriptionEligibility = async (userIds) => {
  try {
    const response = await axiosInstance.post(`/api/course-subscriptions/check-eligibility`, { userIds });
    return response.data.eligibility; // Returns an array of eligibility statuses
  } catch (error) {
    console.error("Error checking subscription eligibility:", error);
    throw error;
  }
};

// Fetch remaining subscription time for a user
export const fetchRemainingSubscriptionTime = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/course-subscriptions/user/${userId}/remaining-time`);
    return response.data.subscriptions;
  } catch (error) {
    console.error("Error fetching remaining subscription time:", error);
    throw error;
  }
};

// Fetch a specific course subscription by ID
export const fetchCourseSubscriptionById = async (subscriptionId) => {
  try {
    const response = await axiosInstance.get(`/api/course-subscriptions/${subscriptionId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching course subscription:", error);
    throw error;
  }
};

export const fetchCoursesSubStatusByUserId = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/course-subscriptions/user/${userId}/status`);
    return response.data;
  } catch (error) {
    console.error("Error fetching courses with subscription status:", error);
    throw error;
  }
};

export const confirmSubscriptionPayment = async (subscriptionId, mpesaReferenceNumber) => {
  try {
      const response = await axiosInstance.post('/api/course-subscriptions/confirm', {
          subscriptionId,
          mpesaReferenceNumber
      });
      return response.data;
  } catch (error) {
      console.error("Error confirming subscription payment:", error);
      throw error;
  }
};

// Cancel a course subscription
export const cancelCourseSubscription = async (subscriptionId) => {
  try {
    const response = await axiosInstance.delete(`/api/course-subscriptions/${subscriptionId}`);
    return response.data;
  } catch (error) {
    console.error("Error canceling course subscription:", error);
    throw error;
  }
};

export const checkPoapEligibility = async (userId, courseId) => {
  try {
    const response = await axiosInstance.get(`/api/course-subscriptions/poap-eligibility/${userId}/${courseId}`);
    return response.data;
  } catch (error) {
    console.error("Error checking POAP eligibility:", error);

    // Check if the error is due to ineligibility (e.g., status code 403 or 400)
    if (error.response && (error.response.status === 403 || error.response.status === 400)) {
      // Return isEligible: false
      return { isEligible: false };
    } else {
      // Re-throw other errors (e.g., network issues, server errors)
      throw error;
    }
  }
};



//Payments

//M-Pesa
// Initiate STK Push
export const initiateStkPush = async (phoneNumber, amount, accountReference, userId, courseId, referralCodeId) => {
  try {
    const response = await axiosInstance.post('/api/mpesa/stkpush', {
      phoneNumber,
      amount,
      accountReference,
      userId,
      courseId,
      referralCodeId
    });
    return response.data;
  } catch (error) {
    console.error("Error initiating STK Push:", error);
    throw error;
  }
};

// Handle Mpesa Callback
export const handleMpesaCallback = async (callbackData) => {
  try {
    const response = await axiosInstance.post('/api/mpesa/stkpush/callback', callbackData);
    return response.data;
  } catch (error) {
    console.error("Error handling Mpesa callback:", error);
    throw error;
  }
};


//Billing
// Fetch all billing records
export const fetchAllBillings = async () => {
  try {
    const response = await axiosInstance.get('/api/billings');
    return response.data;
  } catch (error) {
    console.error("Error fetching all billing records:", error);
    throw error;
  }
};

export const fetchBillingsByUser = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/billings/user/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching billing records by user ID:", error);
    throw error;
  }
};

// Fetch billing records by user ID
export const fetchBillingsByUserId = async (userId, orderId) => {
  try {
    const endpoint = orderId
      ? `/api/billings/user/${userId}/billing/${orderId}`
      : `/api/billings/user/${userId}`;

    const response = await axiosInstance.get(endpoint);
    return response.data;
  } catch (error) {
    console.error("Error fetching billing records by user ID and order ID:", error);
    throw error;
  }
};

// Fetch a specific billing record by ID
export const fetchBillingById = async (billingId) => {
  try {
    const response = await axiosInstance.get(`/api/billings/${billingId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching billing record by ID:", error);
    throw error;
  }
};

// Delete a billing record by ID
export const deleteBillingById = async (billingId) => {
  try {
    const response = await axiosInstance.delete(`/api/billings/${billingId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting billing record by ID:", error);
    throw error;
  }
};


//Invoices
// Create a new invoice
export const createInvoice = async (invoiceData) => {
  try {
    const response = await axiosInstance.post('/api/invoices', invoiceData);
    return response.data;
  } catch (error) {
    console.error("Error creating invoice:", error);
    throw error;
  }
};

// Fetch all invoices
export const fetchAllInvoices = async () => {
  try {
    const response = await axiosInstance.get('/api/invoices');
    return response.data;
  } catch (error) {
    console.error("Error fetching all invoices:", error);
    throw error;
  }
};

// Fetch invoices by user ID
export const fetchInvoicesByUserId = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/invoices/user/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching invoices by user ID:", error);
    throw error;
  }
};

// Fetch invoice by ID
export const fetchInvoiceById = async (invoiceId) => {
  try {
    const response = await axiosInstance.get(`/api/invoices/${invoiceId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching invoice by ID:", error);
    throw error;
  }
};

// Delete an invoice by ID
export const deleteInvoiceById = async (invoiceId) => {
  try {
    const response = await axiosInstance.delete(`/api/invoices/${invoiceId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting invoice:", error);
    throw error;
  }
};


// Referral Code integration
export const fetchAllReferralCodes = async () => {
  try {
    const response = await axiosInstance.get('/api/referral-code/all');
    return response.data;
  } catch (error) {
    console.error("Error fetching all referral codes:", error);
    throw error;
  }
};

// Fetch referral code by UserID
export const fetchReferralCodesByUserId = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/referral-code/user/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching referral codes by User ID:", error);
    throw error;
  }
};

// Generate a referral code
export const generateReferralCode = async () => {
  try {
    const response = await axiosInstance.post('/api/referral-code/generate');
    return response.data;
  } catch (error) {
    console.error("Error generating referral code:", error);
    throw error;
  }
};

// Apply a referral code to get a discount on checkout
export const applyReferralCode = async (code, totalPrice, subscriptionType) => {
  try {
    const response = await axiosInstance.post('/api/referral-code/apply', { code, totalPrice, subscriptionType });
    return response.data;
  } catch (error) {
    console.error("Error applying referral code:", error);
    throw error;
  }
};

// Validate referral code without applying it
export const validateReferralCode = async (code, totalPrice, subscriptionType) => {
  try {
    const response = await axiosInstance.post(`/api/referral-code/validate`, { code, totalPrice, subscriptionType });
    return response.data;
  } catch (error) {
    console.error("Error validating referral code:", error);
    throw error;
  }
};


// Fetch messages for a specific team
export const fetchTeamMessages = async (teamId) => {
  try {
    const response = await axiosInstance.get(`/api/messages/teamMessages/team/${teamId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching team messages:", error);
    throw error;
  }
};

// Fetch all messages for teams assigned to a mentor
export const fetchMessagesForMentorTeams = async (mentorId) => {
  try {
    const response = await axiosInstance.get(`/api/messages/teamMessages/mentor/${mentorId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching messages for mentor's teams:", error);
    throw error;
  }
};

// Create a new team message
export const createTeamMessage = async (messageData) => {
  try {
    const response = await axiosInstance.post(`/api/messages/teamMessages`, messageData);
    return response.data;
  } catch (error) {
    console.error("Error creating team message:", error);
    throw error;
  }
};

// Delete a specific team message
export const deleteTeamMessage = async (messageId) => {
  try {
    const response = await axiosInstance.delete(`/api/messages/teamMessages/${messageId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting team message:", error);
    throw error;
  }
};

// Upload STL/OBJ model files
export const uploadModelFile = async (formData) => {
  try {
    const response = await axiosInstance.post('/api/messages/upload-model', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading model file:", error);
    throw error;
  }
};

// Upload image file
export const uploadImageFile = async (formData) => {
  try {
    const response = await axiosInstance.post('/api/messages/upload-image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading image file:", error);
    throw error;
  }
};

// Upload PDF or DOC file
export const uploadDocumentFile = async (formData) => {
  try {
    const response = await axiosInstance.post('/api/messages/upload-document', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading document file:", error);
    throw error;
  }
};

export const fetchUnreadTeamMessages = async (teamId, userId) => {
  try {
    const response = await axiosInstance.get(`/api/messages/teamMessages/${teamId}/unread`, {
      params: { userId }, // Include userId as a query parameter
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching unread team messages:", error);
    throw error;
  }
};

// Mark multiple team messages as read
export const markTeamMessagesAsRead = async (messageIds) => {
  try {
    const response = await axiosInstance.post(`/api/messages/teamMessages/markAsRead`, { messageIds });
    return response.data;
  } catch (error) {
    console.error("Error marking team messages as read:", error);
    throw error;
  }
};

//Timeline or Events

// Fetch all timelines by mentor ID
export const fetchTimelinesByMentor = async (mentorId) => {
  try {
    const response = await axiosInstance.get(`/api/timelines`, {
      params: { mentorId },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching timelines by mentor:", error);
    return [];
  }
};

// Fetch timelines for the logged-in user's mentor
export const fetchUserTimelines = async () => {
  try {
    const userProfile = await getUserProfile();
    const response = await axiosInstance.get(`/api/timelines/user/${userProfile.user.id}`);
    if (!response.data || response.data.length === 0) {
      console.warn("No timelines found for the user.");
      return [];
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching timelines for user:", error.response?.data || error.message);
    throw new Error("Failed to fetch timelines. Please try again later.");
  }
};

// Create a new timeline
export const createTimeline = async (timelineData) => {
  try {
    const response = await axiosInstance.post(`/api/timelines`, timelineData);
    return response.data;
  } catch (error) {
    console.error("Error creating timeline:", error);
    throw error;
  }
};

// Update an existing timeline
export const updateTimeline = async (timelineId, timelineData) => {
  try {
    const response = await axiosInstance.put(`/api/timelines/${timelineId}`, timelineData);
    return response.data;
  } catch (error) {
    console.error("Error updating timeline:", error);
    throw error;
  }
};

// Delete a timeline
export const deleteTimeline = async (timelineId) => {
  try {
    const response = await axiosInstance.delete(`/api/timelines/${timelineId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting timeline:", error);
    throw error;
  }
};


