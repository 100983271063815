import React from "react";
import CardMenu from "components/card/CardMenu";
import { MdOutlineSchool } from "react-icons/md";
import Transaction from "components/dataDisplay/Transaction";
import Card from "components/card";
import { useNavigate } from "react-router-dom";

function Balance({ transactions, subscriptions }) {
  const navigate = useNavigate();
  // Helper function to find the earliest expiration date
  const getEarliestExpiration = () => {
    if (!subscriptions || subscriptions.length === 0) return null;
    return subscriptions.reduce((earliest, current) => {
      return new Date(current.expirationDate) < new Date(earliest.expirationDate)
        ? current
        : earliest;
    }, subscriptions[0]);
  };

  const earliestSubscription = getEarliestExpiration();

  return (
    <Card extra={"px-4 py-6 w-full"}>
      {/* Time Remaining Section */}
      <div className="bg-image relative mt-[7px] flex h-fit w-full justify-between rounded-xl bg-brand-900 bg-ballanceDashboard bg-cover bg-right px-3 py-4 text-white dark:bg-navy-700">
        <div className="flex flex-col justify-center">
          <p className="text-sm font-medium">Time Remaining</p>
          {/* Display the earliest subscription's remaining time */}
          {earliestSubscription ? (
            <>
              <h4 className="mt-1 text-4xl font-bold">
                {earliestSubscription.remainingTime}
              </h4>
              <p className="mt-2 text-sm text-gray-300">
                Course:{" "}
                <span className="font-semibold">
                  {earliestSubscription.courseTitle}
                </span>
              </p>
            </>
          ) : (
            <div className="mt-2">
              <h4 className="text-2xl font-semibold">No Active Subscriptions</h4>
              <p className="mt-2 text-sm text-gray-300">
                You currently have no active subscriptions.{" "}
                  <button
                    onClick={() => navigate("/admin/courses")}
                    className="font-medium text-white underline hover:text-white/80"
                  >
                    Browse courses
                  </button>
                .
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-col items-end text-white">
          <CardMenu transparent />
        </div>
      </div>

      <h5 className="mt-5 text-sm font-medium text-gray-600">Recent Transactions</h5>

      {/* Recent Transactions Section */}
      <div className="mt-4">
        {transactions.length > 0 ? (
          transactions.slice(0, 2).map((transaction, index) => (
            <Transaction
              key={index}
              title={transaction.courseTitle || "Transaction"}
              date={new Date(transaction.paymentDate).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}
              sum={`-Ksh ${transaction.totalPaid}`}
              status={transaction.status}
              mb="mb-5"
              icon={
                <MdOutlineSchool className="text-brand-500 dark:text-white" />
              }
            />
          ))
        ) : (
          <p className="mt-4 text-center text-sm text-gray-500 dark:text-white">
            No recent transactions found.
          </p>
        )}
      </div>
    </Card>
  );
}

export default Balance;
