import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { confirmSubscriptionPayment } from "api";

// Import your custom alerts
import SuccessAlert from "components/alerts/SuccessAlerts";
import ErrorAlerts from "components/alerts/ErrorAlerts";

const PaymentConfirmation = ({ isOpen, onClose, subscriptionId }) => {
  const [referenceNumber, setReferenceNumber] = useState("");

  // Local states for success/error messages
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);

  // React Query mutation
  const {
    mutate: confirmPayment,
    isLoading,
    isError,
    error,
  } = useMutation({
    mutationFn: () => confirmSubscriptionPayment(subscriptionId, referenceNumber),
    onSuccess: () => {
      setSuccessMessage("Payment confirmed successfully!");
      setErrorMessages([]);
      // Optionally close the modal automatically, if desired:
      // onClose();
    },
    onError: (err) => {
      // If you receive a server message, handle it accordingly
      const errorMsg = err?.response?.data?.message || err.message || "Error confirming payment.";
      setErrorMessages([errorMsg]);
      setSuccessMessage("");
    },
  });

  // When user clicks "Confirm Payment"
  const handleConfirm = () => {
    if (referenceNumber.trim()) {
      confirmPayment();
    } else {
      // If you want to show an ErrorAlert here too, you can:
      setErrorMessages(["Please enter a reference number"]);
    }
  };

  // Clear reference number when modal opens
  useEffect(() => {
    if (isOpen) {
      setReferenceNumber("");
      setErrorMessages([]);
      setSuccessMessage("");
    }
  }, [isOpen]);

  // If not open, render nothing
  if (!isOpen) return null;

  // Dismiss success alert
  const handleCloseSuccess = () => {
    setSuccessMessage("");
    // Optionally close the modal too
    // onClose();
  };

  // Dismiss error alert(s)
  const handleCloseError = () => {
    setErrorMessages([]);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      {/* Modal Container */}
      <div className="w-80 rounded-lg bg-white p-6 shadow-lg">
        {/* Success Alert */}
        {successMessage && (
          <SuccessAlert message={successMessage} onClose={handleCloseSuccess} />
        )}

        {/* Error Alerts */}
        {errorMessages.length > 0 && (
          <ErrorAlerts errors={errorMessages} onClose={handleCloseError} />
        )}

        <h2 className="mb-4 text-xl font-bold">Confirm Payment</h2>

        <input
          type="text"
          placeholder="Enter Mpesa Reference Number"
          value={referenceNumber}
          onChange={(e) => setReferenceNumber(e.target.value)}
          className="mb-4 w-full rounded border border-gray-300 p-2"
        />

        {/* If using isError from React Query and not handled above, you can show something else:
            {isError && !errorMessages.length && (
              <p className="mb-2 text-red-500">Error: {error.message}</p>
            )}
        */}

        <button
          onClick={handleConfirm}
          disabled={isLoading}
          className="w-full rounded bg-orange-500 p-2 text-white transition-colors duration-200 hover:bg-brand-900"
        >
          {isLoading ? "Confirming..." : "Confirm Payment"}
        </button>

        <button
          onClick={onClose}
          className="mt-2 w-full text-center text-dark-500"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
