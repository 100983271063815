// OrderList.js

import { useQuery } from '@tanstack/react-query';
import { fetchAllCourseSubscriptions } from 'api';
import { columnsDataOrders } from './variables/columnsDataOrders';
import SearchTableOrders from './components/SearchTableOrders';
import Card from 'components/card';

const OrderList = () => {
  const {
    data: subscriptions = [],
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ['courseSubscriptions'],
    queryFn: fetchAllCourseSubscriptions,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  // Include all subscriptions
  const allSubscriptions = subscriptions;

  const tableData = allSubscriptions.map((sub) => ({
    id: sub.id,
    userId: sub.user.id, // Add userId
    name: sub.course.title, // Course name
    email: sub.user.email,
    price: `Kshs. ${sub.billing?.amount || '0.00'}`, // Price
    date: new Date(sub.createdAt).toLocaleDateString(),
    status: sub.status === 'active' ? 'Completed' : 'In Progress',
    actions: 'Actions',
  }));

  return (
    <Card extra="w-full h-full bg-white mt-3">
      <SearchTableOrders
        tableData={tableData}
        columnsData={columnsDataOrders}
      />
    </Card>
  );
};

export default OrderList;
