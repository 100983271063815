import React, { useState } from "react";
import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import { resetPassword } from "api";
import { useSearchParams, useNavigate } from "react-router-dom"; // For navigation
import { FaEye, FaEyeSlash, FaSpinner } from "react-icons/fa"; // Spinner for loading

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [searchParams] = useSearchParams();
  const navigate = useNavigate(); // Navigation hook

  // Password validation function
  const isPasswordValid = (password) => password.length >= 8;

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!isPasswordValid(newPassword)) {
      setError("Password must be at least 8 characters long.");
      setMessage("");
      document.getElementById("newPassword").focus(); // Focus back to password field
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      setMessage("");
      document.getElementById("confirmPassword").focus(); // Focus on confirm password field
      return;
    }

    setLoading(true); // Start loading spinner

    try {
      const token = searchParams.get("token");
      const response = await resetPassword(token, newPassword);
      setMessage(response.message);
      setError("");

      // Redirect to login page after success
      setTimeout(() => {
        navigate("/auth/sign-in");
      }, 3000);
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred.");
      setMessage("");
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  // Clear errors when either password field changes
  const handlePasswordChange = (setter) => (e) => {
    setter(e.target.value);
    setError(""); // Clear error
    setMessage(""); // Clear success message
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          <div className="mt-[16vh] flex flex-col rounded-[20px] pb-16 pt-12 md:pr-6 lg:max-w-[90%] lg:pr-0 xl:max-w-[42%] xl:pr-24">
            <h2 className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
              Reset Your Password
            </h2>
            <p className="mt-[10px] ml-1 mb-8 text-base text-gray-600 dark:text-gray-300">
              Enter your new password below and confirm it to reset your password.
            </p>

            {/* Success Message */}
            {message && (
              <p
                className="text-green-500 mb-4"
                role="alert"
                aria-live="polite"
              >
                {message}
              </p>
            )}

            {/* Error Message */}
            {error && (
              <p
                className="text-red-500 mb-4"
                role="alert"
                aria-live="assertive"
              >
                {error}
              </p>
            )}

            {/* Form */}
            <form onSubmit={handleSubmit} className="space-y-6">
              <InputField
                variant="auth"
                label="New Password"
                placeholder="Enter your new password"
                id="newPassword"
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={handlePasswordChange(setNewPassword)}
                icon={showPassword ? <FaEye /> : <FaEyeSlash />}
                onIconClick={toggleShowPassword}
              />
              <InputField
                variant="auth"
                label="Confirm Password"
                placeholder="Confirm your new password"
                id="confirmPassword"
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handlePasswordChange(setConfirmPassword)}
                icon={showPassword ? <FaEye /> : <FaEyeSlash />}
                onIconClick={toggleShowPassword}
              />
              <button
                type="submit" // Ensures the form behaves properly
                className={`mt-4 w-full rounded-xl py-3 text-base font-medium text-white transition duration-200 ${
                  newPassword &&
                  confirmPassword &&
                  isPasswordValid(newPassword) &&
                  newPassword === confirmPassword &&
                  !loading
                    ? "bg-orange-400 hover:bg-brand-900 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    : "bg-gray-300 cursor-not-allowed"
                }`}
                disabled={
                  !newPassword ||
                  !confirmPassword ||
                  !isPasswordValid(newPassword) ||
                  newPassword !== confirmPassword ||
                  loading
                }
                aria-busy={loading} // Accessibility for loaders
              >
                {loading ? ( // Show spinner when loading
                  <span className="flex items-center justify-center">
                    <FaSpinner className="animate-spin mr-2" /> Processing...
                  </span>
                ) : (
                  "Reset Password"
                )}
              </button>
            </form>
          </div>
        </div>
      }
    />
  );
}

export default ResetPassword;
