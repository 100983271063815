import React from "react";
import Card from "components/card";

function YourTransfers({ appliedCoupons = [] }) {
  // Filter for expired and active coupons based on the `isActive` property
  const expiredCoupons = appliedCoupons.filter((coupon) => !coupon.isActive);
  const activeCoupons = appliedCoupons.filter((coupon) => coupon.isActive);

  return (
    <Card extra="px-9 w-full h-full pt-[28px] pb-4 flex flex-col justify-between">
      <h4 className="mb-8 mt-3 text-lg font-bold text-navy-700 dark:text-white">
        Applied Coupons
      </h4>

      <div className={`${expiredCoupons.length === 0 && activeCoupons.length === 0 ? "flex flex-grow items-center justify-center" : ""}`}>
        {expiredCoupons.length === 0 && activeCoupons.length === 0 ? (
          <p className="text-center text-gray-500 dark:text-gray-400">
            No coupons available.
          </p>
        ) : (
          <>
            {/* Map over expired coupons */}
            {expiredCoupons.map((coupon, index) => (
              <div key={index} className="flex justify-between items-center mb-[42px]">
                <div>
                  <p className="font-semibold text-navy-700 dark:text-white">
                    {coupon.title}
                  </p>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    Expires: {coupon.expires}
                  </p>
                </div>
                <span className="bg-red-100 text-red-600 px-3 py-1 rounded-full text-sm font-medium border border-red-400">
                  Expired
                </span>
              </div>
            ))}
            {/* Map over active coupons */}
            {activeCoupons.map((coupon, index) => (
              <div key={index} className="flex justify-between items-center mb-[42px]">
                <div>
                  <p className="font-semibold text-navy-700 dark:text-white">
                    {coupon.title}
                  </p>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    Expires: {coupon.expires}
                  </p>
                </div>
                <span className="bg-green-100 text-green-600 px-3 py-1 rounded-full text-sm font-medium border border-green-400">
                  Active
                </span>
              </div>
            ))}
          </>
        )}
      </div>

      <div className="ml-auto mt-[42px] mb-[10px] flex w-max items-center gap-1">
        <div className="text-base font-bold text-brand-500 transition-all hover:-translate-x-1 hover:cursor-pointer dark:text-white">
          See all coupons
        </div>
      </div>
    </Card>
  );
}

export default YourTransfers;
