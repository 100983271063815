import React, { useEffect, useState } from 'react';
import { fetchTeamRankings } from 'api';
import { FaMedal } from 'react-icons/fa'; // For illustrative icon
import { useNavigate } from 'react-router-dom';

const TeamRanking = () => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate(); // To handle navigation

  useEffect(() => {
    const loadTeams = async () => {
      try {
        const fetchedTeams = await fetchTeamRankings();
        setTeams(fetchedTeams);
      } catch (error) {
        setError('Error fetching team rankings');
      } finally {
        setLoading(false);
      }
    };

    loadTeams();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="text-lg font-semibold text-gray-700 dark:text-gray-300">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="text-lg font-semibold text-red-500">{error}</div>
      </div>
    );
  }

  if (teams.length === 0) {
    return (
      <div className="flex flex-col items-center p-6 bg-white dark:bg-navy-800 rounded-lg shadow-lg dark:shadow-none">
        <FaMedal className="text-6xl text-orange-500 mb-4" />
        <h2 className="mb-2 text-2xl font-bold text-center text-gray-800 dark:text-white">
          No Teams Ranked Yet
        </h2>
        <p className="text-center text-gray-600 dark:text-gray-300 mb-6">
          Be the first to create a team and start earning points to appear on the leaderboard!
        </p>
        <button
          className="px-4 py-2 text-md rounded-full bg-gradient-to-r from-orange-400 to-brand-900 hover:from-brand-900 hover:to-orange-400 text-white font-semibold shadow-lg transition-all duration-200 transform hover:scale-105"
          onClick={() => navigate('/admin/teams')}
        >
          Create a Team
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center p-4 md:p-6 bg-white dark:bg-navy-800 rounded-lg shadow-lg dark:shadow-none">
      <h2 className="mb-4 text-xl md:text-2xl font-bold text-center text-navy-700 dark:text-white">
        Leaderboard
      </h2>
      <div className="w-full">
        <ul className="w-full">
          {teams.map((team, index) => (
            <li
              key={team.id}
              className={`flex items-center justify-between border-b dark:border-gray-600 px-4 py-3 ${
                index % 2 ? 'bg-gray-50 dark:bg-navy-700' : 'bg-white dark:bg-navy-800'
              }`}
            >
              <div className="flex items-center gap-3">
                <span className="text-sm md:text-base font-medium text-gray-900 dark:text-white">
                  #{index + 1}
                </span>
                <span className="text-sm md:text-base font-medium text-gray-900 dark:text-white">
                  {team.name}
                </span>
              </div>
              <span className="text-sm md:text-base font-medium text-gray-900 dark:text-white">
                {team.points} pts
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TeamRanking;
