// Nav.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../../assets/img/dashboards/Logo iForge-8.png";
import { FaRegUser } from 'react-icons/fa';

const Nav = () => {
  return (
    <nav className="flex justify-between px-20 py-10 items-center bg-white">
      {/* Logo / Brand Name */}
        <div className="flex items-center">
          <Link to="/">
            <img 
              src={logo} 
              alt="iForge Logo" 
              className="h-10 w-auto"
            />
          </Link>
        </div>

      {/* Search and Navigation Links */}
      <div className="flex items-center space-x-6">
        {/* Search Bar */}
        <div className="flex items-center">
          {/* Search Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 pt-0.5 text-gray-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
          {/* Search Input */}
          <input
            className="ml-2 outline-none bg-transparent font-medium text-gray-700"
            type="text"
            name="search"
            id="search"
            placeholder="Search..."
          />
        </div>

        {/* Navigation Links */}
        <ul className="flex items-center space-x-6">
          {/* Home Link */}
          <li className="font-semibold text-xl text-brand-900">
            <Link to="/admin/dashboard">Home</Link>
          </li>

          {/* Articles Link */}
          <li className="font-semibold text-xl text-brand-900">
            <Link to="https://store.innovatorsforge.com">Shop</Link>
          </li>

          {/* Courses Icon */}
          <li>
            <Link to="/admin/courses">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-brand-900 hover:text-gray-800"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M12 14l9-5-9-5-9 5 9 5z"
                />
                <path
                  d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                />
              </svg>
            </Link>
          </li>

          {/* Cart Icon */}
          <li>
            <Link to="/cart">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-brand-900 hover:text-gray-800"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </Link>
          </li>

          {/* Profile Icon */}
          <li>
            <Link to="/admin/settings">
              <FaRegUser className="h-5 w-5 text-brand-900 hover:text-gray-800" />
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
