import Card from "components/card";
import InputField from "components/fields/InputField";
import TagsField from "components/fields/TagsField";

const Details = () => {
  return (
    <Card extra={"!z-1 w-full h-fit py-[20px] px-[20px]"}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          POAP Categories and Steps
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Configure the categories and steps involved in the POAP.
        </p>
      </div>
      {/* Inputs */}
      <div className="mt-10 grid h-full w-full grid-cols-1 gap-3 md:grid-cols-2">
        <div className="grid h-fit grid-cols-2 gap-3">
          <InputField
            label="Category Name"
            placeholder="e.g. Team Formation"
            id="categoryName"
            type="text"
          />
          <InputField
            label="Step Description"
            placeholder="e.g. Complete team setup"
            id="stepDescription"
            type="text"
          />
        </div>
        <div className="col-span-1 row-span-2 h-full">
          <TagsField
            id="tags"
            label="Tags"
            mb="0px"
            h="150px"
            placeholderTags={[
              { name: "team", id: 1 },
              { name: "formation", id: 2 },
              { name: "setup", id: 3 },
            ]}
          />
        </div>
      </div>
    </Card>
  );
};

export default Details;
