import React, {useEffect, useState}from "react";
import { MdModeEditOutline } from "react-icons/md";
import image1 from "assets/img/profile/image1.png";
import image2 from "assets/img/profile/image2.png";
import image3 from "assets/img/profile/image3.png";
import image4 from "assets/img/profile/image4.png";
import Card from "components/card";
import { fetchCurrentDescription, getUserProfile } from "api";

const Project = () => {
  const [projects, setProjects] = useState([
    {
      image: image1,
      title: "Team Formation",
      descriptionId: 5,
      status: "not started",
    },
    {
      image: image2,
      title: "3D Design",
      descriptionId: 6,
      status: "not started",
    },
    {
      image: image3,
      title: "3D Printers",
      descriptionId: 7,
      status: "not started",
    },
    {
      image: image4,
      title: "CAD Design",
      descriptionId: 8,
      status: "not started",
    },
  ]);

  useEffect(() => {
    const fetchProjectsStatus = async () => {
      try {
        const userProfile = await getUserProfile();
        const teamId = userProfile.user.team.id;
        const currentDescription = await fetchCurrentDescription(teamId);
        const currentDescriptionId = currentDescription.descriptionId;

        const updatedProjects = projects.map((project) => {
          if (project.descriptionId < currentDescriptionId) {
            return { ...project, status: "Completed" };
          } else if (project.descriptionId === currentDescriptionId) {
            return { ...project, status: "In Progress" };
          } else {
            return { ...project, status: "not started" };
          }
        });       
        setProjects(updatedProjects);
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchProjectsStatus();
  }, []);

  const statusColors = {
    "Completed": "bg-green-100 text-green-800",
    "In Progress": "bg-yellow-100 text-yellow-800",
    "not started": "bg-gray-100 text-gray-800",
  };

  return (
    <Card extra={"w-full p-4 h-full"}>
      <div className="mb-8 w-full">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          All Projects
        </p>
        <p className="mt-2 text-base text-gray-600">
        Explore detailed information about your projects and stay updated on your progress.
        </p>
      </div>
      <div className="h-[300px] overflow-y-auto pr-2">
        {projects.map((project, index) => (
          <div
            key={index}
            className="mt-3 flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-2xl shadow-gray-200 dark:bg-navy-700 dark:shadow-none"
          >
            <div className="flex items-center">
              <div className="">
                <img className="h-[83px] w-[83px] rounded-lg" src={project.image} alt={project.title} />
              </div>
              <div className="ml-4">
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {project.title}
                </p>
                <span className={`inline-block px-2 py-1 mt-2 text-sm font-medium rounded-lg ${statusColors[project.status]}`}>
                  {project.status}
                </span>
              </div>
            </div>
            <div className="mr-4 flex items-center justify-center text-gray-600 dark:text-white">
              <MdModeEditOutline />
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default Project;
