import React, { useEffect, useState } from 'react';
import MiniStatistics from "./components/MiniStatistics";
import { MdOutlineBarChart, MdPerson, MdFileCopy, MdSchool } from "react-icons/md";
import UserReportsTable from "./components/UserReportsTable";
import { columnsDataUserReports } from "./variables/columnsDataUserReports";
import { getUserProfile, fetchTeamMembers, fetchTeamRankings, fetchCurrentDescription } from "api";
import Loader from 'components/loaders/Loader';

const Application = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const [userProfile, setUserProfile] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [rankedTeams, setRankedTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [descriptionId, setDescriptionId] = useState(null);

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const profile = await getUserProfile();
        const teamId = profile.user.team?.id;
  
        if (teamId) {
          let members = await fetchTeamMembers(teamId);
          members = members.filter(member => member.id !== profile.user.id);
  
          const formattedMembers = members.map(member => ({
            name: member.username || 'Unknown',
            email: member.email || 'No Email',
            teamRole: member.teamRole || 'N/A',
            status: member.status || 'Offline',
            imageUrl: member.imageUrl
          }));
  
          setTeamMembers(formattedMembers);
          setSelectedTeam(profile.user.team);
  
          const { descriptionId, projectName } = await fetchCurrentDescription(teamId);
          setSelectedTeam(prevTeam => ({
            ...prevTeam,
            currentProject: projectName || 'No Project Assigned'
          }));
          setDescriptionId(descriptionId);
        }
  
        const teams = await fetchTeamRankings();
        setRankedTeams(teams);
        setUserProfile(profile);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Unable to fetch some data, displaying available information.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  const schoolName = userProfile?.user?.school || 'School Information Not Available';
  const mentorName = userProfile?.user?.mentor 
    ? `${userProfile.user.mentor.firstName} ${userProfile.user.mentor.lastName}` 
    : 'No Mentor Assigned';

  // Calculate team rank dynamically
  const getOrdinalSuffix = (rank) => {
    if (!rank || rank < 1) return "Not Ranked";
    const j = rank % 10,
          k = rank % 100;
    if (j === 1 && k !== 11) return `${rank}st`;
    if (j === 2 && k !== 12) return `${rank}nd`;
    if (j === 3 && k !== 13) return `${rank}rd`;
    return `${rank}th`;
  };
  
  const teamRank = selectedTeam 
    ? getOrdinalSuffix(rankedTeams.findIndex(team => team.id === selectedTeam.id) + 1)
    : "Not Ranked";

  if (loading) return <Loader />;
  
  return (
    <div className="mt-3 h-full w-full rounded-[20px]">
      {error && <p className="text-center text-red-500">{error}</p>}
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 3xl:grid-cols-4">
        <div>
          <MiniStatistics
            icon={<MdOutlineBarChart className="text-4xl" />}
            title="Team Rank"
            value={selectedTeam ? teamRank : "No Team Assigned"}
            growth={`${selectedTeam ? selectedTeam.points : 0} Club Points`}
            growthColor="text-green-500"
            cardBg="bg-gradient-to-r from-orange-400 to-orange-600"
            titleColor="text-white"
            valueColor="text-white"
            detailColor="text-white"
            iconColor="text-white"
          />
        </div>
        <div>
          <MiniStatistics
            icon={<MdFileCopy className="text-4xl" />}
            title="Current Project"
            value={selectedTeam ? selectedTeam.currentProject : "Project Not Available"}
            bgColor="bg-white dark:!bg-navy-800"
            growth={`Phase ${descriptionId || "N/A"}`}
            growthColor="text-blue-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500"
          />
        </div>
        <div>
          <MiniStatistics
            icon={<MdSchool className="text-4xl" />}
            title="School Name"
            value={schoolName}
            bgColor="bg-lightPrimary dark:!bg-navy-700"
            growth="Class of 2024"
            growthColor="text-purple-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500 dark:text-white"
          />
        </div>
        <div>
          <MiniStatistics
            icon={<MdPerson className="text-4xl" />}
            title="Mentor"
            value={mentorName}
            bgColor="bg-lightPrimary dark:bg-navy-700"
            growth="Specialization: 3D Printing"
            growthColor="text-red-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500 dark:text-white"
          />
        </div>
      </div>
      <div className="mt-3">
        <UserReportsTable
          tableData={teamMembers.length > 0 ? teamMembers : [{ name: 'No team members found', email: 'N/A', teamRole: 'N/A', status: 'N/A' }]}
          columnsData={columnsDataUserReports}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          selectedTeam={selectedTeam}
          userProfile={userProfile}
        />
      </div>
    </div>
  );
};

export default Application;
