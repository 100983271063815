import React, { useEffect, useState } from 'react';
import { FaTrophy, FaMedal, FaStar, FaRegSmileBeam } from 'react-icons/fa'; 
import { fetchTopAchievements } from 'api';

const iconsMap = {
  trophy: <FaTrophy className='text-red-500' />,
  medal: <FaMedal className='text-blue-500' />,
  star: <FaStar className='text-green-500' />
};

const AchievementHighlights = () => {
  const [achievements, setAchievements] = useState([]);

  useEffect(() => {
    const fetchAchievements = async () => {
      try {
        const data = await fetchTopAchievements();
        setAchievements(Array.isArray(data) ? data : []); // Ensure `achievements` is always an array
      } catch (error) {
        console.error("Error fetching achievements:", error);
        setAchievements([]); 
      }
    };

    fetchAchievements();
  }, []);

  return (
    <div className="mt-10 p-4 bg-white rounded-lg shadow-lg dark:bg-navy-800 dark:shadow-none">
      <h3 className="text-lg font-bold text-center text-gray-800 dark:text-white mb-4">Achievement Highlights</h3>
      <div className="flex flex-col items-center">
        {achievements.length > 0 ? (
          achievements.map((item, index) => (
            <div key={index} className="flex items-center w-full p-2 my-1 bg-gray-100 rounded-md shadow dark:bg-navy-700 dark:shadow-none">
              <div className="p-3 rounded-full bg-opacity-20 text-xl dark:bg-opacity-20 dark:bg-gray-500">
                {iconsMap[item.icon] || iconsMap.trophy /* Default to trophy if icon not found */}
              </div>
              <div className="ml-4 text-gray-700 dark:text-gray-300">
                <p className="text-sm font-bold dark:text-white">{item.team}</p>
                <p className="text-xs">{item.achievement}</p>
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center mt-4">
            <FaRegSmileBeam className="text-5xl text-gray-400 mb-2" />
            <p className="text-gray-600 dark:text-gray-400 text-center">
            No achievements recorded yet. Get started and showcase your team's potential!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default AchievementHighlights;
