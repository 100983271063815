import { useState } from "react";
import Card from "components/card";
import MasterCardIcon from "components/icons/MasterCardIcon";
import MpesaIcon from "components/icons/MpesaIcon";
import { MdEdit } from "react-icons/md";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUser } from "api";

const PaymentMethod = ({ userId, initialMpesaNumber }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [mpesaNumber, setMpesaNumber] = useState(initialMpesaNumber || "");
  const [originalMpesaNumber, setOriginalMpesaNumber] = useState(initialMpesaNumber || "");
  const [error, setError] = useState("");

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (updatedData) => updateUser(userId, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries(["userProfile"]);
      setIsEditing(false);
    },
  });

  const handleSave = () => {
    if (!/^\+?\d{10,15}$/.test(mpesaNumber)) {
      setError("Please enter a valid phone number");
      return;
    }
    setError("");
    mutation.mutate({ phoneNumber: mpesaNumber });
  };

  const handleCancel = () => {
    setMpesaNumber(originalMpesaNumber);
    setError("");
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^[\d+]*$/.test(value)) {
      setMpesaNumber(value);
    }
  };

  const boxClass =
    "flex items-center justify-between rounded-xl border-2 border-gray-200 px-4 py-[21px] dark:!border-white/10";

  return (
    <Card extra={"w-full px-[33px] h-fit 2xl:h-[290px] py-3 mt-3"}>
      <h4 className="mt-3 ml-1 font-dm text-lg font-bold text-navy-700 dark:text-white">
        Payment Method
      </h4>

      {/* MasterCard */}
      <div className={`${boxClass} mt-[28px]`}>
        <div className="flex items-center gap-2">
          <MasterCardIcon />
          <h4 className="font-dm text-gray-700 dark:text-white ml-2">
            7812 2139 0823 XXXX
          </h4>
        </div>
        <div className="text-base text-gray-700 hover:cursor-pointer dark:text-white">
          <MdEdit className="h-5 w-5" />
        </div>
      </div>

      {/* Mpesa */}
      <div className={`${boxClass} mt-[22px]`}>
        <div className="flex items-center gap-2 text-brand-500 dark:text-white">
          <MpesaIcon />
          {isEditing ? (
            <input
              type="text"
              value={mpesaNumber}
              onChange={handleInputChange}
              className="rounded-lg border border-gray-300 px-2 py-1 text-sm text-gray-700 dark:text-white focus:outline-none"
              placeholder="Enter phone number"
            />
          ) : (
            <h6 className="font-dm text-gray-700 dark:text-white">
              {mpesaNumber || "No phone number set"}
            </h6>
          )}
        </div>
        <div className="flex items-center gap-2">
          {isEditing ? (
            <>
              <button className="text-sm text-green-500" onClick={handleSave}>
                Save
              </button>
              <button className="text-sm text-red-500" onClick={handleCancel}>
                Cancel
              </button>
            </>
          ) : (
            <MdEdit
              className="h-5 w-5 text-gray-700 dark:text-white hover:cursor-pointer"
              onClick={() => setIsEditing(true)}
            />
          )}
        </div>
      </div>

      {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
    </Card>
  );
};

export default PaymentMethod;
