import React from 'react';
import Card from '../../components/Card';
import styles from '../../components/CourseContent.module.css'

const CardsWrapper = ({ section }) => {
  const courses = [
    {
      number: 1,
      title: 'The 3D Printing Industry',
      description: 'Placeholder description for The 3D Printing Industry.',
      date: '1 Jan 2024',
      tags: ['3D Printing'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/html-syntax/cover.jpg',
      href: 'https://weareprintlab.com/s/sgdnwg/',
    },
    {
      number: 2,
      title: '3D Printing Technologies',
      description: 'Placeholder description for 3D Printing Technologies.',
      date: '2 Jan 2024',
      tags: ['3D Printing'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/basic-types-of-html-tags/cover.jpg',
      href: 'https://weareprintlab.com/s/mmp7q7/',
    },
    {
      number: 3,
      title: 'Slicing for 3D Printing',
      description: 'Placeholder description for Slicing for 3D Printing.',
      date: '3 Jan 2024',
      tags: ['3D Printing'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/links-images-about-file-paths/cover.jpg',
      href: 'https://weareprintlab.com/s/shwlwv/',
    },
    {
      number: 4,
      title: 'Intro to 3D CAD with Tinkercad',
      description: 'Placeholder description for Intro to 3D CAD with Tinkercad.',
      date: '4 Jan 2024',
      tags: ['3D CAD'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/html-syntax/cover.jpg',
      href: 'https://weareprintlab.com/s/dwlwvp/',
    },
    {
      number: 5,
      title: 'Tinkercad to Fusion',
      description: 'Placeholder description for Tinkercad to Fusion.',
      date: '5 Jan 2024',
      tags: ['Fusion 360'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/basic-types-of-html-tags/cover.jpg',
      href: 'https://weareprintlab.com/s/eap2qa/',
    },
    {
      number: 6,
      title: 'Intro to 3D CAD with Fusion',
      description: 'Placeholder description for Intro to 3D CAD with Fusion.',
      date: '6 Jan 2024',
      tags: ['Fusion 360'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/links-images-about-file-paths/cover.jpg',
      href: 'https://weareprintlab.com/s/vhuenf/',
    },
    {
      number: 7,
      title: 'Designing for 3D Printing',
      description: 'Placeholder description for Designing for 3D Printing.',
      date: '7 Jan 2024',
      tags: ['3D Design'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/html-syntax/cover.jpg',
      href: 'https://weareprintlab.com/s/zuaf6s/',
    },
    {
      number: 8,
      title: 'Design Methods',
      description: 'Placeholder description for Design Methods.',
      date: '8 Jan 2024',
      tags: ['Design'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/basic-types-of-html-tags/cover.jpg',
      href: 'https://weareprintlab.com/s/rvge60/',
    },
    {
      number: 9,
      title: 'Mechanism Cubes',
      description: 'Placeholder description for Mechanism Cubes.',
      date: '9 Jan 2024',
      tags: ['Mechanisms'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/links-images-about-file-paths/cover.jpg',
      href: 'https://weareprintlab.com/s/5wsuze/',
    },
    {
      number: 10,
      title: 'Assistive Bottle Openers',
      description: 'Placeholder description for Assistive Bottle Openers.',
      date: '10 Jan 2024',
      tags: ['Assistive Design'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/html-syntax/cover.jpg',
      href: 'https://weareprintlab.com/s/hkv8at/',
    },
    {
      number: 11,
      title: 'Micro:bit Switch Access',
      description: 'Placeholder description for Micro:bit Switch Access.',
      date: '11 Jan 2024',
      tags: ['Micro:bit'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/basic-types-of-html-tags/cover.jpg',
      href: 'https://weareprintlab.com/s/xlx6ju/',
    },
    {
      number: 12,
      title: 'Assistive Clips',
      description: 'Placeholder description for Assistive Clips.',
      date: '12 Jan 2024',
      tags: ['Assistive Design'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/links-images-about-file-paths/cover.jpg',
      href: 'https://weareprintlab.com/s/2ygxaa/',
    },
    {
      number: 13,
      title: 'Personalized Links',
      description: 'Placeholder description for Personalized Links.',
      date: '13 Jan 2024',
      tags: ['Personalization'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/html-syntax/cover.jpg',
      href: 'https://weareprintlab.com/s/ammh7w/',
    },
    {
      number: 14,
      title: '3D Printed Typography',
      description: 'Placeholder description for 3D Printed Typography.',
      date: '14 Jan 2024',
      tags: ['Typography'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/basic-types-of-html-tags/cover.jpg',
      href: 'https://weareprintlab.com/s/eertq8/',
    },
    {
      number: 15,
      title: 'Rethinking Plastics',
      description: 'Placeholder description for Rethinking Plastics.',
      date: '15 Jan 2024',
      tags: ['Plastics'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/links-images-about-file-paths/cover.jpg',
      href: 'https://weareprintlab.com/s/k6nkxs/',
    },
    {
      number: 16,
      title: 'Functional Wrenches',
      description: 'Placeholder description for Functional Wrenches.',
      date: '16 Jan 2024',
      tags: ['Functional'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/html-syntax/cover.jpg',
      href: 'https://weareprintlab.com/s/ycyns8/',
    },
    {
      number: 17,
      title: 'Self Watering Planters',
      description: 'Placeholder description for Self Watering Planters.',
      date: '17 Jan 2024',
      tags: ['Planters'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/basic-types-of-html-tags/cover.jpg',
      href: 'https://weareprintlab.com/s/kjlagw/',
    },
    {
      number: 18,
      title: 'Suspension Bridge',
      description: 'Placeholder description for Suspension Bridge.',
      date: '18 Jan 2024',
      tags: ['Bridges'],
      bgImage: 'https://codetheweb.blog/assets/img/posts/links-images-about-file-paths/cover.jpg',
      href: 'https://weareprintlab.com/s/e3cwzm/',
    },
  ];
  
  const filteredCourses =
  section === 'miniCourses'
    ? courses.filter((course) => course.number < 9) // Mini courses: original numbering
    : courses
        .filter((course) => course.number >= 9) // Projects: filter items
        .map((course, index) => ({
          ...course,
          number: index + 1, // Reset numbering starting from 1
        }));

  return (
    <div className={styles.cardsWrapper}>
    {filteredCourses.map((course) => (
      <Card
        key={course.number}
        number={course.number}
        title={course.title}
        description={course.description}
        date={course.date}
        tags={course.tags}
        bgImage={course.bgImage}
        href={course.href}
      />
    ))}
  </div>
    
  );
};

export default CardsWrapper;
