import NftBanner2 from "assets/img/nfts/NftBanner2.png";
import NftProfile from "assets/img/nfts/NftProfile.png";
import { PiBooks, PiBooksThin } from "react-icons/pi";
import Card from "components/card";
import { IoBookOutline } from "react-icons/io5";
import { FcReadingEbook } from "react-icons/fc";

const Banner = () => {
  return (
    <div className="flex h-full w-full flex-col items-center rounded-[20px]">
      {/* Banner Background */}
      <div
        style={{ backgroundImage: `url(${NftBanner2})` }}
        className="relative flex h-[150px] w-full items-center justify-center rounded-[20px] bg-cover md:h-[120px] lg:h-[170px] 3xl:h-[220px]"
      >
        <div className="absolute -bottom-20 flex h-[180px] w-[180px] items-center justify-center rounded-full border-[10px] border-white bg-white dark:bg-navy-700 dark:!border-navy-900">
          <FcReadingEbook className="text-navy-700 h-[60%] w-[60%]" />
        </div>
      </div>

      {/* Profile Info */}
      <div className="mt-14 flex flex-col items-center">
        <h1 className="text-[55px] font-bold text-navy-700 dark:text-white">
          Courses
        </h1>
      </div>

      {/* Learn/Create/Innovate Card */}
      <Card
        extra={
          "grid grid-cols-4 dark:shadow-none w-full md:w-[800px] gap-4 py-5 px-3 mt-6 rounded-[20px]"
        }
      >
        <div className="flex flex-col items-center justify-center border-gray-200 py-3 dark:border-white/10 md:border-r-2">
          <h3 className="text-3xl font-bold text-navy-700 dark:text-white">18</h3>
          <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
            Modules
          </p>
        </div>

        <div className="flex flex-col items-center justify-center border-gray-200 py-3 dark:border-white/10 md:border-r-2">
          <h3 className="text-3xl font-bold text-navy-700 dark:text-white">4.8</h3>
          <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
            Rating
          </p>
        </div>

        <div className="flex flex-col items-center justify-center border-gray-200 py-3 dark:border-white/10 md:border-r-2">
          <h3 className="text-3xl font-bold text-navy-700 dark:text-white">80h</h3>
          <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
            Duration
          </p>
        </div>

        <div className="flex flex-col items-center justify-center py-3">
          <h3 className="text-3xl font-bold text-navy-700 dark:text-white">Yes</h3>
          <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
            Certification
          </p>
        </div>
      </Card>
    </div>
  );
};

export default Banner;
