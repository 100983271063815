const MiniStatistics = (props) => {
  const {
    bgColor,
    icon,
    title,
    value,
    cardBg,
    titleColor,
    valueColor,
    detailColor,
    iconColor,
    detailText,
  } = props;

  return (
    <div
      className={`flex ${cardBg} justify-between rounded-[20px] bg-clip-border p-6 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none mt-4 md:mt-0`}
    >
      <div>
        <h5 className={`font-dm text-sm font-medium ${titleColor}`}>
          {title}
        </h5>
        <p className={`font-dm text-2xl font-bold ${valueColor}`}>{value}</p>
        {detailText && (
          <p className={`mt-1 text-sm font-medium text-gray-600 ${detailColor}`}>
            {detailText}
          </p>
        )}
      </div>

      <div
        className={`flex items-center ${bgColor} h-20 w-20 justify-center rounded-full ${iconColor}`}
      >
        {icon}
      </div>
    </div>
  );
};

export default MiniStatistics;
