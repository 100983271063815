import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdEdit } from "react-icons/md";
import Card from "components/card";
import EditUserModal from "../components/functions/EditUserModal";

const ManagementTable = (props) => {
  const { columnsData, tableData } = props;
  const [editUser, setEditUser] = useState(null);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Start on the first page
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = tableInstance;

  const handleEditUser = (user) => {
    setEditUser(user);
  };

  const closeEditModal = () => {
    setEditUser(null);
  };

  const defaultImageUrl = "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";

  return (
    <Card extra={"w-full px-9 py-3 h-full"}>
      <div className="mt-2 flex w-full items-center justify-between px-1">
        <h4 className="font-dm text-xl font-bold text-navy-700 dark:text-white">
          User Management
        </h4>
      </div>
      <div className="mt-[25px] overflow-x-scroll 2xl:overflow-x-hidden">
        <table {...getTableProps()} className="w-full font-dm text-navy-700">
          <thead className="w-full">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="items-center border-b border-gray-200 text-base dark:border-white/10"
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="pb-[15px] pl-[5px] text-start text-sm font-bold uppercase tracking-wide text-gray-600"
                    key={index}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="w-full">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index} className="items-center">
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Name") {
                      const imageUrl = cell.value[2] || defaultImageUrl;
                      data = (
                        <div className="flex items-center gap-2 pr-16 sm:min-w-[120px] md:min-w-[200px] lg:w-full lg:pr-0">
                          <img
                            className="h-9 w-9 rounded-xl"
                            src={imageUrl}
                            alt=""
                          />
                          <div className="flex w-full flex-col">
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value[0]}
                            </p>
                            <p className="text-sm font-medium text-gray-600">
                              {cell.value[1]}
                            </p>
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === "Team") {
                      data = (
                        <div className="w-full text-sm font-bold dark:text-white sm:min-w-[100px] lg:min-w-[unset]">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "Role") {
                      data = (
                        <div className="w-full text-sm font-bold dark:text-white sm:min-w-[120px] md:min-w-[150px] lg:min-w-[unset]">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "Status") {
                      data = (
                        <div className="sm:min-w-[120px] md:min-w-[150px] lg:min-w-[unset]">
                          <div
                            className={`flex w-[95px] items-center justify-center rounded-[10px] py-1.5 text-sm font-bold uppercase ${
                              cell.value.toLowerCase() === "offline"
                                ? "bg-red-100 text-red-500 dark:bg-red-50"
                                : "bg-green-100 text-green-500 dark:bg-green-50"
                            }`}
                          >
                            {cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === "School") {
                      data = (
                        <div className="w-full text-sm font-bold dark:text-white sm:min-w-[100px] lg:min-w-[unset]">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "") {
                      data = (
                        <button
                          className="max-h-fit w-full max-w-max cursor-pointer text-xl text-gray-600"
                          onClick={() => handleEditUser(row.original)}
                        >
                          <MdEdit />
                        </button>
                      );
                    }
                    return (
                      <td
                        className="mt-[20px] py-[14px] pl-2 lg:pr-2"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      
      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="px-4 py-2 bg-orange-500 text-white rounded disabled:bg-gray-300"
        >
          Previous
        </button>
        <span className="text-navy-700 dark:text-white">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="px-4 py-2 bg-orange-500 text-white rounded disabled:bg-gray-300"
        >
          Next
        </button>
      </div>

      {editUser && <EditUserModal user={editUser} onClose={closeEditModal} />}
    </Card>
  );
};

export default ManagementTable;
