import React from 'react';
import { MdOutlineTimer, MdLockOutline } from "react-icons/md";

const Milestone = (props) => {
  const { image, title, isLocked, desc, day, date, topics, time, extra, onClick, resourceLink, videoLink } = props;

  return (
    <div
      className={`relative flex h-fit w-full flex-col rounded-[20px] bg-clip-border p-4 !pb-10 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none 2xl:flex-row 3xl:h-[310px] ${extra} hover:cursor-pointer hover:shadow-2xl hover:scale-105 transition-transform duration-300 ease-in-out`}
      onClick={onClick} // Applying the onClick here to make the whole card clickable
    >
      <div
        className={`relative mr-8 flex min-h-[200px] min-w-full items-center justify-center rounded-xl text-[100px] 2xl:h-[270px] 2xl:min-w-[270px]`}
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '20px', // Ensuring the rounded corners are applied
        }}
      >
        {/* Glassmorphic overlay and conditional padlock */}
        {isLocked && (
          <div className="absolute inset-0 bg-white/30 backdrop-blur-lg rounded-xl flex items-center justify-center">
            <MdLockOutline size={80} className="text-white opacity-80" />
          </div>
        )}
      </div>

      <div className="w-full justify-between pt-6 3xl:ml-8">
        <div>
          <div className="flex flex-col 2xl:flex-row">
            <div>
              <h2 className="font-bold leading-7 text-navy-700 dark:text-white md:text-[18px] 3xl:text-[28px] 3xl:leading-10">
                {title}
              </h2>
              <p className="mt-3 text-base text-gray-600">{desc}</p>
            </div>
            <div className="flex w-max justify-end gap-1 font-medium">
              <p className="mt-2 w-max text-gray-600"> {day} •</p>
              <p className="mt-2 w-max text-base text-navy-700 dark:text-white">
                {date}
              </p>
            </div>
          </div>

          <div className="mt-4 flex flex-col flex-wrap items-center gap-2 md:flex-row">
            {topics.map((topic, index) => (
              <div key={index} className="flex h-7 w-max items-center justify-center rounded-lg bg-lightPrimary px-2 text-xs font-bold uppercase tracking-wide text-brand-500 dark:bg-white/5 dark:text-white">
                {topic}
              </div>
            ))}
            <div className="flex items-center gap-1 text-sm font-medium text-navy-700 dark:text-white 2xl:ml-auto">
              <MdOutlineTimer />
              <p>{time}</p>
            </div>
          </div>
                    {/* Links Section */}
                    {!isLocked && (
            <div className="mt-4">
              {resourceLink && (
                <a
                  href={resourceLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline block"
                >
                  Resource Link
                </a>
              )}
              {videoLink && (
                <a
                  href={videoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline block mt-2"
                >
                  Video Link
                </a>
              )}
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default Milestone;
