import Card from "components/card";
import Dropdown from "components/dropdown";
import InputField from "components/fields/InputField";

const Socials = () => {
  return (
    <Card extra={"w-full px-[20px] py-[22px] h-full"}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          POAP Metadata
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Configure additional metadata for your POAP, such as categories or difficulty levels.
        </p>
      </div>
      {/* Inputs */}
      <div className="mt-7 grid grid-cols-1 gap-3">
        <InputField
          label="Category"
          placeholder="e.g., Education, Teamwork"
          id="category"
          type="text"
        />
        <Dropdown
          label="Difficulty Level"
          variant="manage" // use 'manage' variant if it provides the styling needed
          button={<span className="text-sm text-gray-600 dark:text-white">Select difficulty level</span>}
          classNames="w-full border rounded-xl border-gray-200 dark:border-white/10 bg-white dark:bg-navy-900"
        >
          {/* Dropdown options */}
          <div className="flex flex-col">
            <button className="text-left px-4 py-2 text-sm text-gray-600 dark:text-white hover:bg-gray-100 dark:hover:bg-navy-700">
              Beginner
            </button>
            <button className="text-left px-4 py-2 text-sm text-gray-600 dark:text-white hover:bg-gray-100 dark:hover:bg-navy-700">
              Intermediate
            </button>
            <button className="text-left px-4 py-2 text-sm text-gray-600 dark:text-white hover:bg-gray-100 dark:hover:bg-navy-700">
              Advanced
            </button>
          </div>
        </Dropdown>
        <InputField
          label="Tags"
          placeholder="e.g., Collaboration, Leadership"
          id="tags"
          type="text"
        />
        <InputField
          label="Additional Notes"
          placeholder="Any extra information"
          id="additionalNotes"
          type="text"
        />
      </div>
    </Card>
  );
};

export default Socials;
