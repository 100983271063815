import React from 'react';
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

function useOutsideAlerter(ref, setOpen) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setOpen]);
}

const DropdownForForm = ({
  label,
  options,
  selectedOption,
  onSelect,
  variant = 'auth',
  state,
  error,
}) => {
  const wrapperRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  useOutsideAlerter(wrapperRef, setOpen);

  return (
    <div className="relative mb-3">
      {label && (
        <label
          className={`block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1 ${
            variant === "auth" ? "ml-3 font-medium" : "ml-1.5 font-medium"
          }`}
        >
          {label}
        </label>
      )}
      <div ref={wrapperRef} className="relative">
        <div
          className={`mt-2 flex h-12 w-full items-center rounded-xl border px-3 pr-10 text-sm cursor-pointer outline-none transition-colors duration-200 ${
            state === "error"
              ? "border-red-500 text-red-500 dark:border-red-400 dark:text-red-400"
              : "border-gray-200 text-navy-700 placeholder-gray-400 focus:border-orange-500 focus:ring-1 focus:ring-orange-500 dark:border-white/10 dark:bg-navy-800 dark:text-white"
          }`}
          onClick={() => setOpen(!open)}
        >
          <span className={`${!selectedOption ? 'text-gray-400' : ''}`}>
            {selectedOption || 'Select an option'}
          </span>
          {open ? (
            <MdArrowDropUp className="absolute right-3 text-xl text-gray-500 dark:text-gray-300" />
          ) : (
            <MdArrowDropDown className="absolute right-3 text-xl text-gray-500 dark:text-gray-300" />
          )}
        </div>
        {open && (
          <ul className="absolute z-10 mt-1 w-full bg-white dark:bg-navy-800 rounded-xl shadow-lg max-h-60 overflow-auto">
            {options.map((option) => (
              <li
                key={option}
                className="px-4 py-2 text-sm text-navy-700 dark:text-white hover:bg-gray-100 dark:hover:bg-navy-700 cursor-pointer"
                onClick={() => {
                  onSelect(option);
                  setOpen(false);
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
      {error && (
        <p className="mt-1 text-xs text-red-500 dark:text-red-400 ml-3">
          {error}
        </p>
      )}
    </div>
  );
};

export default DropdownForForm;
