import React, { useEffect, useState } from "react";
import { MdOutlineGroup, MdPerson, MdPeople, MdGroupWork } from "react-icons/md";
import MiniStatistics from "./components/MiniStatistics";
import ManagementTable from "./components/ManagementTable";
import TeamManagementTable from "./components/TeamManagementTable";
import EcommerceOrderList from "views/admin/main/ecommerce/orderList"; // Import Order List component
import { tableColumnsManagement } from "views/admin/main/account/application/variables/tableColumnsManagement";
import { tableColumnsTeams } from "views/admin/main/account/application/variables/tableColumnsTeams";
import { fetchUsers, fetchTeams, fetchPoapDescriptions } from "api";
import PoapManagement from "./components/PoapManagement";
import SettingsProduct from "../../ecommerce/settingsProduct";
import NftProfile from "views/admin/nfts/profile";
import NftCollection from "views/admin/nfts/collection";

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [userData, setUserData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [poapData, setPoapData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const users = await fetchUsers();
      const teams = await fetchTeams();
      const poaps = await fetchPoapDescriptions();      
      setUserData(users);
      setTeamData(teams);
      setPoapData(poaps);
    };
    fetchData();
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return <ManagementTable columnsData={tableColumnsManagement} tableData={userData} />;
      case 2:
        return <TeamManagementTable columnsData={tableColumnsTeams} tableData={teamData} />;
      case 3:
        return <EcommerceOrderList />; 
      case 4:
        return <SettingsProduct />        
      default:
        return <ManagementTable columnsData={tableColumnsManagement} tableData={userData} />;
    }
  };

  return (
    <div className="mt-20 h-full w-full rounded-[20px]">
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 3xl:grid-cols-4">
        <div>
          <MiniStatistics
            icon={<MdOutlineGroup className="text-4xl" />}
            title="Total Users"
            value={userData.length.toString()}
            cardBg="bg-gradient-to-r from-orange-400 to-orange-600"
            titleColor="text-white"
            valueColor="text-white"
            detailColor="text-white"
            iconColor="text-white"
          />
        </div>
        <div>
          <MiniStatistics
            icon={<MdPerson className="text-4xl" />}
            title="Active Users"
            value={userData.filter(user => user.status === 'Online').length.toString()}
            cardBg="bg-white dark:!bg-navy-800"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500"
            detailText="Users currently online"
          />
        </div>
        <div>
          <MiniStatistics
            icon={<MdPeople className="text-4xl" />}
            title="Total Teams"
            value={teamData.length.toString()}
            cardBg="bg-white dark:!bg-navy-800"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500"
            detailText="Teams created in the system"
          />
        </div>
        <div>
          <MiniStatistics
            icon={<MdGroupWork className="text-4xl" />}
            title="Active Teams"
            value={teamData.filter(team => team.points > 0).length.toString()}
            cardBg="bg-white dark:!bg-navy-800"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500"
            detailText="Teams actively participating"
          />
        </div>
      </div>
      <div className="mt-5">
        <div className="flex space-x-4 border-b border-gray-200">
          <button
            onClick={() => setActiveTab(1)}
            className={`py-2 px-4 font-semibold ${
              activeTab === 1
                ? "border-b-2 border-brand-500 text-brand-500"
                : "text-gray-500"
            }`}
          >
            Users
          </button>
          <button
            onClick={() => setActiveTab(2)}
            className={`py-2 px-4 font-semibold ${
              activeTab === 2
                ? "border-b-2 border-brand-500 text-brand-500"
                : "text-gray-500"
            }`}
          >
            Teams
          </button>
          <button
            onClick={() => setActiveTab(3)}
            className={`py-2 px-4 font-semibold ${
              activeTab === 3
                ? "border-b-2 border-brand-500 text-brand-500"
                : "text-gray-500"
            }`}
          >
            Order List
          </button>


        </div>
        <div className="mt-3">{renderContent()}</div>
      </div>
    </div>
  );
};

export default AdminDashboard;
