import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import EventPopup from 'components/card/EventPopup'; // needed for dayClick
import { useMediaQuery } from 'react-responsive';
import './customFullCalendarTheme.css';
import groot from 'assets/img/avatars/goort.jpg'

function EventCalendar(props) {
  const { calendarData, initialDate } = props;
  const [hoveredEvent, setHoveredEvent] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });


  // Define breakpoints
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
  const isMediumScreen = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' });
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1025px)' });

  const headerToolbarConfig = () => {
    if (isSmallScreen) {
      return {
        left: 'prev,next',
        center: 'title',
        right: ''
      };
    } else if (isMediumScreen) {
      return {
        left: 'prev,next today',
        center: 'title',
        right: ''
      };
    } else if (isLargeScreen) {
      return {
        left: 'prev,next today',
        center: 'title',
        right: ''
      };
    }
  };

  const handleEventMouseEnter = (info) => {
    const rect = info.el.getBoundingClientRect();
    const eventDate = new Date(info.event.start);
  
    // Format the date as "Monday 1, 2024" (without month)
    const formattedDate = `${eventDate.toLocaleDateString('en-US', { weekday: 'long' })} 
                           ${eventDate.getDate()}, 
                           ${eventDate.getFullYear()}`;
  
    setHoveredEvent({
      title: info.event.title,
      date: formattedDate,  // Use the formatted date without the month
      time: info.event.extendedProps.time || '09:00 AM - 12:00 PM',
      location: info.event.extendedProps.location || 'iForge Center',
      imageUrl: info.event.extendedProps.imageUrl || groot,
    });
  
    setPopupPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX });
  };
  
  

  const handleEventMouseLeave = () => {
    setHoveredEvent(null);
  };


  return (
  <>
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin]}
      headerToolbar={headerToolbarConfig()}
      initialView="dayGridMonth"
      contentHeight="auto"
      events={calendarData}
      initialDate={initialDate}
      editable={true}
      eventMouseEnter={handleEventMouseEnter}
      eventMouseLeave={handleEventMouseLeave}
      height="100%"
      className="dark:bg-navy-800 dark:text-white custom-calendar"
    />
  {/* Popup for events */}
  <EventPopup event={hoveredEvent} position={popupPosition} onClose={handleEventMouseLeave} />
  </>
  );
}

export default EventCalendar;
