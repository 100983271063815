import React from "react";
import Card from "components/card";
import { BsAward } from "react-icons/bs";

const MentorsBadge = () => {
  return (
    <Card extra={"w-full h-full p-4"}>
      <div className="flex flex-col items-center justify-center text-center">
        <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-4xl font-bold text-brand-500 dark:bg-navy-700 dark:text-white">
          <BsAward />
        </div>
        <p className="mt-3 text-xl font-bold text-navy-700 dark:text-white md:text-2xl">
          iForge Mentor
        </p>
        <p className="mt-1 px-4 text-base font-normal text-gray-600 dark:text-gray-300 md:px-0">
          You are recognized as an iForge Mentor, guiding and leading your teams to success.
        </p>
      </div>
    </Card>
  );
};

export default MentorsBadge;
