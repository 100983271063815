import Barcode from "assets/img/ecommerce/Code-128.png";

const Details = ({ billingData }) => {
  const { user, paymentMethod, paymentDate, accountReference } = billingData || {};

  return (
    <div className="flex h-full w-full flex-col items-center justify-between rounded-[20px] bg-white bg-clip-border px-8 pt-8 pb-11 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none md:flex-row">
      {/* Left Side */}
      <div className="flex flex-col items-center gap-16 md:flex-row">
        <div>
          <p className="text-base font-medium leading-6 text-gray-600">
            Customer Details:
          </p>
          <h3 className="text-xl font-bold leading-7 text-navy-700 dark:text-white">
            {user?.firstName} {user?.lastName}
          </h3>
          <p className="text-base font-medium leading-6 text-gray-600">
            {user?.city}, {user?.country}
          </p>
        </div>
        <div>
          <p className="text-base font-medium text-gray-600">phone</p>
          <p className="font-medium text-navy-700 dark:text-white">
            <a
              className="text-brand-500 underline hover:text-brand-500 dark:text-white"
              href={`mailto:${user.email}`}
            >
              {user?.email || "N/A"}
            </a>
          </p>
          <p className="text-base font-medium text-gray-600">
            {paymentMethod}
          </p>
          <p className="text-base font-medium text-gray-600">
            {new Date(paymentDate).toLocaleString()}
          </p>
          <p className="text-base font-medium text-gray-600">
            Reference: {accountReference}
          </p>
        </div>
      </div>
      {/* Barcode Image */}
      <div className="mt-5 h-[112px] w-[112px] rounded-[20px] md:mt-0">
        <img src={Barcode} className="h-full w-full" alt="barcode" />
      </div>
    </div>
  );
};

export default Details;
