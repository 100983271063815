import React from 'react';

const EventPopup = ({ event, position, onClose }) => {
  if (!event) return null;

  return (
    <div
      className="absolute bg-white dark:bg-navy-700 shadow-xl rounded-xl p-5 w-96 border border-gray-200 dark:border-gray-600 
                 transition-transform transition-opacity duration-300 ease-out transform scale-95 opacity-0 
                 fade-in z-50"
      style={{ top: position.top + 20, left: position.left + 20 }}
      onMouseLeave={onClose}
    >
      <div className="relative">
        <img
          src={event.imageUrl || 'https://via.placeholder.com/400'}
          alt="Event"
          className="w-full h-48 object-cover rounded-t-xl shadow-md"
        />
        <div className="absolute top-2 right-2 bg-orange-500 text-white px-3 py-1 text-xs font-bold rounded-md">
          Upcoming
        </div>
      </div>
      
      <div className="mt-4">
        <h3 className="text-2xl font-bold text-navy-700 dark:text-white">{event.title}</h3>
        
        <div className="flex items-center mt-2 text-gray-600 dark:text-gray-300 text-sm">
          <span className="mr-2">📅</span>
          <p>{event.date}</p>
        </div>

        <div className="flex items-center mt-2 text-gray-600 dark:text-gray-300 text-sm">
          <span className="mr-2">🕒</span>
          <p>{event.time}</p>
        </div>

        <div className="flex items-center mt-2 text-gray-600 dark:text-gray-300 text-sm">
          <span className="mr-2">📍</span>
          <p>{event.location}</p>
        </div>
      </div>
    </div>
  );
};

export default EventPopup;
