import React, { useState } from 'react';
import Sidebar from './components/Sidebar';
import Nav from './components/Nav'; // Navbar
import CourseContent from './CourseContent';
import styles from './CoursePage.module.css';

const CoursePage = () => {
  const [selectedSection, setSelectedSection] = useState('miniCourses');

  const handleSidebarSelect = (section) => {
    setSelectedSection(section);
  };

  return (
    <div>
      {/* Navbar */}
      <Nav />

      {/* Main Layout */}
      <div className={styles.container}>
        <Sidebar onSelect={handleSidebarSelect} />
        <section className={styles.content}>
          <CourseContent section={selectedSection} />
        </section>
      </div>
    </div>
  );
};

export default CoursePage;
