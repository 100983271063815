export const tableColumnsReceipt = [
  {
    Header: "Item",
    accessor: "item",
    id: "item",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    id: "quantity",
  },
  {
    Header: "Rate",
    accessor: "rate",
    id: "rate",
  },
  {
    Header: "Amount",
    accessor: "amount",
    id: "amount",
  },
];
