import React, { createContext, useContext, useState, useEffect } from 'react';
import { isTokenExpired, saveToken, removeToken, getToken } from 'utils/auth';
import { jwtDecode } from 'jwt-decode'; // Keep your import as is
import io from 'socket.io-client';

const AuthContext = createContext();
let socket = null; // Singleton instance for socket

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null); // State for user info including role
  const [loading, setLoading] = useState(true);

  // Helper function to initialize socket and handle events
  const initSocket = (decodedToken) => {
    if (!socket) {
      socket = io(process.env.REACT_APP_API_BASE_URL, {
        transports: ['websocket'],
        withCredentials: true,
        secure: true,
      });

      socket.on('connect', () => {

        // Emit joinRoom for the logged-in user
        if (decodedToken.id) {
          socket.emit('joinRoom', decodedToken.id);
        }
      });

      socket.on('disconnect', () => {
        console.log('Socket disconnected:', socket.id);
      });

      socket.on('connect_error', (err) => {
        console.error('Socket connection error:', err);
      });

      socket.on('reconnect', (attempt) => {
        console.log('Reconnected after', attempt, 'attempts');
      });
    }
  };

  useEffect(() => {
    const initializeAuth = () => {
      const token = getToken();

      if (token) {
        const isExpired = isTokenExpired(token);

        if (!isExpired) {
          const decodedToken = jwtDecode(token);

          setIsAuthenticated(true);
          setUser(decodedToken); // Set the user info based on decoded token

          // Initialize socket connection
          initSocket(decodedToken);

          // Handle auto-logout when the token expires
          const timeUntilExpiration = decodedToken.exp * 1000 - Date.now();

          if (timeUntilExpiration > 0) {
            setTimeout(() => {
              logout();
            }, timeUntilExpiration);
          }
        } else {
          console.warn('Token is expired. Logging out.');
          removeToken(); // Remove the invalid token from storage
          setIsAuthenticated(false);
          setUser(null);
        }
      } else {
        console.warn('No token found. User is not authenticated.');
        setIsAuthenticated(false);
        setUser(null);
      }

      setLoading(false); // Ensure loading is set to false after handling the token
    };

    initializeAuth();

    return () => {
      // Cleanup socket connection on component unmount
      if (socket) {
        socket.disconnect();
        socket = null; // Clear the socket instance
      }
    };
  }, []); // Run only on mount

  const login = (token, rememberMe) => {
    saveToken(token, rememberMe);
    const decodedToken = jwtDecode(token);

    setIsAuthenticated(true);
    setUser(decodedToken); // Set the user state on login

    // Initialize socket connection after login
    initSocket(decodedToken);
  };

  const logout = () => {
    console.log('Logging out user');
    removeToken();
    setIsAuthenticated(false);
    setUser(null); // Clear the user state on logout

    // Disconnect the socket on logout
    if (socket) {
      socket.disconnect();
      console.log('Socket disconnected on logout');
      socket = null; // Clear socket instance
    }
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, login, logout, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
