import React from "react";
import Dropdown from "components/dropdown";
import { AiOutlineUser, AiOutlineShop } from "react-icons/ai";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { TiLightbulb } from "react-icons/ti";

/**
 * A modular CardMenu component that can display a dropdown menu
 * with either your own custom menu items OR a default set of items.
 *
 * @param {boolean} transparent - If true, use a "transparent" style.
 * @param {boolean} vertical - If true, render vertical dots instead of horizontal.
 * @param {Array} menuItems - An array of menu item objects:
 *    [{ label: string, icon?: JSX.Element, onClick?: function }, ... ]
 *
 * If `menuItems` is not provided or is empty, the component will display
 * the default "Panel 1", "Panel 2", etc. items as before.
 */
function CardMenu(props) {
  const { transparent = false, vertical = false, menuItems = [] } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Dropdown
      button={
        <button
          onClick={() => setOpen(!open)}
          className={`flex items-center text-xl hover:cursor-pointer ${
            transparent
              ? "bg-none text-white hover:bg-none active:bg-none"
              : vertical
              ? "bg-none text-navy-700 dark:text-white"
              : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
          } linear justify-center rounded-lg font-bold transition duration-200`}
        >
          {vertical ? (
            <p className="text-[24px] hover:cursor-pointer">
              <BsThreeDotsVertical />
            </p>
          ) : (
            <BsThreeDots className="h-6 w-6" />
          )}
        </button>
      }
      animation="origin-top-right transition-all duration-300 ease-in-out"
      classNames={`${transparent ? "top-8" : "top-11"} right-0 w-max`}
    >
      <div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        {menuItems.length > 0 ? (
          // If the user provided a custom list of menu items, display them
          menuItems.map((item, idx) => (
            <div
              key={idx}
              onClick={item.onClick}
              className="flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium hover:text-black 
                         dark:text-gray-200 dark:hover:text-white mt-2 pt-1"
            >
              {item.icon && <span>{item.icon}</span>}
              <span>{item.label}</span>
            </div>
          ))
        ) : (
          // Otherwise, show the original default panels
          <>
            <p className="flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium hover:text-black">
              <span>
                <AiOutlineUser />
              </span>
              Panel 1
            </p>
            <p className="mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium hover:text-black">
              <span>
                <AiOutlineShop />
              </span>
              Panel 2
            </p>
            <p className="mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium hover:text-black">
              <span>
                <TiLightbulb />
              </span>
              Panel 3
            </p>
            <p className="mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium hover:text-black">
              <span>
                <FiSettings />
              </span>
              Panel 4
            </p>
          </>
        )}
      </div>
    </Dropdown>
  );
}

export default CardMenu;
