import { useState, useEffect } from "react";
import Card from "components/card";
import ErrorAlerts from "components/alerts/ErrorAlerts";
import SuccessAlert from "components/alerts/SuccessAlerts";

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const Profile = ({profile, onImageChange}) => {
  const [avatar, setAvatar] = useState(profile.imageUrl);
  const [errorMessages, setErrorMessages] =useState([]);
  const [successMessage, setSuccessMessage] = useState(null);


  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessages(["File is too large. Please upload a file smaller than 5MB."]);
        return;
      }

      setErrorMessages([]);
    }

    try {
      await onImageChange(file);
      const reader = new FileReader();

      reader.onloadend = () => {
        setAvatar(reader.result);
      };
      reader.readAsDataURL(file);

      setSuccessMessage("Profile picture updated successfully!");
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      setErrorMessages(["Failed to upload profile picture. Please try again."]);
    }
  };

  useEffect(() => {
    setAvatar(profile.imageUrl); // Update avatar if profile image changes
  }, [profile.imageUrl]);

  return (
    <div>
      {errorMessages.length > 0 && (
        <ErrorAlerts errors={errorMessages} onClose={() => setErrorMessages([])} />
      )}
      {successMessage && (
        <SuccessAlert message={successMessage} onClose={() => setSuccessMessage(null)} />
      )}



      <Card extra={"w-full py-[18px] px-[18px] h-[160px]"}>
        <div className="flex items-center gap-3">
          <div className="relative flex h-[90px] w-[90px] items-center justify-center rounded-full">
            <img
              className="h-full w-full rounded-full object-cover"
              src={avatar}
              alt="profile"
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="absolute inset-0 opacity-0 cursor-pointer"
              title="Change profile picture"
            />
          </div>
          <div>
            <p className="text-xl font-bold text-navy-700 dark:text-white">
              {`${profile.firstName} ${profile.lastName}`}
            </p>
            <p className="text-base text-gray-600">{profile.email}</p>
          </div>
        </div>
        <p className="text-sm text-gray-500 mt-2">Click on the picture to change it.</p>
      </Card>
    </div>
  );
};

export default Profile;
