import React, { useState } from "react";
import Card from "components/card";
import MasterCard from "components/card/MasterCard";
import { MdCached, MdAdd, MdAttachMoney, MdMoreHoriz } from "react-icons/md";

function YourCard() {
  // State to manage the selected payment method
  const [paymentMethod, setPaymentMethod] = useState("Mpesa"); // Default set to Mpesa

  // Handle change from dropdown selection
  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  return (
    <Card extra={"w-full h-full px-[34px] py-4 xl:py-2"}>
      {/* Header */}
      <div className="flex items-center justify-between px-1 xl:pt-2">
        <h4 className="font-dm text-lg font-bold text-navy-700 dark:text-white">
          Your Payment Method
        </h4>

        {/* Dropdown to select payment method */}
        <div className="relative inline-block text-left">
          <select
            value={paymentMethod}
            onChange={handlePaymentChange}
            className="rounded-lg bg-lightPrimary p-1 pl-3 pr-6 border border-gray-300 dark:border-gray-700 text-navy-700 dark:text-white transition duration-200 focus:outline-none focus:ring-2 focus:ring-brand-500 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:bg-navy-600 dark:active:bg-navy-800"
          >
            <option value="Mpesa">Mpesa</option>
            <option value="Card">Card</option>
          </select>
        </div>
      </div>

      {/* MasterCard or Mpesa based on the selected payment method */}
      <div className="mt-[26px]">
        {paymentMethod === "Mpesa" ? (
          <MasterCard
            number="Mpesa XXXX XXXX XXXX"
            CCV="Safaricom"
            expDate="N/A"
            type="Mpesa"
            extraStyle="bg-gradient-to-r from-orange-600 via-orange-500 to-orange-400"

          />
        ) : (
          <MasterCard
            number="1275 XXXX XXXX XXXX"
            CCV="12X"
            expDate="02/23"
            type="MasterCard"
          />
        )}
      </div>

      {/* Card below info */}
      <div className="mt-12 w-full rounded-xl bg-lightPrimary px-4 py-3 dark:bg-navy-700">
        <h5 className="text-sm font-bold leading-5 text-navy-700 dark:text-white">
          Use for added security online
        </h5>
        <p className="pr-2 text-sm font-normal leading-5 text-gray-600">
          The card number is refreshed automatically after each use. Each number
          can be used only once.
        </p>
      </div>

      {/* Buttons */}
      <div className="mt-10 flex w-full justify-between xl:gap-1 2xl:gap-8">
        {/* Transfer Button */}
        <div className="flex flex-col items-center justify-center relative group">
          <button
            className="flex items-center justify-center rounded-full bg-white p-3 text-2xl text-brand-500 shadow-2xl transition duration-200 hover:cursor-pointer hover:!bg-gray-50 active:!bg-gray-100 dark:!bg-navy-700 dark:text-white dark:hover:!bg-white/20 dark:active:!bg-white/10"
          >
            <MdCached />
          </button>
          <h5 className="mt-1 text-sm font-medium text-navy-700 dark:text-white">Transfer</h5>
          {/* Tooltip */}
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 w-40 px-4 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-center">
            <span>Coming Soon</span>
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full h-2 w-2 bg-gray-800 rotate-45"></div>
          </div>
        </div>

        {/* Top Up Button */}
        <div className="flex flex-col items-center justify-center relative group">
          <button
            className="flex items-center justify-center rounded-full bg-white p-3 text-2xl text-yellow-500 shadow-2xl transition duration-200 hover:cursor-pointer hover:!bg-gray-50 active:!bg-gray-100 dark:!bg-navy-700 dark:text-white dark:hover:!bg-white/20 dark:active:!bg-white/10"
          >
            <MdAdd />
          </button>
          <h5 className="mt-1 text-sm font-medium text-navy-700 dark:text-white">Top Up</h5>
          {/* Tooltip */}
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 w-40 px-4 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-center">
            <span>Coming Soon</span>
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full h-2 w-2 bg-gray-800 rotate-45"></div>
          </div>
        </div>

        {/* Buy Tokens Button */}
        <div className="flex flex-col items-center justify-center relative group">
          <button
            className="flex items-center justify-center rounded-full bg-white p-3 text-2xl text-green-500 shadow-2xl transition duration-200 hover:cursor-pointer hover:!bg-gray-50 active:!bg-gray-100 dark:!bg-navy-700 dark:text-white dark:hover:!bg-white/20 dark:active:!bg-white/10"
          >
            <MdAttachMoney />
          </button>
          <h5 className="mt-1 text-sm font-medium text-navy-700 dark:text-white">Buy Tokens</h5>
          {/* Tooltip */}
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 w-40 px-4 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-center">
            <span>Coming Soon</span>
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full h-2 w-2 bg-gray-800 rotate-45"></div>
          </div>
        </div>

      
    </div>


      {/* Transactions */}
    </Card>
  );
}

export default YourCard;
