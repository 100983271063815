import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Portal } from "@chakra-ui/portal";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes";
import { useAuth } from "contexts/authContext";
import { useTheme } from "layouts/hooks/useTheme";

const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="w-16 h-16 border-4 border-orange-500 border-dashed rounded-full animate-spin"></div>
  </div>
);

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [currentRoute, setCurrentRoute] = useState("Main Dashboard");
  const [loading, setLoading] = useState(true);
  const { user } = useAuth(); // Get user object with permissions

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setOpen(true); // Open sidebar for larger screens
      } else {
        setOpen(false); // Close sidebar for smaller screens
      }
    };
  
    // Initialize sidebar state based on window size
    handleResize();
    window.addEventListener("resize", handleResize);
  
    // Simulate loading time (if applicable)
    setTimeout(() => setLoading(false), 1000); // Ensure this resolves properly
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getActiveRoute(routes);
    // eslint-disable-next-line
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          setCurrentRoute(routes[i].name);
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.layout === "/admin" &&
        (!prop.requiredPermission || (Array.isArray(prop.requiredPermission) 
          ? prop.requiredPermission.some(permission => user.permissions.includes(permission))
          : user.permissions.includes(prop.requiredPermission))
        )
      ) {
        return (
          <Route path={`${prop.path}`} element={prop.component} key={key} />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      return null;
    });
  };

  document.documentElement.dir = "ltr";

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex h-full w-full bg-background-100 dark:bg-background-900">
      <Sidebar
        open={open}
        hovered={hovered}
        setHovered={setHovered}
        mini={props.mini}
        onClose={() => setOpen(false)}
      />
      {/* Navbar & Main Content */}
      <div className="h-full w-full font-dm dark:bg-background-900">
        {/* Main Content */}
        <main
          className={`mx-2.5 flex-none transition-all dark:bg-background-900 md:pr-2 ${
            props.mini === false
              ? "xl:ml-[313px]"
              : props.mini === true && hovered === true
              ? "xl:ml-[313px]"
              : "ml-0 xl:ml-[142px]"
          } `}
        >
          {/* Routes */}
          <div>
            <Portal>
              <Navbar
                onOpenSidenav={() => setOpen(!open)}
                brandText={currentRoute}
                secondary={getActiveNavbar(routes)}
                theme={props.theme}
                setTheme={props.setTheme}
                hovered={hovered}
                mini={props.mini}
                setMini={props.setMini}
                {...rest}
              />
            </Portal>
            <div className="mx-auto min-h-screen p-2 !pt-[100px] md:p-2">
              <Routes>
                {getRoutes(routes)}
                <Route
                  path="/"
                  element={<Navigate to="/admin/dashboard" replace />}
                />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
