import React from 'react';
import { MdSchool, MdDesignServices, MdSupportAgent, MdThumbUp, MdLightbulb, MdOutlineLibraryBooks } from 'react-icons/md'; // Updated icon imports

const FeaturesSection = () => {
    return (
        <section className="relative min-h-screen flex items-center justify-center bg-gradient-to-b from-gray-50 to-white">
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
                <div className="text-center mb-12">
                    <h2 className="text-4xl font-extrabold text-gray-800">Key Features & Benefits</h2>
                    <p className="text-lg text-gray-600 mt-4 max-w-lg mx-auto">
                        Discover what makes our approach unique and how our technology enhances education
                    </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-6 gap-20 text-center">
                    {/* Features */}
                    <div className="md:col-span-2 bg-white rounded-xl shadow-lg overflow-hidden transform hover:scale-105 transition duration-300">
                        <div className="p-6">
                            <MdOutlineLibraryBooks className="mx-auto text-6xl text-orange-500" />  {/* Changed icon */}
                            <h3 className="mt-5 text-2xl font-semibold text-gray-800">Modular Courses</h3>
                            <p className="text-gray-600">
                                Tailored learning paths for different ages and skill levels to foster deep understanding and enthusiasm for 3D printing.
                            </p>
                        </div>
                    </div>
                    <div className="md:col-span-2 bg-white rounded-xl shadow-lg overflow-hidden transform hover:scale-105 transition duration-300">
                        <div className="p-6">
                            <MdDesignServices className="mx-auto text-6xl text-orange-500" />
                            <h3 className="mt-5 text-2xl font-semibold text-gray-800">Create and Compete</h3>
                            <p className="text-gray-600">
                                Engage in innovative challenges and competitions to test your skills and push the boundaries of what you can create.
                            </p>
                        </div>
                    </div>
                    <div className="md:col-span-2 bg-white rounded-xl shadow-lg overflow-hidden transform hover:scale-105 transition duration-300">
                        <div className="p-6">
                            <MdSupportAgent className="mx-auto text-6xl text-orange-500" />
                            <h3 className="mt-5 text-2xl font-semibold text-gray-800">Teacher Mentorship</h3>
                            <p className="text-gray-600">
                                Benefit from the guidance of experienced educators who are passionate about inspiring and teaching the next generation.
                            </p>
                        </div>
                    </div>
                    {/* Benefits */}
                    <div className="md:col-span-2 bg-white rounded-xl shadow-lg overflow-hidden transform hover:scale-105 transition duration-300">
                        <div className="p-6">
                            <MdSchool className="mx-auto text-6xl text-orange-500" />
                            <h3 className="mt-5 text-2xl font-semibold text-gray-800">Enhanced STEM Education</h3>
                            <p className="text-gray-600">
                                Integrate cutting-edge 3D printing technology into your curriculum to enhance learning and innovation.
                            </p>
                        </div>
                    </div>
                    <div className="md:col-span-2 bg-white rounded-xl shadow-lg overflow-hidden transform hover:scale-105 transition duration-300">
                        <div className="p-6">
                            <MdThumbUp className="mx-auto text-6xl text-orange-500" />
                            <h3 className="mt-5 text-2xl font-semibold text-gray-800">Student Engagement</h3>
                            <p className="text-gray-600">
                                Activate student creativity and problem-solving skills with hands-on, project-based learning.
                            </p>
                        </div>
                    </div>
                    <div className="md:col-span-2 bg-white rounded-xl shadow-lg overflow-hidden transform hover:scale-105 transition duration-300">
                        <div className="p-6">
                            <MdLightbulb className="mx-auto text-6xl text-orange-500" />
                            <h3 className="mt-5 text-2xl font-semibold text-gray-800">Promote Innovation</h3>
                            <p className="text-gray-600">
                                Foster a culture of innovation and creativity, preparing students for future challenges.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeaturesSection;
